import _ from 'lodash';
import React, { Fragment, useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Slide,
    Box,
    Fab
} from '@mui/material';
import {
    Add,
    DeleteForever,
    Create
} from '@mui/icons-material';

import { deleteSpec, fetchSpecs } from 'actions/specs';

import NewSpec from 'components/specs/new';

const styles = {
    root: {
        flexGrow: 1,
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
    },
    title: {
        flexGrow: 1,
    },
    button: {
        width: '-webkit-fill-available'
    }
};

const ConfirmDelete = forwardRef(({
    cancel,
    deleteSpecConfirm
}, ref) => {
    return (
    <ListItem ref={ref}>
        <ListItemText primary="Are you sure you want to delete this spec?" />
        <ListItemSecondaryAction>
            <Button variant="contained" onClick={cancel} style={{marginRight: 8}}>No, Cancel</Button>
            <Button variant="contained" color="secondary" onClick={deleteSpecConfirm}>Yes, Delete</Button>
        </ListItemSecondaryAction>
    </ListItem>
)})

const Specs = () => {
    const dispatch = useDispatch();
    useEffect(() => dispatch(fetchSpecs()), []);
    const specs = useSelector(state => state.specs)
    const [ open, setOpen ] = useState(false);
    const [ deleteId, setDeleteId ] = useState('');
    const [ editId, setEditId ] = useState('');
    return (
    <Fragment>
    <AppBar sx={styles.searchBar}  position="static" elevation={0} color="default">
        <Toolbar>
            <Typography variant="h6" component="h6" sx={styles.title}>Purchasing Specifications</Typography>
            <Box display={{ xs: 'block', sm: 'none' }}  sx={styles.addPO} >
                <Fab
                    size="small"
                    color="primary"
                    sx={styles.addPO}
                    onClick={() => setOpen(true)}
                >
                    <Add />
                </Fab>
            </Box>
            <Box display={{ xs: 'none', sm: 'block' }}  sx={styles.addPO} >
                <Button
                    variant="contained"
                    color="primary"
                    sx={styles.addPO}
                    onClick={() => setOpen(true)}
                >
                    <Add />
                    Add Purchasing Spec
                </Button>
            </Box>
        </Toolbar>
    </AppBar>
    <List>
    {_.map(specs, (spec, specId) => {
    const {title, url} = spec;
    return (
        specId === deleteId ?
        <Slide in={specId === deleteId} direction="left" key={specId}>
        <ConfirmDelete
            deleteSpecConfirm={()=>dispatch(deleteSpec(deleteId, () => console.log('Deleted')))}
            cancel={()=>setDeleteId('')}
        /></Slide>:
        <ListItem key={specId}>
            <ListItemText
                primary={
                    <a target="_blank" rel="noopener noreferrer" href={url}>{title}</a>
                }
            />
            <NewSpec open={specId===editId} onClose={() => setEditId('')} spec={spec} specId={specId}/>
            <ListItemSecondaryAction>
                <IconButton
                    onClick={()=>setEditId(specId)}
                >
                    <Create />
                </IconButton>
                <IconButton
                    onClick={()=>setDeleteId(specId)}
                >
                    <DeleteForever />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )})}
    </List>
    <NewSpec open={open} onClose={() => setOpen(false)} />
    </Fragment>
)}

export default Specs;
