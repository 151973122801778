import {
    reverse,
    map,
    pickBy,
    uniq,
    size,
    includes,
    toString,
    lowerCase,
    toNumber
} from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
	AppBar,
	Paper,
	Box,
	Toolbar,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Button,
	Card,
	CardHeader,
	IconButton,
	Chip,
	Fab,
	Collapse,
	Badge
} from '@mui/material';
import {
	ExpandMore,
	FilterList,
	Add,
	PriorityHigh as Caution
} from '@mui/icons-material';
import { orange } from '@mui/material/colors';
import { fetchPOs, openPO, unFetchPOs } from 'actions/pos';

import Drawer from 'components/drawer';
import NewPO from 'components/pos/new';
import POSearchRow from 'components/pos/poSearchRow';

const styles = {
    root: {
        flexGrow: 1,
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
    },
    paper: {
        margin: 1
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    title: {
        flexGrow: 1,
    },
	  button: {
	    position: 'absolute',
	    right: theme => theme.spacing(1)
  	},
    tableRoot: {
        margin: 1
    },
    filter: {
        margin: 1,
        // boxShadow: 'none'
    },
    cardHeader: {
       padding: 1,
    },
    chip: {
        margin: '5px 0 5px 0'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme => `${theme.transitions.create('transform', {
            duration: 'shortest',
        })}`,
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    total: {
        '& div' : {
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 2,
        }

    },
    move: {
        transition: 'all .25s'
    },
    flip: {
        transform: 'rotate(0.125turn)',
    }
};

const initialState = {
    columns: {
            po: { show: true, col: 0, header: 'PO' },
            vendor: { show: true, col: 1, header: 'Vendor' },
            date: { show: false, col: 1, header: 'Date' },
            purchasingAgent: { show: true, col: 2, header: 'By' },
            location: { show: false, col: 3, header: 'Location' },
            status: { show: true, col: 4, header: 'Status' },
            total: { show: true, col: 5, header: 'Total' },
        }
};

const POs = () => {
    const dispatch = useDispatch();
    // const classes = useStyles();
  const [ columns, setColumns ] = useState(initialState.columns);
  const [search, setSearch] = useState({status: ['OPEN', 'PARTIAL']});
  const [ show, setShow ] = useState(false);
  const [ open, setOpen ] = useState(false);
  const pos = useSelector(state => state.pos);
  const searched = React.useMemo(() => pickBy(pos, po => {
      const _size = size(uniq(map(po, (v,k) => {
          if(!search[k]) return true; //if search column is blank include
          if(k === 'status') return includes(toString(search[k]), v); //if status is one of the options selected (note we are searching the selections for the po status)
          if(k === 'total') return toNumber(search[k]) <= po.total/100;
          return includes(lowerCase(toString(v)), lowerCase(search[k])); //if the column has a search string, include if the term is found in the value of the po object key
      })))===1
      return _size
  }), [pos, search, show]);
  const filtered = React.useMemo(() => reverse(map(searched, (po, poId) => ({ ...po, poId}))), [searched])
  useEffect(() => {
      const getPOs = () => dispatch(fetchPOs());
      getPOs();
      return () => dispatch(unFetchPOs());
  }, []);

	return (
            <Drawer
                header='Purchasing'
                roles={["ALL"]}
                active='Purchase Orders'
            >
                    <Paper sx={styles.root}>
                        <AppBar sx={styles.searchBar}  position="static" elevation={0} color="default">
                          <Toolbar>
				            <Box display={{ xs: 'block', sm: 'none' }} sx={styles.button}>
				              <Fab
				                size="small"
				                color="primary"
				                onClick={() => setOpen(true)}
				              >
				                  <Add />
				              </Fab>
				            </Box>
				            <Box display={{ xs: 'none', sm: 'block' }} sx={styles.button}>
					            <Button
					              variant="contained"
					              color="primary"
					              onClick={() => setOpen(true)}>
					                <Add />
					                New
					            </Button>
				            </Box>
                          </Toolbar>
                        </AppBar>
                        <Box sx={styles.paper}>
                            <Card sx={styles.filter} elevation={0}>
                                <CardHeader
                                    sx={styles.cardHeader}
                                    avatar={<FilterList />}
                                    action={
                                        <IconButton
                                            onClick={() => setShow(!show)}
                                            sx={show
                                            ? styles.expand
                                            : {...styles.expand, ...styles.expandOpen}
                                            }
                                        >
                                            <ExpandMore />
                                        </IconButton>
                                    }
                                    title="Filter"
                                />
                                <Collapse in={show} timeout="auto" unmountOnExit>
                            {
                                map(columns, ({show, header}, col)=>{
                                    const remove = () => {
                                                setColumns(prevCols => {
                                                return { ...prevCols, [col]: { ...prevCols[col],  show: !prevCols[col].show }};
                                                })};
                                    return <Chip
                                            key={col}
                                            color={show ? 'primary' : 'secondary'}
                                            onClick={remove}
                                            label={header}
                                            onDelete={remove}
                                            sx={styles.chip}
                                            deleteIcon={<Add
                                                sx={!show
                                                    ? styles.move
                                                    : { ...styles.move, ...styles.flip}
                                                }/>}
                                        />;
                                })
                            }
                            </Collapse>
                        </Card>
                        <Table
                            sx={styles.tableRoot}
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                {
                                    map(columns, ({ show, header }, col) => {
                                        return show ? <TableCell
                                            key={col}
                                            sx={{textAlign: header === 'Total' ? 'right' : ''}}
                                        >
                                            {header}
                                        </TableCell>
                                        : null
                                    })
                                }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <POSearchRow
                                    columns={columns}
                                    show={show}
                                    search={search}
                                    updateSearch={(searchTerm, searchKey) => {
                                      setSearch(prev => {
                                          return {
                                              ...prev,
                                              [searchKey]: searchTerm
                                          }
                                      })
                                    }}
                                />
                            {
                                map(filtered, (purchaseOrder) => {
                                const { poId, issues } = purchaseOrder
                                    return (
                                        <TableRow
                                            key={poId}
                                            onClick={
                                                () => {
                                                    const {poId} = purchaseOrder;
                                                    dispatch(openPO(poId))
                                                }
                                            }
                                        >
                                        {
                                            map(columns, ({ show }, colKey) => {
                                                const isTotal =  colKey === 'total';
                                                const cellValue = isTotal
                                                ? <NumberFormat
                                                    value={purchaseOrder[colKey]/100}
                                                    decimalScale={2}
                                                    fixedDecimalScale={2}
                                                    // prefix="$ "
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    renderText={(value, props) => (<div><span>$</span><span>{value}</span></div>)}
                                                />
                                                : purchaseOrder[colKey]
                                                return show
                                                    ? <TableCell
                                                        key={colKey}
                                                        sx={isTotal
                                                        ? styles.total
                                                        : {}
                                                        }
                                                    >
                                                        {issues && colKey === 'status'
                                                        ? <Badge
                                                            sx={{
                                                            '& span': { right: '-5px'},
                                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                                            '& svg': {
                                                                fontSize: '.9rem',
                                                                color: orange[500]
                                                            }}}
                                                            badgeContent={<Caution />}
                                                            size="small">
                                                        {cellValue}
                                                        </Badge>
                                                        : cellValue
                                                        }
                                                    </TableCell>
                                                    : null
                                            })
                                        }
                                        </TableRow>
                                    )
                                })
                            }
                            </TableBody>
                        </Table>
                        </Box>
                        <NewPO
                            open={open}
                            onClose={() => setOpen(false)}
                        />
                    </Paper>
            </Drawer>
	);
};



export default POs;
