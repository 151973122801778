import { map, omit } from 'lodash'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Button,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Alert,
	Divider,
	IconButton,
} from '@mui/material';
import {
  Create,
  DeleteForever
} from '@mui/icons-material';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { format } from 'date-fns';



import { fetchPOIssues, createPOIssue, updatePOIssue, deleteIssue } from 'actions/pos';

const nonEmptyObject = object()
  .test(
    'is-non-empty',
    'At least one item must be selected',
    (value) => value && Object.keys(value).length > 0
  );

const IssueForm = ({edit, items, poId, setEdit, setpoIssues}) => {
	const { vendorId, po, location, vendor, purchasingAgent } = useSelector(s => s.pos[poId] || {});
	const dispatch = useDispatch();
	const blankIssue = {
    issue: '',
    poId,
    po,
    location,
    vendor,
    purchasingAgent,
    issueItems: {},
    vendorId,
    status: 'open',
    initialActions: '',
    date: format(new Date(), 'yyyy-MM-dd')
  };
  return (
      <Formik
        enableReinitialize
        initialValues={edit || blankIssue}
        validationSchema={
          object().shape({
            issue: string().required(),
            issueItems: nonEmptyObject
          })
        }
        onSubmit={(values, actions) => {
          const cb = () => {
            if (edit) {
              setEdit(prev => omit(prev, edit.id));
              setpoIssues(prev => ({...prev, [edit.id]: values}));
            }
            actions.resetForm();
          };
          return edit
          ? dispatch(updatePOIssue(values, edit.id, cb))
          : dispatch(createPOIssue(values, cb));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {

          return (
            <Form>
              <TextField
                name='issue'
                value={values.issue}
                label={edit ? 'Issue': 'New Issue'}
                placeholder="Please describe the issue in detail ..."
                multiline
                rows={2}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.issue && errors.issue)}
                helperText={touched.issue && errors.issue}
              />
              <TextField
              sx={{mt: 2, mb: 1}}
                name='initialActions'
                value={values.initialActions}
                label={'Vendor Response (Initial)'}
                placeholder="What has been done so far to fix the issue ..."
                multiline
                rows={2}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.initialActions && errors.initialActions)}
                helperText={touched.initialActions && errors.initialActions}
              />
              {map(items, (item, itemId) => {
                return itemId && <FormGroup key={itemId}>
                  <FormControlLabel control={
                    <Checkbox
                    checked={Boolean(values.issueItems[itemId])}
                      onClick={() => {
                        const updateValues = values.issueItems[itemId]
                        ? omit(values.issueItems, itemId)
                        : {...values.issueItems, [itemId]: true};
                        setFieldValue('issueItems', updateValues);
                      }}
                    />} label={item.description} />
                </FormGroup>;
              })}
              {errors.issueItems && <Alert severity="warning">{errors.issueItems}</Alert>}
              {edit
                ? <Button
                  type="submit"
                  color="secondary"
                  onClick={()=>dispatch(deleteIssue(poId, edit.id, setpoIssues))}
                >
                  Delete Issue
                </Button>
                :null
              }
              <Button type="submit">{edit ? 'Update Issue': 'Add Issue'}</Button>
            </Form>
          );
        }}
      </Formik>
    );
};
const Issues = ({
	open,
	onClose,
	items,
	poId
}) => {
	const handleClose = onClose;
	const dispatch = useDispatch();
	const { issues } = useSelector(s => s.pos[poId] || {});
	const [edit, setEdit] = React.useState({});
	const [poIssues, setpoIssues] = React.useState({});
	React.useEffect(() => {
	  const cb = issueData => setpoIssues(issueData);
	  const fetchIssues = () => dispatch(fetchPOIssues(issues, cb));
	  fetchIssues();
	}, [issues]);
  return poIssues && (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle>Purchase Order Issues</DialogTitle>
      <DialogContent>
        <List sx={{mb: 1}}>
          {map(poIssues, (issue, issueId) => {
            return edit[issueId]
            ? <IssueForm
              key={issueId}
              poId={poId}
              items={items}
              setEdit={setEdit}
              edit={{...issue, id: issueId}}
              setpoIssues={id => {
               return setpoIssues(prev => omit(prev, id));
              }}
            />
            : <ListItem key={issueId}>
              <ListItemText primary={issue && issue.issue} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => setEdit(prev => ({...prev, [issueId]: true}))}
                >
                  <Create />
                </IconButton>
                <IconButton>
                  <DeleteForever />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>;
          })}
          <Divider />
        </List>
        <IssueForm poId={poId} items={items} setEdit={setEdit}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Issues;
