import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';

import {
    fetchQuestions,
    fetchItemCategoryQuestions
} from 'actions/questions';

import Questions from 'components/questions/main';

const ItemQuestions = ({
    open,
    onClose,
    itemCategoryId,
}) => {
    const dispatch = useDispatch();
    const itemCategoriesQuestions = useSelector(s => s.itemCategoriesQuestions)
    useEffect(() => dispatch(fetchQuestions()), []);
    useEffect(() => dispatch(fetchItemCategoryQuestions(itemCategoryId)), [])
    return (
        <Dialog
            onClose={onClose}
            open={open}
        >
            <DialogTitle>Item Category Supplier Questions</DialogTitle>
            <DialogContent>
                <Questions
                    itemCategoryLink={itemCategoryId}
                    itemCategoriesQuestions={itemCategoriesQuestions}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ItemQuestions;
