import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	MenuItem,
	Button
} from '@mui/material';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';


const styles = {
	input: {
		marginTop: theme => theme.spacing(1),
		marginBottom: theme => theme.spacing(1)
	}
};

const Question = ({
	open,
	onClose,
	question,
	questions,
	createQuestion,
	index,
	categoryId
}) => {
	const { enqueueSnackbar } = useSnackbar();
	//const state = useStore().getState();
	return (
			<Dialog open={open} onClose={onClose}>
			<Formik
				enableReinitialize
				initialValues={{
					question,
					type: '',
					answeredBy: '',
					answers: {}
				}}
				onSubmit={(values, actions) => {
					createQuestion({ question, categoryId, index }, () => {
              enqueueSnackbar("Question Added", { variant: 'success' });
              onClose();
					});
				}}
			>{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur
			}) => (
				<Form>
					<DialogTitle>Question</DialogTitle>
					<DialogContent>
						<TextField
							value={values.question}
							name="question"
							onChange={handleChange}
							onBlur={handleBlur}
							variant="outlined"
							label="Question"
							fullWidth
							error={Boolean(touched.question && errors.question)}
							helperText={touched.question && errors.question}
							sx={styles.input}
						/>
						<TextField
							value={values.type}
							name="type"
							onChange={handleChange}
							onBlur={handleBlur}
							variant="outlined"
							label="Type"
							fullWidth
							select
							error={Boolean(touched.question && errors.question)}
							helperText={touched.question && errors.question}
							sx={styles.input}
						>
						{/*<Autocomplete
		          name="answeredBy"
		          options={questions}
		          getOptionLabel={(option) => option.name || ''}
		          style={{ width: 300 }}
		          renderInput={(params) => (
		          <TextField
		                {...params}
		                label="Combo box"
		                variant="outlined"
		            />
		          )}
		        />*/}
							<MenuItem value=""></MenuItem>
							<MenuItem value="boolean">Yes/No</MenuItem>
							<MenuItem value="string">Text</MenuItem>
						</TextField>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}>Cancel</Button>
						<Button type="submit" variant="contained">
	          	Submit
	        	</Button>
					</DialogActions>
				</Form>
			)}</Formik>
			</Dialog>
		);
};

export default Question;