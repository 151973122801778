import { find, mapValues, map, replace, first, split, remove, sortBy, findIndex, pickBy } from 'lodash';
import { database, storage } from 'firebase_config';
import { v4 as uuidv4 } from 'uuid';

import { showSnackbar, logError } from 'actions';

export const PO_NUMBER = 'PO_NUMBER';
export const POS = 'POS';
export const PO = 'PO';
export const EXPENSE_TYPES = 'EXPENSE_TYPES';
export const OPEN_PO = 'OPEN_PO';


const purchasing = database.ref('purchasing');
const pos = database.ref('purchasing/pos');
const deleted = database.ref('purchasing/deletedPOs')
const poNumber = database.ref('purchasing/poNumber');
const poItems = database.ref('purchasing/poItems');
const poAttachments = storage.ref('purchasing/poAttachments');
const poIssues = database.ref('purchasing/issues');




export function createPO(values, cb) {
	return (dispatch, getState) => pos.push().then(res => {
			const poId = res.key;
			const po = parseInt(values.po) || getState().poNumber
			dispatch(updatePO({ ...values, poId, po}, () => {
				dispatch(newPONumber());
				cb(poId);
			}))
		})
}

export function validatePONumber(poNumber) {
	return () => {
		return pos.orderByChild('po').equalTo(poNumber).once('value').then(s => {
			const exists = s.exists();
			return exists
			})
	}
}
export function openPO(poId) {
	const getPO = async (dispatch) => {
		pos.child(poId).on('value', async snapshot => {
			const poData = snapshot.val();
			if (poData.items) {
			await Promise.all(map(poData.items, async (item, itemId) => {
				const itemData = await poItems.child(itemId).once('value').then(s => s.val());
				//showSnackbar
				return poData.items[itemId] = itemData;
			}))}
			return dispatch({
			type: OPEN_PO,
			payload: poData
		});
		})
	}
	return dispatch => getPO(dispatch)
}

export function scrollPOs(by, direction) {
	return (dispatch, getState) => {
		const currentOpenPO = getState().openPO;
		const allPOs = getState().pos;
		const condensedPOs = () => {
		switch(by) {
			case 'agent':
				return pickBy(allPOs, po => po.purchasingAgent === currentOpenPO.purchasingAgent)
			case 'vendor':
				return pickBy(allPOs, po => po.vendorId === currentOpenPO.vendorId)
			default:
				return allPOs
		}}
		const orderedPOs = sortBy(condensedPOs(), 'po');
		const i = findIndex(orderedPOs, po => po.poId === currentOpenPO.poId);
		const next = direction === 'up' ? i-1 : i+1;
		const nextPOId = orderedPOs[next] && orderedPOs[next].poId;
		return nextPOId && dispatch(openPO(nextPOId));
	}
}

export function detatchListen(poId) {
	return dispatch => {
	pos.child(poId).off()
	}
}

export function closePO(poId) {
	return dispatch => {
	pos.child(poId).off()
	dispatch({
		type: OPEN_PO,
		payload: {}
	})
	}
}

export function deletePO(poId, cb) {
	const po = pos.child(poId);
	return () => po.once('value').then(snap => {
		deleted.child(poId).set(snap.val()).then(() => po.remove())
	})
}

export function updatePOStatus(poId, status, callback) {
	return () => {
		pos.child(poId).child('status').set(status).then(callback)
	}
}

export function updatePOLastRec() {
	 return (dispatch, getState) => {
	 	const { poId } = getState().openPO
	 	return pos.child(`${poId}/lastReceived`).set(Date.now()).then(() => dispatch(fetchPO(poId)))
	 }
}
export function updatePO(values, cb) {
	const { poId } = values;
	const poItemsDeep = mapValues(values.items, item => ({ ...item, poId}));
	const mininmizePO = {
		...values,
		pdfURL: null,
		items: mapValues(poItemsDeep, item => true),
		itemsLoaded: null
	};
	const update = async (dispatch) => {
		try {
			await Promise.all(map(poItemsDeep, (item, itemId) => {
				return poItems.child(itemId).set({ ...item, inputs: item.inputs || null})
			}));
			await purchasing.update({
				[`pos/${poId}`]: mininmizePO
			})
			return;
		} catch (error) {
			dispatch(logError({error, mininmizePO, values, poItemsDeep}, 'PO Item Update Error'));
			return dispatch(showSnackbar({
			message: `PO Item Update Error: ${error} [Dustin has been notified]`,
			options: {
				variant: 'error'
			}
		}))
		}
	}

	return dispatch => update(dispatch).then(cb).catch(error => {
		dispatch(logError(error, 'PO Update Error'));
		return dispatch(showSnackbar({
			message: `PO Update Error: ${error}`,
			options: {
				variant: 'error'
			}
		}))
		})
}

export function updatePOItem(values, itemId, cb) {
	const updated = Date.now();
	return dispatch => poItems.child(itemId).update({...values, updated})
	.then(()=>{
		// dispatch(openPO(values.poId));
		cb && cb()
		})
}

export function getPONumber() {
	return dispatch => {
		poNumber.on('value', snap => {
			dispatch({
				type: PO_NUMBER,
				payload: snap ? snap.val() : {}
			})
		})
	}
}

export function newPONumber() {
	return () => poNumber.transaction(number => {
		return ++number
	})
}

export function fetchPOs() {
	return dispatch => {
		pos.orderByKey().limitToLast(40).once('value', snap => {
			dispatch({
				type: POS,
				payload: snap ? snap.val() : {}
			})
		}).then(() => pos.on('value', async snapshot  => {

			dispatch({
				type: POS,
				payload: snapshot ? snapshot.val() : {}
			})
		}))
	}
}

export function unFetchPOs() {
	return () => pos.off()
}

export function getNewItemId() {
	return () => {
		return poItems.push().then(key => key)
}}


export function fetchPO(po) {
	return dispatch => {
		pos.child(po).on('value', async snap => {
			const poVal = snap.val()
			//https://stackoverflow.com/questions/38125401/lodash-mapvalues-async
			const getItemData = async () => {
				let items = { ...poVal.items }
				await Promise.all(map(poVal.items, async (bool, itemId) => {
				const itemData = await poItems.child(itemId).once('value').then(s=>s.val())
				return items[itemId] = itemData
			}))
			return items;
			}

			getItemData().then(items => {
				const assembledPO = {...poVal, items: {...items}, itemsLoaded: true};
				dispatch({
					type: PO,
					payload: snap ? {[po]: assembledPO }: {}
				})
			})
		})
	}
}

//=================================
//      PO Issues
//=================================

export function fetchPOIssues(issues, cb) {
	return () => {
		const getIssueData = async () => {
			let allIssues = {};
			await Promise.all(map(issues, async (issue, issueId) => {
				const data = await poIssues.child(issueId).once('value').then(s => s.val());
				return allIssues[issueId] = data;
			}))
			return allIssues;
		}
		return getIssueData().then(cb)
	}

}

export function createPOIssue(values, cb) {
	return () => {
		poIssues.push(values).then(res => {
			pos.child(`${values.poId}/issues`).update({[res.key]: true}).then(cb)
		})
	}
}

export function updatePOIssue(values, id, cb) {
	return dispatch => {
		poIssues.child(id).set(values).then(cb)
	}
}

export function deleteIssue(poId, issuesId, cb) {
	const updates = {};
	updates[`/pos/${poId}/issues/${issuesId}`] = null;
	updates[`/issues/${issuesId}`] = null;
	return () => purchasing.update(updates).then(cb);
}

//=================================
//      PO Attachments
//=================================

export function attachFileToPO(file, poId) {
	const newKey = uuidv4();
	const attachmentLocation = `${newKey}/${file.name}`
	const filePath = poAttachments.child(attachmentLocation);
	console.log(filePath)
	return dispatch => {
		const uploadTask = filePath.put(file);
		uploadTask.on('state_changed', (snapshot) => {
			var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    	console.log('Upload is ' + progress + '% done');
		}, error => {
			dispatch(logError(error, 'PO File Upload Error'));
			return dispatch(showSnackbar({
			message: `PO File Upload Error: ${error} [Dustin has been notified]`,
			options: {
				variant: 'error'
			}
		}))
		}, () => {
	    uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
	    	const fileData = {downloadURL, name: file.name, id: newKey, attachmentLocation}
	    	await pos.child(`${poId}/poDocuments`).update({[fileData.id]: fileData});
		    return dispatch(showSnackbar({
		    	message: "File successfullly attached to PO",
		    	options: {
		    		variant: 'success'
		    	}
		    }))
	    });
		})
	}
}


export function attachRecFileToPO(file, cb) {
	const newKey = uuidv4();
	const filePath = poAttachments.child(`${newKey}/${file.name}`);
	return dispatch => {
		const uploadTask = filePath.put(file);
		uploadTask.on('state_changed', (snapshot) => {
			var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    	console.log('Upload is ' + progress + '% done');
		}, error => {
			dispatch(logError(error, 'PO File Upload Error'));
			return dispatch(showSnackbar({
			message: `PO File Upload Error: ${error} [Dustin has been notified]`,
			options: {
				variant: 'error'
			}
		}))
		}, () => {
	    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
	      cb({downloadURL, name: file.name, id: newKey});
	    });
		})
	}
}

function extractFilePath(url) {
    // Decode the URL
    const decodedUrl = decodeURIComponent(url);

    // Find the start of the file path
    const basePath = "/purchasing/poAttachments/";
    const startIndex = decodedUrl.indexOf(basePath) + basePath.length;

    // Extract the file path
    const filePath = decodedUrl.substring(startIndex);

    // Find where the query parameters start and remove them
    const queryParamIndex = filePath.indexOf("?");
    if (queryParamIndex !== -1) {
        return filePath.substring(0, queryParamIndex);
    }

    return filePath;
}

export function removeFileFromPO(fileData, poId) {
	const filePath = fileData.attachmentLocation || extractFilePath(fileData.downloadURL)
	return dispatch => {
		poAttachments.child(filePath).delete()
		.then(() => {
			return pos.child(`${poId}/poDocuments`).update({[fileData.id]: null})
		})
		.catch(error => {
			dispatch(logError(error, 'PO File Delete Error'));
			return dispatch(showSnackbar({
			message: `PO File Delete Error: ${error} [Dustin has been notified]`,
			options: {
				variant: 'error'
			}
		}))
		})
	}
}

// export async function linkFiletoAttachment(downloadURL, name, ids, needsFile, cb) {
// 	const { itemId, commentId } = ids;
// 	return async () => {
// 		const items = poItems.child(itemId)
// 		await items.once('value').then(async snapshot => {
// 			const { attachments, comments }  = snapshot.val();
// 			await items.update({
// 				attachments: attachments
// 					? [ ...attachments, { downloadURL, commentId, name }]
// 					: [{ downloadURL, commentId, name }],
// 				comments:
// 					needsFile
// 					? { ...comments, [commentId] : {
// 						...comments[commentId],
// 						verified: true
// 					}}
// 					: comments
// 			});
// 			return;
// 		})
// 		return;
// 	}
// }

//CHAT GPT VERSION:

export const linkFiletoAttachment = (downloadURL, name, ids, needsFile) => {
  return async (dispatch, getState) => {
    const { itemId, commentId } = ids;
    const items = poItems.child(itemId);
    const snapshot = await items.once('value');
    const { attachments, comments } = snapshot.val();
    await items.update({
      attachments: attachments
        ? [...attachments, { downloadURL, commentId, name }]
        : [{ downloadURL, commentId, name }],
      comments: needsFile
        ? { ...comments, [commentId]: { ...comments[commentId], verified: true }}
        : comments
    });
    // Dispatch an action here if needed, for example:
    // dispatch({ type: 'FILE_LINKED_TO_ATTACHMENT', payload: { downloadURL, name, ids } });
  };
};


export function unlinkFileToAttachment(downloadURL, itemId, cb) {
	const prefix = 'https://firebasestorage.googleapis.com/v0/b/custom-app-project-test.appspot.com/o/'
	const noPrefix = replace(downloadURL, prefix, '');
	const noToken = first(split(noPrefix, "?", 1));
	const dbPath = decodeURIComponent(noToken)
	return () => {
		const storageRef = storage.ref(dbPath)
		storageRef.delete()
		.then(() => {
			const item = poItems.child(itemId)
			item.once('value').then(snapshot => {
			const { attachments, comments }  = snapshot.val();
			const removedAttachment = remove(attachments, a => a.downloadURL === downloadURL);
			const linkedToCommentId = removedAttachment[0].commentId;
			const { needsFile } = comments[linkedToCommentId];
			//if the comment in the attachment removed, then inspect the comment.  If it needsFile then check if the array
			if (needsFile) {
				const hasOtherAttachment = find(attachments, a => a.commentId);
				if (!hasOtherAttachment) {
					item.child('comments').child(linkedToCommentId).update({verified: false});
				}
			}
			item.update({attachments}).then(cb)
			//poItems.child(itemId).child('attachments')
		})})
		.catch((error) => console.log('File delete error', {error}));
	}
}

export function verifyRequirement(itemId, commentId) {
	return () => poItems.child(`${itemId}/comments/${commentId}/verified`)
	.transaction(verified => !verified) //.then(() => console.log('verify'))
}

export function updateComment(value, key, itemId, commentId) {
	return () => poItems.child(`${itemId}/comments/${commentId}`)
	.update({
		[key]: value
	})
}

export function expenseTypes() {
	return dispatch => expenseTypes.on('value').then(s => {
		dispatch({
			type: EXPENSE_TYPES,
			payload: s.val()
		})
	})
}