import { map, remove } from 'lodash';
import React from 'react';
import {
  InputBase,
  Chip,
  Box
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const StyledChip = styled(Chip)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
      maxWidth: 82,
      overflow: 'hidden',
textOverflow: 'ellipsis',
whiteSpace: 'nowrap',
    },

}))

const styles = {
	search: theme => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `rgba(${theme.palette.common.white}, 0.15)`,
    '&:hover': {
      backgroundColor: `rgba(${theme.palette.common.white}, 0.25)`,
    },
    marginRight: theme.spacing(1),
    minWidth: 220,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    }
  }),
  terms: theme => ({
    display: 'block',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.5),
    [theme.breakpoints.up('sm')] : {
      marginRight: theme.spacing(14),
    }
  }),
}


const Chips = ({
  terms,
  deleteTerm
}) => (map(terms, (term, index) => {
      return <StyledChip
        key={index+term}
        label={term}
        onDelete={() => deleteTerm(index)}
      />
}))

const SearchComponent = (props) => {
	const {
		search,
		setSearch,
		terms,
		setTerms
	} = props
	return <Box
	  sx={theme => ({
	    display: 'contents',
    [theme.breakpoints.down('sm')] : {
      width: '100%',
	    display: 'block',
	    margin: theme.spacing(1,1,0,0)
    }
	  })}
	>
      <Box sx={styles.search}>
        <form
          onSubmit={e => {
            e.preventDefault();
            setTerms(prevState => {
              prevState.push(search);
              return prevState;
            });
            setSearch('');
          }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={e => setSearch(e.target.value)}
              value={search}
            />
          </Search>
        </form>
      </Box>
      <Box sx={styles.terms}>
        <Chips
          terms={terms}
          deleteTerm={index => {
            const newTerms = remove(terms, (v,i) => (i!==index));
            setTerms(newTerms);
          }}
        />
      </Box>
      </Box>
}

export default SearchComponent;
