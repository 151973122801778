import React from 'react';
import {
	Grid,
	TextField,
	InputAdornment,
	ToggleButton,
	ToggleButtonGroup
} from '@mui/material';
import NumberFormat from 'react-number-format';
import PercentIcon from '@mui/icons-material/Percent';
import MoneyIcon from '@mui/icons-material/AttachMoney';

const styles = {
	gridContainer: {
		marginTop: 1
	},
	label: {
		textAlign: 'right',
		lineHeight: '32px',
		paddingRight: 1,
	  justifyContent: 'space-between',
	  alignItems: 'stretch',
	  display: 'flex'
	},
};

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue * 100,
          },
        });
      }}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      displayType={'input'}
    />
  );
});

const Tax = ({
		tax,
		taxRate,
		taxType,
		setFieldValue
}) => {

  const handleToggle = (e, newPosition) => {
  	setFieldValue('taxType', newPosition)
  }
	const isPercent = taxType === 'percent'
  const handleChange = e => {
  	return isPercent
  	? setFieldValue('taxRate', e.target.value)
  	: setFieldValue('tax', e.target.value)
  }
  const percentClass = visible => (visible && isPercent) || (!visible && !isPercent)
  ? { '& p': { color: theme => theme.palette.text.primary }}
  : { '& p': { opacity: 0}}
	return (
		<Grid container sx={styles.gridContainer}>
			<Grid item xs={6} sx={styles.label}>
				<ToggleButtonGroup
		      value={taxType}
		      exclusive
		      onChange={handleToggle}
					size="small"
					sx={{'& svg': { fontSize: '1.0rem !important'}}}
	      >
					<ToggleButton value="dollar">
						<MoneyIcon />
					</ToggleButton>
					<ToggleButton value="percent">
						<PercentIcon />
					</ToggleButton>
				</ToggleButtonGroup>
				Tax:
			</Grid>
			<Grid item xs={6}>
				<TextField
					value={(isPercent ? taxRate : tax)/100}
					fullWidth
					onChange={handleChange}
					sx={{
						'& input': {
							fontSize: '.8em !important',
							textAlign: 'center'
						}
					}}
					InputProps={{
						inputComponent: NumberFormatCustom,
						startAdornment: <InputAdornment position="start" sx={percentClass(false)}>$</InputAdornment>,
						endAdornment: <InputAdornment position="end" sx={percentClass(true)}>%</InputAdornment>
					}}
					variant="standard"
				/>
			</Grid>
		</Grid>
	)
}

export default Tax;
