import _ from 'lodash';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar
} from '@mui/material';

import {
  AccountCircle,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  Description,
  PeopleOutline,
  InvertColors,
  ViewList,
  AddShoppingCart,
  PriceCheck
} from '@mui/icons-material';

import { styled, useTheme } from '@mui/material/styles';

import Restricted from 'components/restricted';

import { useColorSelect } from 'colorMode';

import {logoutUser} from 'actions';
const drawerWidth = 240;


const menu = [
    {to: '/home', text: 'Purchase Orders', icon: <Description />},
    {to: '/invoices', text: 'Invoices', icon: <PriceCheck />},
    {to: '/suppliers', text: 'Vendors', icon: <PeopleOutline />},
    {to: '/items', text: 'Items', icon: <AddShoppingCart />},
    {to: '/itemCategories', text: 'Item Categories', icon: <ViewList />},
    // {to: '/forms', text: 'Settings', icon: <Settings />},
]

const openedMixin = (theme) => ({
  width: drawerWidth,
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MainDrawer({children, roles, header}) {
  const { mode, toggleColorMode } = useColorSelect();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const auth_user = useSelector(state => state.authUser);
  const menuOpen = Boolean(anchorEl);
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(logoutUser(err=>{
      if(err) {
        enqueueSnackbar('' + err, {variant: 'error'})
      }
    }))
    handleClose();
      navigate('/');
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const ListItems = ({active}) => {
    return _.map(menu, ({to, icon, text, component }, key)=>{
      if (!to) {
        return <div key={key}>{component}</div>;
      }
      return (
        <ListItem
            button to={to}
            component={Link}
            key={key}
            //sx={{ ...styles.item, ...active === text && styles.itemActiveItem}}
        >
            <ListItemIcon
              //sx={styles.itemPrimary}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
                primary={text}
                //sx={styles.itemPrimary}
            />
        </ListItem>
      )
    })
  }

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {header}
          </Typography>
          <IconButton
            onClick={handleMenu}
            color="inherit"
            edge="end"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menuOpen}
            onClose={handleClose}
          >
            {auth_user ?
            [
              <MenuItem key={'1'} onClick={handleSignOut}>Sign Out</MenuItem>,
              <MenuItem key={'2'} onClick={handleClose}>{auth_user.email}</MenuItem>
            ]
            :
            <MenuItem onClick={()=>navigate('/')}>Sign In</MenuItem>
            }

          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ListItems />
        <ListItem onClick={toggleColorMode} sx={{position: 'absolute', bottom: 0 }}>
            <ListItemIcon
              //sx={styles.itemPrimary}
            >
              <InvertColors />
            </ListItemIcon>
            <ListItemText
                primary={`Enable ${mode === 'dark' ? "Light" : "Dark"} Mode`}
                //sx={styles.itemPrimary}
            />

        </ListItem>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {_.some(roles, role=>_.includes(auth_user.role, role)) ||
        _.indexOf(roles, "ALL") > -1 ?
          children
          :
          <div>
            <Restricted />
          </div>
        }
      </Box>
    </Box>
  );
}

