import { isEmpty, find } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Button,
	TextField,
	InputAdornment,
	FormControl,
	InputLabel,
	OutlinedInput
} from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import { string , object } from 'yup';
import {useDropzone} from 'react-dropzone';

import NumberFormat from "react-number-format";

import { closeInvoice } from 'actions/invoices';

// Function to add days to a date
const addDays = (days) => {
  const result = new Date();
  result.setDate(result.getDate() + days);
  return result;
};

const getToday = unix => {
		const timestamp = unix
		? addDays(unix)
		: Date.now()
    const now = new Date(timestamp);
    const year = now.getFullYear();
    const month = ("0" + (now.getMonth() + 1)).slice(-2);
    const day = ("0" + now.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  //const ref = useRef();
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue * 100,
          },
        });
      }}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      displayType={onChange ? "input" : "text"}
    />
  );
});

const DropDialog = () => {
  const {acceptedFiles, getRootProps, getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  	isDragActive
  } = useDropzone();
  const {values} = useFormikContext();
  const [dropGood, setDropGood] = React.useState('#767676');

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

	React.useEffect(() => {
		if (isDragActive) {
			setDropGood(isDragAccept ? '#00e676' : '#ff1744')
		} else {
			setDropGood('#767676');
		}
	}, [isDragActive, isDragAccept])
	return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <TextField
        	fullWidth
        	placeholder="Drop files here to upload..."
        	sx={{
        		'& input': {
        			border: `1px dashed ${dropGood}`,
        			borderRadius: t => t.spacing(.5)
        		}
        	}}
        />
      </div>
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>

      </aside>
    </section>
    )
}

const InvoiceForm = ({initialValues}) => {
	const { values, handleChange, setValues } = useFormikContext();
	const pos = useSelector(state => state.pos);
	React.useEffect(() => {
		const po = find(pos, p => String(p.po) === values.po)
		setValues(po
		? {
			...values,
			poDate: po.date,
			vendor: po.vendor,
			poTotal: po.total

		}
		: {
			...values,
			poDate: initialValues.poDate,
			vendor: initialValues.vendor,
			poTotal: initialValues.poTotal
		}
		)
	}, [values.po])
			return <DialogContent>
				<Form>
				<Grid container spacing={2} sx={{paddingTop: 1}}>
					<Grid item xs={12}  sm={6}>
						<TextField
							value={values.invoice}
							name="invoice"
							label="Invoice Number"
							onChange={handleChange}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							value={values.po}
							name="po"
							label="PO Number"
							onChange={handleChange}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={values.vendor}
							name="vendor"
							label="Vendor"
							onChange={handleChange}
							fullWidth
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							value={values.poDate}
							name="poDate"
							label="PO Date"
							onChange={handleChange}
							fullWidth
							type="date"
							InputLabelProps={{
								shrink: true
							}}
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							value={values.invoiceDate}
							name="invoiceDate"
							label="Invoice Date"
							onChange={handleChange}
							fullWidth
							type="date"
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							value={values.invoiceDueDate}
							name="invoiceDueDate"
							label="Invoice Due Date"
							onChange={handleChange}
							fullWidth
							type="date"
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormControl variant="outlined" fullWidth>
		          <InputLabel htmlFor="outlined-adornment-amount">PO Total</InputLabel>
		          <OutlinedInput
								value={(values.poTotal / 100).toFixed(2)}
								name="poTotal"
								disabled
								type="number"
								onChange={handleChange}
		            id="outlined-adornment-amount"
		            startAdornment={<InputAdornment position="start">$</InputAdornment>}
								label="PO Total"
								sx={{'& input' : {
									textAlign: "right",
				          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
				            WebkitAppearance: 'none',
				            margin: 0,
				          },
								}}}

		          />
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl variant="outlined" fullWidth>
		          <InputLabel htmlFor="outlined-adornment-amount">Invoice Total</InputLabel>
		          <OutlinedInput
								value={(values.total / 100).toFixed(2)}
								name="total"
								onChange={handleChange}
		            id="outlined-adornment-amount"
		            startAdornment={<InputAdornment position="start">$</InputAdornment>}
								label="Invoice Total"
								sx={{'& input' : {
									textAlign: "right",
				          // '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
				          //   WebkitAppearance: 'none',
				          //   margin: 0,
				          // },
								}}}
                inputComponent={NumberFormatCustom}
                    //o
		          />
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<DropDialog />
					</Grid>
				</Grid>
				</Form>
			</DialogContent>

}

const Invoice = () => {
	const dispatch = useDispatch();
	const invoice = useSelector(state => state.openInvoice);
	const [today] = React.useState(getToday());
	const [thirtyDays] = React.useState(getToday(30));

	const handleClose = () => dispatch(closeInvoice());

	const initialValues = {
						invoice: '',
						po: '',
						poId: '',
						vendor: '',
						vendorId: '',
						status: 'OPEN',
						poDate: today,
						poTotal: 0,
						invoiceDate: today,
						invoiceDueDate: thirtyDays,
						total: 0,
						files: [],
						unsavedFiles: []
					}

	return (
		<Dialog open={!isEmpty(invoice)} onClose={handleClose}>
			<DialogTitle>Invoice</DialogTitle>
				<Formik
					enableReinitialize
					initialValues={initialValues}
				>
					{() => {
						return <InvoiceForm initialValues={initialValues}/>}}
				</Formik>
		</Dialog>
	)
}

export default Invoice;
