import _ from 'lodash';
import React, { useEffect, useState, Fragment }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { useSnackbar } from 'notistack';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    Switch,
    FormGroup,
    FormControlLabel,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    IconButton
} from '@mui/material';
import Clear from '@mui/icons-material/Clear';

import {
    linkRequirements,
    fetchRequirements,
    editRequirement,
    createRequirement,
    fetchCategoryRequirements
} from 'actions/requirements';
import { fetchSpecs } from 'actions/specs';

const styles = {
    input: {
        margin: theme => theme.spacing(1)
    },
    slide: {
         margin: theme => theme.spacing(1)
    },
    spec: {
        minWidth: 277
    },
    linkButton: {
        marginTop: theme => theme.spacing(1),
        marginLeft: theme => theme.spacing(1),
        height: 56
    },
    cancel: {
        position: 'absolute',
        right: theme => theme.spacing(1),
        top: theme => theme.spacing(1),
    }
};

const LinkExisting =({
    itemCategoryId,
    onClose: close,
    itemRequirements
}) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const requirements = useSelector(s => s.requirements);
    const itemCategories = useSelector(s => s.itemCategories);
    const categoryRequirements = _.flatMap(itemCategories, (category) => {
        return _.values(_.mapValues(category.requirements, (v,k) => k))
    })
    const [links, setLinks] = useState(itemRequirements || {})
    const onClose = () => {
        setOpen(false);
        setLinks(itemRequirements || {});
        close();
    }
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    <IconButton onClick={onClose} sx={styles.cancel}>
                        <Clear />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {_.map(requirements, (requirement, reqId) => {
                          if (!requirement.itemReq && _.includes(categoryRequirements, reqId)) {
                              return (
                                <ListItem key={reqId} button>
                                    <Checkbox
                                        checked={links[reqId] || false}
                                        onChange={() => setLinks({ ...links, [reqId]: !links[reqId] })}
                                    />
                                    <ListItemText
                                        primary={requirement.title}
                                        secondary={requirement.instruction}
                                    />
                                </ListItem>
                              )
                          }
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={() => dispatch(linkRequirements(links, itemCategoryId, close))}
                        color="primary"
                        variant="contained"
                    >Link</Button>
                </DialogActions>
            </Dialog>
            <Button
                color="primary"
                variant="outlined"
                sx={styles.linkButton}
                onClick={() => setOpen(true)}
            >Link Existing</Button>
        </Fragment>
    )
}

const NewRequirement = (props) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const specs = useSelector(s=>s.specs);
    const requirements = useSelector(s=>s.requirements);
    const {
        open,
        onClose,
        itemCategoryId,
        editId,
        itemId,
        item,
    } = props;
    const edit = requirements && requirements[editId];

    useEffect(() =>dispatch(fetchSpecs()), [])
    useEffect(() => dispatch(fetchRequirements()), [])

    return (<Formik
    initialValues={
    !!edit ?
    {
        title: edit.title || '',
        instruction: edit.instruction || '',
        spec: edit.spec || '',
        verify: edit.verify || false,
        verifyValue: edit.verifyValue || false,
        itemReq: edit.itemReq || Boolean(itemId),
        includeOnPO: edit.includeOnPO || true,
        attachmentRequired: edit.attachmentRequired || false,
        verifyValueLabel: edit.verifyValueLabel || ''
    }
    : {
        title: '',
        instruction: '',
        verify: false,
        verifyValue: false,
        spec: '',
        includeOnPO: true,
        attachmentRequired: false,
        verifyValueLabel: '',
        itemReq: Boolean(itemId)
    }
    }

    enableReinitialize={true}
    validationSchema={ object().shape({
        title: string().required(),
        instruction: string().required(),
    })}
    onSubmit={(values, formikBag) => {
                const afterAction = () => {
                    onClose();
                    enqueueSnackbar(`Requirement ${!editId ? 'added' : 'edited'} `, { variant: 'success'});
                    if (!item) dispatch(fetchCategoryRequirements(itemCategoryId))
                }
                const id = itemCategoryId || itemId;

                return !editId
                    ? dispatch(createRequirement(values, afterAction, id, item))
                    : dispatch(editRequirement(editId, values, afterAction))
            }}
    >{({
        values,
        values: {
            title,
            instruction,
            verify,
            verifyValue,
            spec,
            includeOnPO,
            attachmentRequired,
            verifyValueLabel
        },
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        isValid,
    }) => {
    return <Dialog open={open} onClose={onClose}>
            <Form>
            <DialogTitle>
                Add Requirement
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={styles.input}
                    value={title}
                    name='title'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    label='Title'
                    variant='outlined'
                />
                {item ? null : <LinkExisting { ...props} />}
                <TextField
                    sx={styles.input}
                    value={instruction}
                    name='instruction'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.instruction && errors.instruction)}
                    helperText={touched.instruction && errors.instruction}
                    label='Supplier instruction'
                    variant='outlined'
                    multiline
                    fullWidth
                />
                <FormGroup row>
                    <FormControlLabel
                        sx={styles.slide}
                        control={
                            <Switch
                                checked={verify}
                                onChange={handleChange('verify')}
                                value='verify'
                                // color="primary"
                            />}
                            label="Verify when recieved"
                    />
                </FormGroup>
                <Collapse in={verify} collapsedSize={'0px'}>
                <FormGroup row>
                    <FormControlLabel
                        sx={styles.slide}
                        control={
                            <Switch
                                checked={verifyValue}
                                onChange={handleChange('verifyValue')}
                                value='verifyValue'
                                // color="primary"
                            />}
                            label="Enter value when received"
                    />
                </FormGroup>

                <Collapse in={verifyValue} collapsedSize={'0px'}>
                <TextField
                    sx={styles.verifyValueLabel}
                    value={verifyValueLabel}
                    name='verifyValueLabel'
                    onChange={handleChange}
                    label='Input Label'
                    variant='outlined'
                    fullWidth
                />
                </Collapse>
                <FormGroup row>
                    <FormControlLabel
                        sx={styles.slide}
                        control={
                            <Switch
                                checked={attachmentRequired}
                                onChange={handleChange('attachmentRequired')}
                                value='attachmentRequired'
                                // color="primary"
                            />}
                            label="Require attachment when receiving"
                    />
                </FormGroup>
                </Collapse>
                <FormGroup row>
                    <FormControlLabel
                        sx={styles.slide}
                        control={
                            <Switch
                                checked={includeOnPO}
                                onChange={handleChange('includeOnPO')}
                                value='includeOnPO'
                                // color="primary"
                            />}
                            label="Include on Purchase Order"
                    />
                </FormGroup>
                <TextField
                    select
                    sx={{...styles.spec, ...styles.input}}
                    value={spec}
                    name='spec'
                    onChange={handleChange('spec')}
                    onBlur={handleBlur}
                    label='Purchasing Specification'
                    variant='outlined'
                >
                    <MenuItem value=""></MenuItem>
                    {_.map(specs, (option, key) => {
                        return <MenuItem key={key} value={key}>{option.title}</MenuItem>
                    })}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    >Add</Button>
            </DialogActions>
            </Form>
        </Dialog>}}
    </Formik>
    )
}

export default NewRequirement;
