import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import thunk from 'redux-thunk';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import rootReducer from 'reducers';
import 'index.css';
import { ConfirmProvider } from 'components/confirm';
import SnackbarListener from 'components/SnackbarListener';

import Snake from 'components/snake';
import POs from 'components/pos/pos';
import Items from 'components/items/items';
import SignIn from 'components/signin';
import Reset from 'components/resetPassword';
import ItemCategories from 'components/itemCategories/main';
import Suppliers from 'components/suppliers/main';
import RequireAuth from 'components/auth';
import AuthProvider from 'components/AuthProvider';
import RequirementsList from 'components/requirements/requirementList';
import Invoices from 'components/pos/invoices';
import * as serviceWorker from 'serviceWorker';
import 'components/scrollbar';

import { ColorModeProvider, useColorSelect } from "colorMode";

import { verifyAuth } from 'actions';



const ColorProvided = () => {

  const { theme } = useColorSelect();


    return <ThemeProvider theme={theme()}>
      <CssBaseline />
      <SnackbarListener />
            <GlobalStyles
              styles={(theme) => {
                return {
                  "*::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "*::-webkit-scrollbar-track": {
                    webkitBoxShadow: `${theme.shadows[1]}`,
                  },
                  "*::-webkit-scrollbar-thumb": {
                    backgroundColor: `${theme.palette.background.paper}`,
                    outline: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "2px",
                  },
                  "& body": {
                    backgroundColor: `${theme.palette.background.paper}ee`,
                  },
                };
              }}
            />
            {/*<CssBaseline />*/}
            <BrowserRouter>
            <ConfirmProvider>
            <AuthProvider>
                <Routes>
                    <Route exact path='/invoices' element={<RequireAuth><Invoices /></RequireAuth>} />
                    <Route exact path='/requirements' element={<RequireAuth><RequirementsList /></RequireAuth>} />
                    <Route exact path='/home' element={<RequireAuth><POs /></RequireAuth>} />
                    <Route exact path='/suppliers' element={<RequireAuth><Suppliers /></RequireAuth>} />
                    <Route exact path='/items' element={<RequireAuth><Items /></RequireAuth>} />
                    <Route exact path='/itemCategories' element={<RequireAuth><ItemCategories /></RequireAuth>} />
                    <Route exact path='/snake' element={<Snake />} />
                    <Route path='/reset' element={<Reset />} />
                    <Route path='/' element={<SignIn />} />
                </Routes>
            </AuthProvider>
            </ConfirmProvider>
            </BrowserRouter>
      </ThemeProvider>
}

function App() {

    function configureStore(initialState) {
        const store = createStore(
            rootReducer,
            initialState,
            compose (
                applyMiddleware(thunk),
                window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
            )
        );

        store.dispatch(verifyAuth())

        return store;
    }

    const store = configureStore();
  return (
        <SnackbarProvider maxSnack={4}>
    <Provider store={store}>
        <ColorModeProvider>
          <ColorProvided />
        </ColorModeProvider>
    </Provider>
        </SnackbarProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);






// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
