import React, { useState } from 'react';
import {
    Paper,
    AppBar,
    Tabs,
    Tab,
    Box
} from '@mui/material';

import Drawer from 'components/drawer';
import Suppliers from 'components/suppliers/suppliers';
import SupplierIssues from 'components/suppliers/supplierIssues';

const styles = {
    root: {
        flexGrow: 1,
        maxWidth: 986,
        margin: 'auto',
        overflow: 'hidden',
    },
};

const SuppliersMain = () => {
    const [tabIndex, setTabIndex] = useState(0);

    return (

        <Drawer
            header='Purchasing'
            roles={["ADMIN"]}
            active='Vendors'
        >
            <Paper sx={styles.root}>
                <AppBar position="static">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tabIndex}
                        onChange={(e,i) => setTabIndex(i)}
                        textColor="inherit"
                        indicatorColor="secondary"
                    >
                        <Tab label='Vendors'/>
                        <Tab label='Vendor Performance'/>
                        <Tab label='Vendor Issues'/>
                    </Tabs>
                </Box>
                </AppBar>
                {tabIndex === 0 && <Suppliers />}
                {tabIndex === 2 && <SupplierIssues />}
            </Paper>
        </Drawer>
)}

export default (SuppliersMain);
