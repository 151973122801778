import _ from 'lodash';
import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Slide,
    Typography
} from '@mui/material';
import {
    DeleteForever,
    Create
} from '@mui/icons-material';

import {
    fetchCategoryRequirements,
    deleteRequirement
} from 'actions/requirements';

import NewRequirement from 'components/requirements/new';

const SlideComponent = forwardRef((props, ref) => (<div ref={ref}>{props.children}</div>))

const ItemQuestions = ({
    open,
    onClose,
    itemCategoryId,
    questions,
    itemRequirements
}) => {
    const dispatch = useDispatch();
    const requirements = useSelector(s => s.requirements)
    useEffect(() => {
        if (open) { dispatch(fetchCategoryRequirements(itemCategoryId)) }
        }, [open]);
    const [ newReq, setNewReq ] = useState(false);
    const [ editReq, setEditReq ] = useState({});
    const [ editReqId, setEditReqId] = useState();
    const [ deleteId, setDeleteId] = useState();
    return (
        <Dialog
            onClose={onClose}
            open={open}
        >
            <DialogTitle>
                Item Category Requirements
                <Button
                    onClick={() => setNewReq(true)}
                    style={{
                        position: 'absolute',
                        right: 16,
                        top: 16
                    }}
                    color="primary"
                    variant="contained"
                >
                    Add
                </Button>
            </DialogTitle>
            <DialogContent>
                <List  style={{ overflow: 'hidden' }}>
                {_.map(requirements, (requirement, reqId) => {
                    if (itemRequirements && itemRequirements[reqId]) {
                    return (
                        deleteId === reqId ?
                            <Slide
                                key={reqId}
                                in={deleteId === reqId}
                                direction="left"
                                mountOnEnter
                                unmountOnExit
                            >
                                <SlideComponent remove>
                                    <ListItem  style={{
                                        height: 70,
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                        borderRadius: '4px',
                                        borderColor: 'rgba(0, 0, 0, 0.34)',
                                    }}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="h6"
                                                >
                                                Are you sure you want to delete this requirement?
                                                </Typography>
                                            }
                                            style={{ maxWidth: '60%' }}
                                        />
                                        <ListItemSecondaryAction>
                                            <Button
                                                onClick={() => setDeleteId('')}
                                            >
                                                Nevermind
                                            </Button>
                                            <Button
                                                onClick={() => dispatch(deleteRequirement(itemCategoryId, reqId))}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Yes, Delete
                                            </Button>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </SlideComponent>
                            </Slide>
                            :

                        <Slide key={reqId+1} in={deleteId !== reqId} direction="right">
                            <ListItem style={{ overflow: 'hidden' }}>
                                <ListItemText
                                    primary={requirement.title}
                                    secondary={requirement.instruction}
                                    style={{ maxWidth: '80%' }}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={() => {
                                            setEditReqId(reqId);
                                            setEditReq(requirement);
                                            setNewReq(true);
                                        }}
                                    >
                                        <Create />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => setDeleteId(reqId)}
                                    >
                                        <DeleteForever />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Slide>
                    )}
                })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onClose} color="primary" variant="contained">Save</Button>
            </DialogActions>
            <NewRequirement
                open={newReq}
                onClose={() => {
                    setNewReq(false)
                    setEditReq({});
                    setEditReqId('');
                }}
                itemCategoryId={itemCategoryId}
                itemRequirements={itemRequirements}
                edit={editReq}
                editId={editReqId}
            />
        </Dialog>
    )
}

export default ItemQuestions;
