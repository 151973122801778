import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useAuth } from 'components/AuthProvider';
import { useSnackbar } from 'notistack';

import { signIn } from 'actions';


const styles = {
  main: theme => ({
    width: 'auto',
    maxWidth: 400,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(448)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
  paper: {
    marginTop: theme => theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme => `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme => theme.spacing(1),
    backgroundColor: theme => `${theme.palette.secondary.main} !important`,
    color: theme => `${theme.palette.primary.main} !important`,
  },
  form: {
    //width: '100%', // Fix IE 11 issue.
    marginTop: theme => theme.spacing(2),
    marginLeft: theme => theme.spacing(3),
    marginRight: theme => theme.spacing(3),
    marginBottom: theme => theme.spacing(3),
  },
  submit: {
    marginTop: theme => theme.spacing(4),
    //backgroundColor: theme.palette.primary.main
  },
  iconButton: {
    backgroundColor: theme => `${theme.palette.primary.main} !important`,
    height: 44,
    width: 44
  }
};

const SignIn = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getAuthUser } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    // const classes = useStyles();
    const dispatch= useDispatch();
    const [values, setValues] = useState({
      email: '',
      password: ''
    });
    // const [errors, setErrors] = useState({});

    const handleChange = e=>{
        setValues(prevState => ({ ...values, [e.target.name]: e.target.value}));
    }

    const handleSubmit = e=>{
        e.preventDefault();
        dispatch(signIn(values, ({type, res}) => getAuthUser().then(()=>{
            switch(type) {
                case 'Error':
                    enqueueSnackbar(res + '', {variant: 'error'})
                    break;

                case 'User':
                    reDirect();
                    break;
                default:
                  break;
            }
        })));
    }
    const reDirect= () => {
        if (location.pathname !== '/') {
          navigate(location.pathname)
        } else {
            navigate("/home")
        }
    }
    // const validate = e=>{
    //     const {name, value} = e.target;
    //     // eslint-disable-next-line
    //     const validEmail = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    //     switch(name) {
    //         case 'email':
    //             if(!value.match(validEmail)) {
    //                 setErrors(prevState=>({
    //                     ...prevState.errors,
    //                     [name]: 'Email format invaild'
    //                 }))
    //             } else {
    //                 setErrors(prevState=>({
    //                     ...prevState.errors,
    //                     [name]: ''
    //                 }))
    //             }
    //             break;
    //           default:
    //             break;
    //     }
    // }
    return (
        <Paper sx={styles.main}>
          <Paper sx={styles.paper}>
            <IconButton sx={styles.iconButton}>
            <Avatar sx={styles.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            </IconButton>
            <Typography component="h1" variant="h5" color="primary">
              Gulf-Pro Purchasing Module
            </Typography>
            <form
                sx={styles.form}
                onSubmit={e=>handleSubmit(e)}
            >
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={values.email}
                    onChange={handleChange}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={styles.submit}
              >
                Sign in
              </Button>
              <Button
                color="primary"
                sx={{
                  fontSize: '.8em',
                  marginTop: .5,
                  ':hover': {
                    backgroundColor: 'inherit'
                  }
                }}
                onClick={e => {
                e.preventDefault();
                navigate('/reset');
              }}>
                Forgot Password
              </Button>
            </form>
          </Paper>
        </Paper>
      );
    }


export default SignIn;