import React from 'react';
import {
	Grid,
	InputAdornment
} from '@mui/material';
import NumberFormat from 'react-number-format';

import CustomStyledInput from 'components/CustomStyledInput';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      displayType={onChange ? 'input' : 'text' }
    />
  );
})

const Total = ({total}) => {
	return (
		<Grid container sx={{marginTop: theme => theme.spacing(1)}}>
			<Grid
				item
				xs={6}
				sx={{
					textAlign: 'right',
					lineHeight: '32px',
					paddingRight: theme => theme.spacing(1)
				}}>
				Total:
			</Grid>
			<Grid item xs={6}>
				<CustomStyledInput
					value={total/100}
					fullWidth
					disabled
					sx={{
						'& input': {
							fontSize: '.8em !important',
							textAlign: 'center'
						}
					}}
					InputProps={{
						inputComponent: NumberFormatCustom,
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
						endAdornment: <InputAdornment position="end" sx={{'& p': {opacity: 0 }}}>%</InputAdornment>,
					}}
				/>
			</Grid>
		</Grid>
	)
}

export default Total;
