import { map, pickBy, size, find, remove, omit } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Checkbox,
	Grid,
	Typography,
	FormControlLabel,
	FormGroup,
	Switch,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Divider,
	Button,
	Paper,
	Box
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';

import { attachRecFileToPO, linkFiletoAttachment, openPO } from 'actions/pos';

const styles = {
	dropzone: {
	  flex: 1,
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'center',
	  padding: '20px',
	  borderWidth: 2,
	  borderRadius: 2,
	  borderColor: '#eeeeee',
	  borderStyle: 'dashed',
	  backgroundColor: 'background.paper',
	  color: '#bdbdbd',
	  outline: 'none',
	  transition: 'border .24s ease-in-out'
	},

// const focusedStyle = {
//   borderColor: '#2196f3'
// };

	over: {
	  borderColor: '#00e676'
	}

// const rejectStyle = {
//   borderColor: '#ff1744'
// };
}

const POAttachments = ({
	open,
	onClose,
	items,
	poId
}) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [over, setOver] = useState(false);
	const [switchChecked, setSwitchChecked] = useState(false);
	const [linkedToComment, setLinkedToComment] = useState({});
	const [files, setFiles] = useState([])

	const drag = () => setOver(prev => !prev);

	const toggleSwitch = () => setSwitchChecked(prev => !prev);


	const filteredItems = pickBy(items, item => size(find(item.comments, comment => comment.attachmentRequired))>0);

	const reset = () => {
		setSwitchChecked(false);
		setLinkedToComment({});
		setFiles([]);
		setOver(false);
	}

//CHAT GPT VERSION:
const upload = () => {
  if (size(files) === 0) return enqueueSnackbar('No files dropped/selected', { variant: 'error' });
  if (size(linkedToComment) === 0) return enqueueSnackbar('No requirements selected', { variant: 'error' });

  // Process each file
  const uploadPromises = map(files, (file) => {
    return new Promise((resolve, reject) => {
      dispatch(attachRecFileToPO(file, async (response) => {
        const { error, name, downloadURL } = response;
        if (error) {
          enqueueSnackbar(`Upload Error: ${error}`);
          reject(error);
          return;
        }
        const linkPromises = map(linkedToComment, (itemIds, commentId) => {
          const itemPromises = map(itemIds, (bool, itemId) => {
            const ids = { poId, itemId, commentId };
            const itemComment = items[itemId] && items[itemId].comments[commentId];
            const needsFile = itemComment && itemComment.attachmentRequired;
            return dispatch(linkFiletoAttachment(downloadURL, name, ids, needsFile));
          });
          return Promise.all(itemPromises);
        });
        await Promise.all(linkPromises);
        resolve();
      }));
    });
  });

  Promise.all(uploadPromises).then(() => {
    reset();
    onClose();
    dispatch(openPO(poId));
  }).catch(error => {
    // Handle the error appropriately
  });
};


	return <Dialog open={open} onClose={onClose} disableEnforceFocus>
		<DialogTitle>Attach Files to PO</DialogTitle>
		<DialogContent>
			<Grid container>
				<Grid item xs={12}>
					<Dropzone
						onDrop={acceptedFiles => {
							setFiles(prev => [ ...prev, ...acceptedFiles])
						}}
						onDragEnter={drag}
						onDragLeave={drag}
					>
						{({getRootProps, getInputProps}) => {
						const overStyle = over ? styles.over : {}
						return (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<Box
										component="p"
									sx={{ ...styles.dropzone, ...overStyle }}
									>{'Drag & drop some files here, or click to select files'}</Box>
								</div>
							</section>
						)}}
					</Dropzone>
				</Grid>
				<Grid item xs={12}>
					<List>
						{map(files, (file, fileIndex) => {
							const dl = URL.createObjectURL(file);
							const removeFile = () => {
								setFiles(prev => remove(prev, (v,i) => i !== fileIndex))
							}
							return (
								<ListItem
									key={file.name+fileIndex}
									button
									href={dl}
									component="a"
									target="_blank"
									rel="noopener noreferrer"
								>
									<ListItemText
										primary={file.name}
									/>
									<ListItemSecondaryAction>
										<IconButton onClick={removeFile}>
											<DeleteForeverIcon />
										</IconButton>
									</ListItemSecondaryAction>
							</ListItem>
							)
						})}
					</List>
				</Grid>
				<Grid item xs={12} sm={3}>
					<FormGroup>
						<FormControlLabel
							sx={{'& span': { fontSize: '.8rem'}}}
							control={<Switch
									onChange={toggleSwitch}
									checked={switchChecked}
									size="small"
								/>
							}
							label="Include All" />
					</FormGroup>
				</Grid>
				<Grid item xs={12} sm={9}>
					<Typography variant="subtitle1">Select requirement file applies to:</Typography>
				</Grid>
			</Grid>
			<Divider light />
			<Grid item xs={12}>
				{map(switchChecked ? items : filteredItems, (item, itemId) => {
					return <Paper
							key={itemId}
							elevation={2}
							sx={{
								marginTop: theme => theme.spacing(2),
								padding: theme => theme.spacing(1),
								textAlign: 'center'
							}}
						>
						<Typography
							variant="button"
						>
							{`${item.code} - ${item.description}`}
						</Typography>
						<FormGroup>
						{map(item.comments, (comment, commentId) => {
							if (!switchChecked && !comment.attachmentRequired) return null
							const isChecked = Boolean(
								linkedToComment[commentId]
								&& linkedToComment[commentId][itemId]
							)
							return <FormControlLabel
								key={commentId}
								control={<Checkbox
									size="small"
									checked={isChecked}
									onChange={() => {
										setLinkedToComment(prev => {
											return prev[commentId] && prev[commentId][itemId]
											? { ...prev, [commentId] : omit(prev[commentId], itemId) }
											: { ...prev, [commentId] : { ...prev[commentId], [itemId]: true}}
										})
									}}
								/>}
								label={comment.instruction}
								sx={{
									'& span' : {
										fontSize: '.75rem',
										textAlign: 'left'
									}
								}}
							/>
						})}
						</FormGroup>
					</Paper>
				})}
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button onClick={upload}>
				Upload Documents
			</Button>
		</DialogActions>
	</Dialog>
}

export default POAttachments;
