import { map, size, orderBy, omit, sum, values as _values, isEqual, find} from 'lodash';
import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Item from 'components/pos/item';
import Subtotal from 'components/pos/subtotal';
import Tax from 'components/pos/tax';
import Shipping from 'components/pos/shipping';
import Total from 'components/pos/total';

import { database } from 'firebase_config';
import { updatePODocs } from "actions/requirements";

const poItems = database.ref('purchasing/poItems');

const getNewItemId = async () => {
	const { key } = await poItems.push();
	return key
}

const defaultItem = {
	lineItem: 1,
	itemId: '',
	description: '',
	code: '',
	qty: 0,
	um: 'EA.',
	cost: 0,
	total: 0,
	comments: {},
	inputs: {},
	received: false
}

const Items = ({
	poId,
	setFieldValue,
	setValues,
	values,
	values: {
		subtotal,
		tax,
		taxRate,
		taxType,
		shipping,
		items,
		total,
		itemsLoaded,
		notes
	},
	incCanShow
}) => {
	// const [editRow, setEditRow] = useState(size(items));
	const dispatch = useDispatch();
	const [allItems, setAllItems] = useState(getNewItemId().then(itemId => ({...items, [itemId]: {...defaultItem, lineItem: size(items)+1}})))
	const taxes = { tax, taxRate, taxType };
	const itemsAreNotLoaded = typeof map(items)[0] === 'boolean'
	const updateItems = (item, itemId) => {
		const allNewItems = item ? { ...items, [item.itemId]: item} : omit(items, itemId);
		const poSubTotal = sum(map(allNewItems, i => {
			return i.total;
			}))
		const calcTax = taxType === 'percent'
		 ? poSubTotal*(taxRate/10000)
		 : tax
	 const poTotal = poSubTotal + calcTax + shipping
	return setValues({
			...values,
			items: allNewItems,
			subtotal: poSubTotal,
			// tax: calcTax,
			total: poTotal,
		})

	}
	useEffect(() => {
		const run = () => updateItems();
		run();
		}, [shipping, tax, taxRate, taxType]);
	useEffect(() => {
		const newId = async () => {
		if (itemsAreNotLoaded) return f=>f;
		await getNewItemId().then(itemId => {
		const blankRow = find(items, item => {
		const equal = isEqual(
			omit(item, ["itemId", "lineItem"]),
			omit(defaultItem, ["itemId", "lineItem"])
		)
		return equal;
		});
		const newLastRow = {...defaultItem, itemId, lineItem: size(items)+1};
			if (!blankRow)  setAllItems({...items, [itemId]: newLastRow})
			else setAllItems(items)
		})}
		newId();
		dispatch(updatePODocs(poId,items, setFieldValue))
	}, [items])

	return (
		<Fragment>
			{map(orderBy(_values(allItems), 'lineItem'), (item, index) => {
				return <Item
					key={item.itemId}
					onRender={incCanShow}
					item={{ ...item, lineItem: index + 1 }}
					updateItems={updateItems}
					lastRow={size(items)+1 === item.lineItem}
				/>
			})}
			<Grid container>
				<Grid item xs={7}>
					<TextField
						multiline
						rows={3}
						fullWidth
						label="Notes"
						sx={{
							margin: theme => theme.spacing(2),
							marginTop: theme => theme.spacing(4),
							paddingRight: theme => theme.spacing(5)
						}}
						InputLabelProps={{
							shrink: true
						}}
						onChange={e => {
							const { value } = e.target;
							setFieldValue('notes', value);
						}}
						value={notes || ''}
					/>
				</Grid>
				<Grid item xs={4}>
					<Subtotal subtotal={subtotal}/>
					<Tax setFieldValue={setFieldValue} {...taxes}/>
					<Shipping setFieldValue={setFieldValue} shipping={shipping}/>
					<Total total={total} />
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>

		</Fragment>
	)
}

export default Items;