// import * from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

const SnackbarListener = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const snackbarMessage = useSelector(state => state.snackbar);
  const { message, options } = snackbarMessage;
  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, options);
      // Optionally, dispatch an action to clear the message from state
    }
  }, [snackbarMessage, enqueueSnackbar, dispatch]);

  return null;
};

export default SnackbarListener;