import { map } from 'lodash';
import React from 'react';
import {
	TableRow,
	TableCell,
	TextField,
	MenuItem,
	Chip,
	FormControl,
	Input,
	Box,
	Select
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const POSearchRow = ({
	columns,
	show,
	updateSearch,
	search
}) => {
	return show &&
	<TableRow sx={{minHeight: 48}}>
		{map(columns, (column, key) => {
		  const handleChange = (event) => {
		    const {
		      target: { value },
		    } = event;
		     const searchValue = typeof value === 'string' ? value.split(',') : value;
		     updateSearch(searchValue, 'status')
		  };
			if (!column.show) return;
			if (Boolean(key === 'status')) {
			return (
		    <TableCell
		    	key={key}
					sx={{position: 'relative', padding: 0}}
				>
		      <FormControl sx={{
		      	m: 1,
		      	padding: theme => theme.spacing(0.25),
		      	margin: 0,
						width: '100%',
						paddingRight: theme => theme.spacing(1)
		      }}>
		        <Select
		          labelId="demo-multiple-chip-label"
		          id="demo-multiple-chip"
		          multiple
		          value={search.status}
		          onChange={handleChange}
		          input={<Input
		          	id="select-multiple-chip"
		          	label="Chip"
								sx={{
									'& input' : {
										width: '100%'
									}
								}}
	          	/>}
		          renderValue={(selected) => (
		            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.25 }}>
		              {selected.map((value) => (
		                <Chip key={value} label={value.slice(0,1)} size="small"/>
		              ))}
		            </Box>
		          )}
		          MenuProps={MenuProps}
		        >
		          {['OPEN', 'PARTIAL', 'CLOSED'].map((name) => (
		            <MenuItem
		              key={name}
		              value={name}
		              // style={getStyles(name, personName, theme)}
		            >
		              {name}
		            </MenuItem>
		          ))}
		        </Select>
		      </FormControl>
		    </TableCell>
			)}
			return (
				<TableCell
					key={key}
					sx={{position: 'relative'}}
				>
					<TextField
						variant="standard"
						value={search[key] || ''}
						onChange={e => {
							updateSearch(e.target.value, key)
						}}
						sx={{
							position: 'absolute',
							bottom: theme => theme.spacing(0.25),
							left: theme => theme.spacing(0.5),
							paddingRight: theme => theme.spacing(2),
							width: '100%',
							'& input' : {
								minWidth: '10px', maxWidth: '100%', width: '100%'
							}
						}}
					/>
				</TableCell>
				)
		}
		)}
	</TableRow>
}

export default POSearchRow;
