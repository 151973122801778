import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Add,
    ExpandMore
} from '@mui/icons-material';

import {
    Paper,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Fab,
    Tabs,
    Tab,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Box,
} from '@mui/material';
import PriorityHigh from '@mui/icons-material/PriorityHigh';
import { fetchItemCategories } from 'actions/items';
import Drawer from 'components/drawer';
import NewItemCategory from 'components/itemCategories/new';
import Questions from 'components/questions/main';
import Specs from 'components/specs/specs';
import ItemQuestions from 'components/itemCategories/itemCategoryQuestions';
import ItemRequirements from 'components/itemCategories/itemCategoryRequirements';

const styles = {
    root: {
        flexGrow: 1,
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
    },
    title: {
        flexGrow: 1,
    },
    button: {
        width: '-webkit-fill-available'
    }
};


const ItemCategoryAccordion = ({
    id,
    itemCategory: {
        title,
        requirements,
        critical
    }
}) => {
    const [ open, setOpen ] = useState({
        Questions: false,
        Requirements: false,
        Notes: false,
        Attachments: false
    });
    const [ expanded, setExpanded ] = useState('');
    return (
    <Accordion onChange={(e, expanded) => {
        if (expanded) {
            setExpanded(id)
        } else {
            setExpanded('')
        }
    }}>
        <AccordionSummary
            expandIcon={<ExpandMore />}
        >
        <PriorityHigh
            style={{
                color: critical ? '#f50057' : '#fff'
            }}
        />
        {title}
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={8}>
                {[
                    'Questions','Requirements','Notes','Attachments'
                ].map(button => {
                return (
                    <Grid
                        item sm={3}
                        key={button}
                        onClick={() => setOpen(prevState => ({ ...prevState, [button] : true }))}
                    >
                        <Button color="primary" sx={styles.button}>
                            {button}
                        </Button>
                    </Grid>)})
                }
            </Grid>
            {expanded === id ?
            <React.Fragment>
            <ItemQuestions
                open={open.Questions && expanded === id}
                onClose={() => setOpen(prevState => ({ ...prevState, Questions : false }))}
                itemCategoryId={id}
            />
            <ItemRequirements
                open={open.Requirements && expanded === id}
                onClose={() => setOpen(prevState => ({ ...prevState, Requirements : false }))}
                itemCategoryId={id}
                itemRequirements={requirements}
            />
            </React.Fragment>
            :null}
        </AccordionDetails>
    </Accordion>
    )
}

const ItemCategories = () => {
    const dispatch = useDispatch();
    const itemCategories = useSelector(s => s.itemCategories);
    const [open, setOpen ] = useState(false);
    const [tabIndex, setTabIndex] = useState(0)
    useEffect(() => dispatch(fetchItemCategories()), [])
    return (
        <Drawer
            header='Purchasing'
            roles={["ADMIN"]}
            active='Item Categories'
        >
            <Paper sx={styles.root}>
                <AppBar position="static">
                    <Tabs
                        value={tabIndex}
                        onChange={(e,i) => setTabIndex(i)}
                        variant="scrollable"
                    >
                        <Tab label='Item Categories'/>
                        <Tab label='Supplier Questions'/>
                        <Tab label='Purchasing Specifications'/>
                    </Tabs>
                </AppBar>
                {tabIndex === 0 &&
                <Fragment>
                <AppBar sx={styles.searchBar}  position="static" elevation={0} color="default">
                    <Toolbar>
                        <Typography variant="h6" component="h6" sx={styles.title}>Item Categories</Typography>
                        <Box display={{ xs: 'block', sm: 'none' }}  sx={styles.addPO} >
                            <Fab size="small" color="primary"onClick={() => setOpen(true)}>
                                <Add />
                            </Fab>
                        </Box>
                        <Box display={{ xs: 'none', sm: 'block' }}  sx={styles.addPO} >
                            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                                <Add />
                                Add Item Category
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
                    {_.map(itemCategories, (itemCategory, itemCategoryId) => {
                        return <ItemCategoryAccordion id={itemCategoryId} itemCategory={itemCategory} key={itemCategoryId} />
                    })}
                </Fragment>}
                {tabIndex === 1 && <Questions />}
                {tabIndex === 2 && <Specs />}
            </Paper>
            <NewItemCategory open={open} onClose={() => setOpen(false)} itemCategories={itemCategories}/>
        </Drawer>
    )
}

export default ItemCategories;
