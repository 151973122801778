import { groupBy, map, mapValues, keyBy, forEach, find, size } from 'lodash';
import { database, storage } from 'firebase_config'

export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const FETCH_SUPPLIER = 'FETCH_SUPPLIER';
export const FETCH_EVALUATION = 'FETCH_EVALUATION';
export const FETCH_SUPPLIER_ISSUES = 'FETCH_SUPPLIER_ISSUES';

const suppliers = database.ref('purchasing/suppliers/suppliers');
const deletedSuppliers = database.ref('purchasing/suppliers/deletedSuppliers');
const evaluations = database.ref('purchasing/suppliers/evalReports');
const shortEvals = database.ref('purchasing/suppliers/shortEvals');
const issues = database.ref('purchasing/issues');
const pos = database.ref('purchasing/pos');

// const supplierImport = require('convertcsv (1).json')

const  supplierEvidence = storage.ref('purchasing/supplierEvidence');
const  supplierEvalDocs = storage.ref('purchasing/supplierEvalDocs');

// ==================================
// ========Suppplier Actions=========
// ==================================

export function fetchSuppliers() {
    return dispatch => suppliers.on('value', snapshot => {
        dispatch({
            type: FETCH_SUPPLIERS,
            payload: snapshot ? snapshot.val() : null
        })
    })
}

export function fetchSupplierIssues() {
    return dispatch => issues.on('value', async (snapshot) => {
        const _issues = snapshot.val();
        const byVendor = mapValues(groupBy(map(_issues, (i, id) => ({...i, id})), 'vendorId'), (vendorIssues) => {
            return keyBy(vendorIssues, 'id');
        });
        await Promise.all(map(byVendor, async (issue, vendorId) => {
            const vendorData = await suppliers.child(vendorId).once('value').then(s => s.val());
            return byVendor[vendorId] = {issueItems: issue, vendorName: vendorData.name}
        }))
        dispatch({
            type: FETCH_SUPPLIER_ISSUES,
            payload: byVendor
        })
    })
}

export function unFetchSupplierIssues() {
    return () =>  issues.off('value')
}

export function updateSupplierIssue(values, callback) {
    return () => issues.child(values.id).update(values).then(callback)
}

export function getFirstPOs() {
    return () => suppliers.once('value').then(async snapshot => {
        const allSuppliers = snapshot.val();
        const firstPOs = await database.ref('purchasing/vendorData2022').once('value').then(s => s.val());
        forEach(allSuppliers, (supplier, supplierId) => {
            const found = find(firstPOs, first => first.vendname === supplier.name);
            if (!found || !supplier.name) return;
            const poObj = [1,2,3].map(i => found[`ponum${i}`]
            ?{
                po: found[`ponum${i}`],
                total: found[`pototal${i}`],
            }
            : null)
            const poObjNoNull = poObj.filter(e=>e !== null);
            suppliers.child(supplierId).child('initialPOs').update(poObjNoNull)
        })
    })
}
// export function importSuppliers() {
//     return () => {
//         return supplierImport.map(object => {
//             const {
//                 addr1,
//                 addr2,
//                 addr3,
//                 city,
//                 st_abbr,
//                 zip,
//                 contact_phone,
//                 phonenum
//             } = object;
//             const values = {
//                 ...object,
//               division : "",
//               linkedCategories : {},
//               primaryContact : {
//                 contact : false,
//                 email : "",
//                 firstName : "",
//                 lastName : ""
//               },
//               qualityContact : {
//                 contact : false,
//                 email : "",
//                 firstName : "",
//                 lastName : ""
//               },
//               sameAddress : false,
//                 billing: {
//                     address1: `${addr1}`,
//                     address2: `${addr2 ? `\n${addr2}` : ''}${addr3 ? `\n${addr3}` : ''}`,
//                     city: city,
//                     state: st_abbr,
//                     zip: zip,
//                 },
//                 physical: {
//                     address1: '',
//                     address2: '',
//                     city: '',
//                     state: '',
//                     zip: '',
//                 },
//                 contact_phone: `${
//                     contact_phone
//                     && contact_phone.replace(/[^0-9.]/g, "")}
//                     ${phonenum && phonenum.replace(/[^0-9.]/g, "")}`,
//                 addr1: null,
//                 addr2: null,
//                 addr3: null,
//                 city: null,
//                 st_abbr: null,
//                 zip: null,
//                 phonenum: null,
//                 unreviewed: true
//             }
//             return suppliers.push(values)
//         })
//     }
// }

export function fetchSupplier(id) {
    if (!id) { return () => {}}
    return dispatch => suppliers.child(id).on('value', async snapshot => {
        let supplier = snapshot ? { [id]: snapshot.val() } : {}
        const initialPOs = supplier.initialPOs || [];
        if (supplier[id].name && size(initialPOs) < 3) {
            const nextPOs = await pos.orderByChild('vendorId')
            .equalTo(id).limitToFirst(3).once('value')
            .then(s => {
                const poVal = s.val();
                if (!poVal) return false;
                return map(poVal, po => ({
                    po: po.po,
                    total: typeof po.total === 'number' && !isNaN(po.total)
                    ? (po.total/100).toFixed(2)
                    : po.total
                }))
            })
            const poArray = nextPOs ? nextPOs : [];
            supplier[id].initialPOs = [...initialPOs, ...poArray]
        }
        return dispatch({
            type: FETCH_SUPPLIER,
            payload: supplier
        })
    })
}

export function toggleVendorSuspension(id) {
    return () => {
        suppliers.child(id).child('suspended').transaction(s => !s)
    }
}

export function createSupplier(values, cb) {
    return () => suppliers.push(values).then(cb)
}

export function editSupplier(values, supplierId, cb) {
    return () => suppliers.child(supplierId).update(values).then(cb)
}

export function deleteSupplier(id, cb) {
    return () => suppliers.child(id).once('value').then(snapshot => {
        deletedSuppliers.child(id).update(snapshot.val()).then(()=>{
            suppliers.child(id).remove().then(cb)
        })
    })
}

export function linkSupplierCategories(id, list, cb) {
    return () => suppliers.child(id).update({ linkedCategories: list}).then(cb)
}

export function createNewEval(id) {
    return () => suppliers.child(id).update({ newEval: true });
}

export function fetchEvaluation(id) {
    return dispatch => evaluations.child(id).on('value', snapshot => {
        dispatch({
            type: FETCH_EVALUATION,
            payload: snapshot ? snapshot.val() || {} : {}
        })
    })
}

export function markCritical(id) {
    return () => suppliers.child(id).child('critical').transaction(val => {
        val = !val;
        return val
    })
}

export function answerQuestion(supplierId, qid, values, cb) {
    return () => evaluations.child(`${supplierId}/evalResponse/${qid}`).set(values).then(cb)
}

export function submitEvaluation(id, values, cb) {
    return () => evaluations.child(`${id}/evalResponse`).update(values).then(cb)
}

export function attachEvidence(supplierId, qid, files, cb) {
    return files.map(file => {
        const uploadTask = supplierEvidence.child(`${supplierId}/${qid}/${file.name}`).put(file);
        return uploadTask.on('state_changed', snapshot=>{
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            cb('progress', progress)
        }, error=>{
            cb('error', error.message)
        }, ()=>{
            uploadTask.snapshot.ref.getDownloadURL().then(url => {
            evaluations.child(`${supplierId}/evalResponse/${qid}/attachments`).push({ name: file.name, url })
                .then(() =>cb({ name: file.name, url }))
            })
        })
    })
}


export function fetchShortEval(id) {
    return () => shortEvals.child(id).once('value').then(s => {
        const vals = s.val()
        return {...vals, id}
        })
}
export function saveVendorEval(values, cb) {
    const key =  values.id ? values.id : shortEvals.push().key;
    const { files, supplierId} = values;
    return () => {
        attachEvalDocs(supplierId, key, files).then(newAttachments => {
            const attachments = [...values.attachments, ...newAttachments]
            return shortEvals.child(key).set({...values, supplierId, attachments, files: []}).then(() => {
                suppliers.child(`${supplierId}/shortEval`).set(key).then(cb)
            })
        })
    }
}


async function attachEvalDocs(supplierId, eid, files) {
    console.log(files)
        if (!files[0]) return files
        const attachments = await Promise.all(files.map(async file => {
        const location = `${supplierId}/${eid}/${file.name}`
        const uploadRef = supplierEvalDocs.child(location)

        const fileData = await uploadRef.put(file).then(async () => {
            const url = await uploadRef.getDownloadURL().then();
            return {name: file.name, url, location}
        })
        return fileData;
    }))
    return attachments
}