import React from 'react';
import {
	Grid,
	InputAdornment
} from '@mui/material';
import NumberFormat from 'react-number-format';

import CustomStyledInput from 'components/CustomStyledInput';

const styles ={
	gridContainer: {
		marginTop: 1
	},
	label: {
		textAlign: 'right',
		lineHeight: '32px',
		paddingRight: 1
	},
	textField: {
		'& div' : {
			//color: `${theme.palette.text.primary} !important`,

		},
		'& input': {
			fontSize: '.8em !important',
			textAlign: 'center'
		}
	},
	nonVis: {
		'& p': {
			color: 'background.paper'
		}
	},
};

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      displayType={onChange ? 'input' : 'text' }
    />
  );
})

const Subtotal = ({
	subtotal
}) => {
	// const classes = useStyles();
	return (
		<Grid container sx={styles.gridContainer}>
			<Grid item xs={6} sx={styles.label}>
				Subtotal:
			</Grid>
			<Grid item xs={6}>
				<CustomStyledInput
					value={subtotal/100}
					fullWidth
					disabled
					sx={{
						'& input': {
							fontSize: '.8em !important',
							textAlign: 'center'
						}
					}}
					InputProps={{
						inputComponent: NumberFormatCustom,
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
						endAdornment: <InputAdornment position="end" sx={{'& p': {opacity: 0 }}}>%</InputAdornment>,
					}}
				/>
			</Grid>
		</Grid>
	)
}

export default Subtotal;
