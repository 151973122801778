import { FETCH_ITEM_CATEGORY_QUESTIONS } from 'actions/questions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_ITEM_CATEGORY_QUESTIONS:
            return action.payload;
        default:
            return state;
    }
}