import { map, mapValues, pickBy, size } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForever'

import { fetchRequirements, deleteItemRequirement, fullyDeleteRequirement } from 'actions/requirements';
import { fetchItemCategories, fetchItems } from 'actions/items';

const RequirementList = () => {
	const dispatch = useDispatch();
	const { requirements, itemCategories, items } = useSelector(state => state);
    const requirementsWithCategories = mapValues(requirements, (req, rId) => {
    		const categoriesForRequirement = pickBy(itemCategories, (itemCat, itemCatId) => {
    			return Boolean(itemCat.requirements && itemCat.requirements[rId])
    		})
    		const itemsForRequirement = pickBy(items, (item, itemId) => {
    			return Boolean(item.requirements && item.requirements[rId])
    		})
    		const categories = mapValues(categoriesForRequirement, c => c.title)
    		const itemReq = mapValues(itemsForRequirement, c => c.name)
        return {
        	...req,
        	categories,
        	itemReq
        }
    })
	useEffect(() => {
		dispatch(fetchRequirements())
		dispatch(fetchItemCategories())
		dispatch(fetchItems())
	}, [])
	return (
			<Box>
				{map(requirementsWithCategories, (requirement, requirementId) => {
					return <Card key={requirementId} variant="outlined" sx={{m:2}}>
						<CardHeader title={requirement.instruction} />
						<CardContent>
						<div>ID: {requirementId}</div>
						Categories:
						<List>{map(requirement.categories, (cat, catId) => {
							return <ListItem key={catId}>
								<ListItemText primary={cat}/>
								<ListItemSecondaryAction>
									<IconButton>
										<DeleteIcon />
									</IconButton>
								</ListItemSecondaryAction>
								</ListItem>
						})}
						</List>
						Items:
						<ul>{map(requirement.itemReq, (item, itemId) => {
							return <ListItem key={itemId}>
								<ListItemText primary={item}/>
								<ListItemSecondaryAction>
									<IconButton
										onClick={() => {
										const cb = r => console.log('deleted', r)

										dispatch(deleteItemRequirement(itemId, requirementId, cb))

										}}
									>
										<DeleteIcon />
									</IconButton>
								</ListItemSecondaryAction>
								</ListItem>
						})}

						</ul>
						</CardContent>
						<CardActions>
							<Button
								disabled={size(requirement.categories) >0 || size(requirement.itemReq)>0}
								onClick={() => {
									dispatch(fullyDeleteRequirement(requirementId))
								}}
							>Delete Requirement</Button>
						</CardActions>
						</Card>
				})}
			</Box>
		)
}

export default RequirementList;