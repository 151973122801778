import React from 'react';
import { styled } from '@mui/material/styles';
import {
	TextField,
} from '@mui/material';

const StyledTextField = styled(TextField)(
  ({theme}) => `
  .Mui-disabled {
  	color: ${theme.palette.text.primary};
  	-webkit-text-fill-color: ${theme.palette.text.primary};
  }
  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
	}
	.MuiInput-input.Mui-disabled {
	  color: ${theme.palette.text.primary};
  	-webkit-text-fill-color: ${theme.palette.text.primary};
	}
`);

const CustomStyledInput = props => (
  <StyledTextField
    disabled
    variant="standard"
    {...props}
  />);

export default CustomStyledInput;