import { map } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
// import { object, string } from 'yup';
import { useSnackbar	} from 'notistack';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	FormControl,
	FormControlLabel,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Checkbox,
	Tooltip,
	Collapse,
	Switch
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import Cancel from '@mui/icons-material/Cancel';

import { addItemInput, editItemInput }  from 'actions/items';

const NewInput = ({
	open,
	onClose,
	itemId,
	inputEditId,
	inputEdit
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	return (
		<Formik
			enableReinitialize
			initialValues={inputEditId ? inputEdit : {
				question: '',
				options: [''],
				defaultOption: '',
				strictOptions: false
			}}
			onSubmit={(values, { resetForm }) => {
				const handleSuccess = () => {
				 	enqueueSnackbar(`Input ${inputEditId ? 'edited' : 'added'}!`, { variant: 'success' });
				 	resetForm();
				 	onClose();
				 }
				if (!inputEditId) return dispatch(addItemInput(values, itemId, handleSuccess));
				return dispatch(editItemInput(values, itemId, inputEditId, handleSuccess));

			}}
			>{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				setFieldValue,
			}) => {
				const addOption = () => {
					const newOption = values.options[0];
					if (newOption) {
						values.options.push(newOption);
						values.options[0] = '';
						setFieldValue('options', values.options);
					}
				};
				return (
					<Dialog
						open={open}
						onClose={onClose}
					>
						<Form>
							<DialogTitle>Add Item Input</DialogTitle>
							<DialogContent>
								<TextField
									name="question"
									variant="outlined"
									label="Question"
									placeholder="Enter question to prompt when item is added to PO ..."
									value={values.question}
									onChange={handleChange}
									onBlur={handleBlur}
									error={Boolean(touched.question && errors.question)}
									helperText={touched.question && errors.question}
									fullWidth
									margin="normal"
								/>
								<FormControl
									fullWidth
									variant="outlined"
									margin="normal"
								>
									<InputLabel htmlFor="option">Option</InputLabel>
									<OutlinedInput
										id="options"
										value={values.options[0]}
										label="Option"
										onKeyPress={e => {
											if (e.key === 'Enter') {
												e.preventDefault();
												return addOption();
											}
										}}
										onChange={e => {
											values.options[0] = e.target.value;
											setFieldValue('options', values.options);
										}}
										endAdornment={
											<InputAdornment position="end">
												<IconButton onClick={addOption}>
													<Add />
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
								<List>
									{map(values.options, (option, index) => {
										if (index > 0) {
											return <ListItem
												key={index+option}
												button
												onClick={() => {
													if (option === values.defaultOption) return setFieldValue('defaultOption', '')
													setFieldValue('defaultOption', option)
												}}
											>
												<Tooltip title="Select as default value">
													<Checkbox checked={option === values.defaultOption}/>
												</Tooltip>
												<ListItemText primary={option} />
												<ListItemSecondaryAction>
													<IconButton onClick={() => {
														values.options.splice(index, 1)
														setFieldValue('options', values.options);
														if (values.options.length < 2) setFieldValue('strictOptions', false)
														if (option === values.defaultOption) return setFieldValue('defaultOption', '')
													}}>
														<Cancel />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										}
									})}
								</List>
								<Collapse in={values.options.length > 1} >
									<FormControlLabel
										label="Only listed options can be used as input value"
										labelPlacement="end"
										control={
											<Switch
												checked={values.strictOptions}
												onChange={() =>{
													setFieldValue('strictOptions', !values.strictOptions)
												}}/>
										}
									/>
								</Collapse>
							</DialogContent>
							<DialogActions>
								<Button onClick={onClose}>Cancel</Button>
								<Button
									type="submit"
									variant="contained"
									color="primary"
								>
									Save
								</Button>
							</DialogActions>
						</Form>
					</Dialog>
				);
			}}
		</Formik>
	);
};

export default NewInput;
