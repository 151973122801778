import React, { useState, useEffect } from 'react';
import { isAuth } from 'firebase_config';
import { useSelector } from 'react-redux';

let AuthContext = React.createContext({user: isAuth()});

export function useAuth() {
  return React.useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(isAuth());
  const [loading,setLoading]=useState(false);
  const authUser = useSelector(state => state.authUser);

  const getAuthUser = async (res)=>{
    setLoading(true)
    const newUser = await isAuth();
    setUser(newUser)
    setLoading(false)
    return res
  }

  useEffect(()=> {
    getAuthUser();
  },[isAuth, authUser])
  let value = {loading,user, getAuthUser};

  return <AuthContext.Provider value={value}>{loading ? null : children}</AuthContext.Provider>;
}

export default AuthProvider;