import { database, auth } from 'firebase_config'

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';

const users = database.ref('users/users');
const errorLog = database.ref('errors/log');

// =================================
// ==========Error Actions==========
// =================================

export function logError(error, message) {
    const timestamp = Date.now();
    return (_, getState) => {
        const { authUser } = getState();
        return authUser.email && errorLog.push({ authUser, error, message: message || '', timestamp });
    }
}

// =================================
// ==========Auth Actions===========
// =================================

export function signIn(values, cb) {
    const { email, password } = values;
    return () => auth.signInWithEmailAndPassword(email, password)
    .then(res=>{
            cb({type: 'User', res});
            })
        .catch(res=>cb({type: 'Error', res}))
}

export function showSnackbar(payload) {
    return dispatch => dispatch({
        type: 'SHOW_SNACKBAR',
        payload
    })
}

export function resetPassword(values, cb) {
    const { email } = values;
    return () => auth.sendPasswordResetEmail(email)
    .then(res => cb({type: "User", res: "Reset link sent! [May take up to 2 minutes]"}))
    .catch(res => cb({type: 'Error', res}))
}

export function verifyAuth() {
    return dispatch=>{
        const key = 'firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]';
        auth.onAuthStateChanged(user=>{
            if (user) {
                users.child(user.uid).once('value').then(snapshot=>{
                    const dbUser = snapshot.val()
                    const userString = JSON.stringify(user)
                    window.localStorage.setItem(key, userString)
                    dispatch({
                        type: AUTH_USER,
                        payload: {...user, ...dbUser}
                    })
                })

            } else {
                window.localStorage.removeItem(key)
                dispatch({
                    type: UNAUTH_USER
                })
            }
        });
    };
}

export function logoutUser(cb) {
    return ()=>auth.signOut()
    .then(cb)
    .catch(err=>cb(err))
}
