import { map } from 'lodash';
import React, { Fragment, useState } from 'react';
import {
	Popover,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Divider,
	Box
} from '@mui/material';

import DeleteIcon from "@mui/icons-material/DeleteForever";

import EditComment from 'components/pos/itemCommentsEdit';

const styles = {
	popover: {
		padding: 1,
		width: 'calc(100vw)',
		maxWidth: theme => theme.breakpoints.values.md
	},
	listItemText: {
		maxWidth: '87%',
	}
}

const Comments = ({
	popover,
	togglePopover,
	comments,
	updateComments
}) => {
	const [edit, setEdit] = useState('');
	const toggleEdit = id => setEdit(id ? id : '')
	return (
	<Fragment>
		<Popover
			open={Boolean(popover)}
			anchorEl={popover}
			onClose={togglePopover}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			<Box component="div" sx={styles.popover}>
				<List>
					{map(comments, (comment, commentId) => {
					const { title, instruction, url } = comment || {}
						return (
							<Fragment key={commentId}>
							{edit !== commentId
								? <ListItem onClick={() => toggleEdit(commentId)}>
									<ListItemText
										primary={title}
										secondary={url? <a href={url}>Follow link for specification</a> : instruction}
										sx={styles.listItemText}
									/>
									<ListItemSecondaryAction>
										<IconButton onClick={() => updateComments(false, commentId)}>
											<DeleteIcon/>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
								: <EditComment
									comment={comment}
									commentId={commentId}
									label={title}
									closeEdit={toggleEdit}
									updateComments={updateComments}
								/>
							}
							<Divider />
							</Fragment>
						)
					})}
				</List>
				<EditComment updateComments={updateComments}/>
			</Box>
		</Popover>
		</Fragment>
		)
}

export default Comments;