import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const ComposedTextField = (props) => {
  const {
    name,
    label,
    formikProps: {
      values,
      handleBlur,
      handleChange,
      errors,
      touched
    },
    required
  } = props;
  return (
    <TextField
      name={name}
      label={label}
      value={values[name]}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      required={required}
      error={errors[name] && touched[name] }
      helperText={errors[name] && touched[name] }
    />
)}

const Company = React.forwardRef(({
	formikProps,
}, ref) => {
	return(
	<div  ref={ref} style={{width: '100%'}}>
      <Typography variant="h6" gutterBottom>
        Purchasing Contact
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ComposedTextField
            required
            formikProps={formikProps}
            name="name"
            label="Company name"
          />
        </Grid>
        <Grid item xs={12}>
          <ComposedTextField
            formikProps={formikProps}
            name="division"
            label="Division"
          />
        </Grid>
      </Grid>
    </div>
  );
});

export default Company;
