import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Typography
} from '@mui/material'
import {DesktopAccessDisabled} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const styles = {
    root: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        margin: theme => theme.spacing(1)
    },
    card: {
        margin: 'auto',
        maxWidth: 520,
        height: 800
    },
    textField: {
        marginLeft: theme => theme.spacing(1),
        marginRight: theme => theme.spacing(1),
        width: 300,
    },
    button: {
        //marginLeft: 'auto'
    }
};

const Restricted = ()=>{
    const navigate = useNavigate();
    return (
    <div sx={styles.root}>
        <Card sx={styles.card} raised>
        <CardHeader title='Restricted Access' />

        <CardContent>
        <Typography
            variant='h2'
            align='center'
        >
            <DesktopAccessDisabled
                style={{
                    fontSize: 240,
                    color: '#eee'
                }}
            />
            <div>Looks like you don't have permission to view this page</div>
        </Typography>
        </CardContent>
        <CardActions >
            <Button
                sx={styles.button}
                color='primary'
                onClick={()=>navigate(-1)}>
                Go Back
            </Button>
        </CardActions >
        </Card>
    </div>
    );
}


export default Restricted;