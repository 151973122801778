import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import { createItemCategory } from 'actions/items';

const NewItemCategory = ({
    open,
    onClose,
    itemCategories
}) => {
    const dispatch = useDispatch();
    const [title, setName] = useState('');
    const [critical, setCritical] = useState(false);
    const [clone, setClone] = useState(false);
    const [comment, setComment] = useState(false);
    const [cloneId, setCloneId] = useState('');
    const [error, setError] = useState('');
    const submit = e => {
        e.preventDefault();
        if(title.length > 0) {
            dispatch(createItemCategory({ title, critical, comment }, cloneId));
            onClose();
            setName('');
        } else {
            setError('A category name is required')
        }

    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
        <DialogTitle>Add New Category</DialogTitle>
        <form onSubmit={submit} >
            <DialogContent>
                <TextField
                    value={title}
                    onChange={e => {
                        setName(e.target.value)
                        setError('')
                    }}
                    label="Category Name"
                    fullWidth
                    error={Boolean(error)}
                    helperText={error}
                />
                <FormControlLabel
                    label="Mark category as critical"
                    control={
                        <Checkbox
                            value={critical}
                            onChange={() => setCritical(prev => (!prev))}
                        />
                    }
                />
                <br />
                <FormControlLabel
                    control={<Switch
                        checked={comment}
                        onChange={() => setComment(prev => !prev)}
                    />}
                    label={<Typography variant="button">Comment</Typography>}
                    labelPlacement="start"
                />
                <FormControlLabel
                    control={<Switch
                        checked={clone}
                        onChange={() => {
                            setClone(!clone)
                            if (!clone) { setCloneId('') }
                        }}
                    />}
                    label={<Typography variant="button">Clone</Typography>}
                    labelPlacement="start"
                />
                <Collapse in={clone}>
                    <TextField
                        name='clone'
                        value={cloneId}
                        onChange={e => setCloneId(e.target.value)}
                        select
                        style={{minWidth: 263}}
                    >
                        <MenuItem value=""></MenuItem>
                        {_.map(itemCategories, ({title}, id) => {
                            return <MenuItem value={id} key={id}>{title}</MenuItem>
                        })}
                    </TextField>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button
                    type='submit'
                    color="primary"
                    variant="contained"
                >
                    Add
                </Button>
            </DialogActions>
        </form>
        </Dialog>
    )
}

export default NewItemCategory;
