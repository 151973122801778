import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ComposedTextField = (props) => {
  const {
    title,
    label,
    parent,
    formikProps: {
      values,
      handleBlur,
      handleChange,
      errors,
      touched
    },
    required
  } = props;
  return (
    <TextField
      name={`${parent}.${title}`}
      label={label}
      value={values[parent][title]}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errors[parent] && touched[parent]  && errors[parent][title])&& touched[parent][title] }
      helperText={errors[parent] && touched[parent] && touched[parent][title] && errors[parent][title]}
      fullWidth
      required={required}
    />
)}

const Shipping = React.forwardRef(({
	billing,
	formikProps,
	parent
}, ref) => {
	return(
	<div  ref={ref} style={{width: '100%'}}>
      <Typography variant="h6" gutterBottom>
        {billing ? 'Mailing' : 'Shipping'} address
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ComposedTextField
            required
            formikProps={formikProps}
            parent={parent}
            id={`${billing && "billing "}address-line1`}
            title="address1"
            label="Address line 1"
            autoComplete={`${billing && "billing "}address-line1`}
          />
        </Grid>
        <Grid item xs={12}>
          <ComposedTextField
            formikProps={formikProps}
            parent={parent}
            id={`${billing && "billing "}address-line2`}
            title="address2"
            label="Address line 2"
            autoComplete={`${billing && "billing "}address-line2`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ComposedTextField
            required
            formikProps={formikProps}
            parent={parent}
            id={`${billing && "billing "}address-level2`}
            title="city"
            label="City"
            autoComplete={`${billing && "billing "}address-level2`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ComposedTextField
            formikProps={formikProps}
            parent={parent}
            required
            id="state"
            title="state"
            label="State/Province/Region"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ComposedTextField
            required
            formikProps={formikProps}
            parent={parent}
            id={`${billing && "billing "}postal-code`}
            title="zip"
            label="Zip / Postal code"
            autoComplete={`${billing && "billing "}postal-code`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="sameAddress"
                onChange={() => formikProps.setValues({
                  sameAddress: !formikProps.values.sameAddress,
                  physical: formikProps.values.physical,
                  qualityContact: formikProps.values.qualityContact,
                  primaryContact: formikProps.values.primaryContact,
                  name: formikProps.values.name,
                  division: formikProps.values.division,
                  billing: !formikProps.values.sameAddress ?
                    formikProps.values.physical
                    :
                    {
                			address1: '',
                			address2: '',
                			city: '',
                			state: '',
                			zip: ''
                		},
                  })}
                value="yes"
                checked={formikProps.values.sameAddress}
                />}
            label={billing ?
            'Same as physical address' :
            'Use this address for mailing details'}
          />
        </Grid>
      </Grid>
    </div>
  );
});

export default Shipping;
