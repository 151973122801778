import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { CSVLink, CSVDownload } from 'react-csv';

import {
  AppBar,
  Toolbar,
  Button,
  Fab,
  InputBase,
  IconButton,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';

import {
  fetchSuppliers,
  getFirstPOs
} from 'actions/suppliers';

import {
  Add,
  Search,
  PriorityHigh
} from '@mui/icons-material';
import { red } from '@mui/material/colors'

import NewSupplier from 'components/suppliers/new';
import Supplier from 'components/suppliers/supplier';

const styles = {
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    minWidth: 310
  },
  title: {
    flexGrow: 1,
  },
  iconButton: {
    padding: theme => theme => theme.spacing(1),
  },
  input: {
    marginLeft: 1,
    flex: 1,
  },
  button: {
    position: 'absolute',
    right: theme => theme.spacing(1)
  },
  deleteButton: {
    position: 'relative',
    top: theme => theme.spacing(1),
    right: theme => theme.spacing(1)
  },
  divider: {
    width: 1,
    height: 28,
    margin: "4px 16px 4px 4px"
  },
  grid: {
    display: 'flex'
  },
  cardTitle: {
    fontSize: '.75em'
  },
  cardAction: {
    position: 'absolute',
    bottom: theme => theme.spacing(1),
    right: theme => theme.spacing(1),
  },
  expansion: {
    width: '100%'
  },
  critical: {
    color: red[400]
  }
};

const Suppliers = () => {
    const dispatch = useDispatch();
    useEffect(() => dispatch(fetchSuppliers()), []);
    const [ open, setOpen ] = useState(false);
    const [ term, setTerm ] = useState('')
    const [supplierId, setSupplierId] = useState('');
    const suppliers = useSelector(state => state.suppliers)

    const filter = (searchString) => {
      return searchString.toLowerCase().search(term.toLowerCase()) > -1
    }

    const byYT = (_.orderBy(_.map(suppliers, (s, id) => ({...s, id})), [(o) => Boolean(o.yearTotals && o.yearTotals['2022'] ), (o) => o.yearTotals && o.yearTotals['2022']], ['desc', 'desc']))
    return (
      <Fragment>
        <Button onClick={()=> dispatch(getFirstPOs())}>Get First POs</Button>
        <AppBar position="static" elevation={0} color="default">
          <Toolbar>
            <Paper sx={styles.root} elevation={0} >
              <InputBase
                sx={styles.input}
                placeholder="Search vendor list"
                value={term}
                onChange={e => setTerm(e.target.value)}
              />
              <IconButton sx={styles.iconButton} >
                <Search />
              </IconButton>
            </Paper>
            <Box display={{ xs: 'block', sm: 'none' }} sx={styles.button}>
              <Fab
                size="small"
                color="primary"
                onClick={() => setOpen(true)}
              >
                  <Add />
              </Fab>
            </Box>
            <Box display={{ xs: 'none', sm: 'block' }} sx={styles.button}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}>
                <Add />
                Add Vendor
            </Button>
            {/*<Button onClick={importSuppliers}>import</Button>*/}
            </Box>
          </Toolbar>
          <NewSupplier open={open} onClose={() => setOpen(false)} />

            <Supplier
              open={Boolean(supplierId)}
              onClose={() => setSupplierId('')}
              supplierId={supplierId}
            />
        </AppBar>
          <List>
            {_.map(byYT, (supplier) => {
            const supplierId = supplier.id
              return filter(`${supplier.name} ${supplier.division}`) &&
              <ListItem  key={supplierId} button onClick={() => setSupplierId(supplierId)}>
          <ListItemIcon sx={styles.critical}>
            {supplier.critical ? <PriorityHigh /> : ''}
          </ListItemIcon>
                  <ListItemText
                    primary={`${supplier.name} ${supplier.division ? `- ${supplier.division}` : '' }`}
                    secondary={supplier.physical.city && `${supplier.physical.city}, ${supplier.physical.state}`}
                  />
              </ListItem>
            })}
            </List>
        </Fragment>
)}


export default Suppliers;