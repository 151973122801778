import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useAuth } from 'components/AuthProvider';
import { useSnackbar } from 'notistack';

import { resetPassword } from 'actions';


const styles = {
  main: theme => ({
    width: 'auto',
    maxWidth: 400,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(448)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
  paper: {
    marginTop: theme => theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme => `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme => theme.spacing(1),
    backgroundColor: theme => `${theme.palette.secondary.main} !important`,
    color: theme => `${theme.palette.primary.main} !important`,
  },
  form: {
    //width: '100%', // Fix IE 11 issue.
    marginTop: theme => theme.spacing(2),
    marginLeft: theme => theme.spacing(3),
    marginRight: theme => theme.spacing(3),
    marginBottom: theme => theme.spacing(3),
  },
  submit: {
    marginTop: theme => theme.spacing(4),
    //backgroundColor: theme.palette.primary.main
  },
  iconButton: {
    backgroundColor: theme => `${theme.palette.primary.main} !important`,
    height: 44,
    width: 44
  }
};

const Reset = () => {
    const navigate = useNavigate();
    // const location = useLocation();
    const { getAuthUser } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    // const classes = useStyles();
    const dispatch= useDispatch();
    const [values, setValues] = useState({
      email: ''
    });
    // const [errors, setErrors] = useState({});

    const handleChange = e=>{
        setValues(prevState => ({ ...values, [e.target.name]: e.target.value}));
    };

    const handleSubmit = e=>{
        e.preventDefault();
        dispatch(resetPassword(values, ({type, res}) => getAuthUser().then(()=>{
            switch(type) {
                case 'Error':
                    enqueueSnackbar(res + '', {variant: 'error'});
                    break;

                case 'User':
                    enqueueSnackbar(res + '', {variant: 'success'});
                    reDirect();
                    break;
                default:
                  break;
            }
        })));
    };
    const reDirect= () => navigate("/");

    return (
        <Paper sx={styles.main}>
          <Paper sx={styles.paper}>
            <IconButton sx={styles.iconButton}>
            <Avatar sx={styles.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            </IconButton>
            <Typography component="h1" variant="h5" color="primary">
              Gulf-Pro Purchasing Module
            </Typography>
            <form
                sx={styles.form}
                onSubmit={e=>handleSubmit(e)}
            >
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={values.email}
                    onChange={handleChange}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={styles.submit}
              >
                Send Reset Link
              </Button>
            </form>
          </Paper>
        </Paper>
      );
    };


export default Reset;