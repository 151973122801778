import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Chip
} from '@mui/material';
import { Formik, Form } from 'formik';
import { object, string, array } from 'yup';
import Dropzone from 'react-dropzone';
import { useSnackbar } from 'notistack';


import { editSpec, createSpec } from 'actions/specs';

const styles = {
	input: {
		marginTop: theme => theme.spacing(1),
		marginBottom: theme => theme.spacing(1),
	},
	dropzone: {
		marginBottom: theme => theme.spacing(1),
		width: '100%',
		height: 56,
		border: '2px dashed gray',
		borderRadius: theme => theme.spacing(1)
	},
	dropzoneError: {
		marginBottom: theme => theme.spacing(1),
		width: '100%',
		height: 56,
		border: '2px dashed #F44336',
		borderRadius: theme => theme.spacing(1)
	},
	error: {
		color: '#F44336',
		fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
	}
}

const NewSpec = ({
	open,
	onClose,
	spec,
	specId
}) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [ dropped, setDropped ] = useState('');
	const read = dropFile => {
		const reader = new FileReader();

		return new Promise((resolve, reject) => {
			reader.onerror = () => {
				reader.abort();
				reject("Problem parsing input file.")
			}
			reader.onload = () => {
				resolve(reader.result)
			}
			reader.readAsDataURL(dropFile)
		})
	}
	return (
		<Formik
		initialValues={{
			title: spec ? spec.title : '',
			file: []
		}}
		validationSchema={
			object().shape({
				title: string().required(),
				file: array().min(1).required()
			})
		}
		onSubmit={(values, {resetForm}) => {
			const cb = () => {
				resetForm();
				onClose();
				enqueueSnackbar("Spec Added", {variant: 'success'});
			}
			specId
			?	dispatch(editSpec(specId, values, cb))
			: dispatch(createSpec(values, cb));
		}}
		>{({
			values: {
				title,
				file
			},
			errors,
			touched,
			handleChange,
			handleBlur,
			setFieldValue,
			setFieldError,
			handleSubmit,
			resetForm
		}) => (
		<Form>
		<Dialog
			open={open}
			onClose={() => { onClose(); resetForm();}}
			maxWidth='sm'
			fullWidth
		>
			<DialogTitle>
				{spec ? 'Edit' : 'Add'} Purchasing Spec
			</DialogTitle>
			<DialogContent>
				<TextField
					value={title}
					onBlur={handleBlur}
					onChange={handleChange}
					name="title"
					variant="outlined"
					label="Title"
					fullWidth
					sx={styles.input}
					helperText={touched.title && errors.title}
					error={Boolean(errors.title)}
				/>
				<Dropzone
					accept='application/pdf'
					onDropAccepted={async acceptedFiles => {
						const file = acceptedFiles[0]

						try {
							const fileContents = await read(file);
							setDropped(fileContents);
							setFieldValue('file', acceptedFiles);
							setFieldError('file', '')
						} catch(e) {
							setFieldError('file', e)
						}
					}}
					onDropRejected={rejectedFiles => {
						setFieldError('file', `Only a single file of type ".pdf" is allowed`)
					}}
				>
				  {({getRootProps, getInputProps}) => (
				    <section>
				      <div {...getRootProps()}>
				        <input {...getInputProps()} />
				        <div style={!!errors.file && touched.file ? styles.dropzoneError :  styles.dropzone}>
				        	Drop or Click Here to add files
				        </div>
				      </div>
				    </section>
				  )}
				</Dropzone>
				{!!errors.file && touched.file ? <div style={styles.error}>{errors.file}</div> : null}
				{spec && !file[0] ? <a target="_blank" rel="noopener noreferrer"  href={spec.url}>{spec.title}</a>
				: null}
				{dropped && file[0] ?
					<Chip onDelete={()=>setFieldValue('file', [])} label={file[0].path} color="secondary"/>
				: null}
			</DialogContent>
			<DialogActions><Button onClick={() => {
				onClose();
				resetForm();
			}}>Cancel</Button>
				<Button color="primary" variant="contained" type="submit" onClick={handleSubmit}>
					{spec ? 'Update' : 'Add'}
				</Button>
			</DialogActions>
		</Dialog>
		</Form>
		)}
		</Formik>
	)
}

export default NewSpec;
