import { OPEN_INVOICE, CLOSE_INVOICE, NEW_INVOICE } from 'actions/invoices';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case OPEN_INVOICE:
            return action.payload;
        case CLOSE_INVOICE:
            return INITIAL_STATE;
        case NEW_INVOICE:
            return action.payload;
        default:
            return state;
    }
}