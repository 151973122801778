import { find, mapValues, map, replace, first, split, remove, sortBy, findIndex, pickBy } from 'lodash';
import { database, storage } from 'firebase_config';
import { v4 as uuidv4 } from 'uuid';

import { showSnackbar, logError } from 'actions';

export const INVOICE_NUMBER = 'INVOICE_NUMBER';
export const INVOICES = 'INVOICES';
export const INVOICE = 'INVOICE';
export const OPEN_INVOICE = 'OPEN_INVOICE';
export const CLOSE_INVOICE = 'CLOSE_INVOICE';
export const NEW_INVOICE = 'NEW_INVOICE';


const purchasing = database.ref('purchasing');
const invoices = database.ref('purchasing/invoices');
const deleted = database.ref('purchasing/deletedInvoices')
// const poNumber = database.ref('purchasing/poNumber');
const invoiceAttachments = storage.ref('purchasing/invoiceAttachments');


export function fetchInvoices(all) {
	const which_invoices = all
	? invoices
	: invoices.orderByChild('status').equalTo('OPEN')
	return dispatch => which_invoices.on('value', snapshot => {
		dispatch({
			type: INVOICES,
			payload: snapshot?.val()
		})
	})
}


export function openInvoice(id) {
	return dispatch => id
	? invoices.child(id).on('value', snapshot => {
		dispatch({
			type: OPEN_INVOICE,
			payload: snapshot?.val()
		})
	})
	: dispatch({
		type: NEW_INVOICE,
		payload: {new: true}
	})
}
export function closeInvoice() {
	return dispatch => dispatch({
			type: CLOSE_INVOICE,
			payload: null
	})
}