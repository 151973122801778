import {
  map,
  size,
  pickBy,
  mapValues,
  includes,
  filter,
  omit,
  add,
} from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  AvatarGroup,
  Avatar,
  TextField,
  MenuItem,
  Badge,
  IconButton,
  Box,
  Tooltip
} from "@mui/material";
import { green, orange, yellow } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import FilePresentIcon from "@mui/icons-material/FilePresent";
import WarningIcon from "@mui/icons-material/Warning";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { v4 } from 'uuid'

import { database } from "firebase_config";

import { verifyRequirement, updateComment, updatePOItem,unlinkFileToAttachment, openPO as _openPO, updatePOLastRec } from "actions/pos";

import POAttachments from "components/pos/poAttachments";
import POIssues from "components/pos/issues";
import { useConfirm } from "components/confirm";

const spin = keyframes`
  0% {
    box-shadow: 6px 0 10px 0 rgba(0,155,229, .7);
  }
  12.5% {
    box-shadow: 4.6px 4.6px 10px 0 rgba(0,155,229, .7);
  }
  25% {
    box-shadow: 0 6px 10px 0 rgba(0,155,229, .7);
  }
  37.5% {
    box-shadow: -4.6px 4.6px 10px 0 rgba(0,155,229, .7);
  }
  50% {
    box-shadow: -6px 0 10px 0 rgba(0,155,229, .7);
  }
  62.5% {
    box-shadow: -4.6px -4.6px 10px 0 rgba(0,155,229, .7);
  }
  75% {
    box-shadow: 0 -6px 10px 0 rgba(0,155,229, .7);
  }
  87.5% {
    box-shadow: 4.6px -4.6px 10px 0 rgba(0,155,229, .7);
  }
  100% {
    box-shadow: 6px 0 10px 0 rgba(0,155,229, .7);
  }
`;

const LoadingIconButton = styled(IconButton)(({ theme }) => ({
  '&.Mui-disabled': {
    // animation: `$rotatingShadow linear 1.5s infinite`,
    // WebkitAnimation: `$rotatingShadow linear 1.5s infinite`,
      //boxShadow: '10px 0 0 0 red',
      animation: `${spin} .7s infinite ease`
  },
}));


const Receive = ({ items, poId, toggle: _toggle, open, values }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [attach, setAttach] = useState(false);
  const [expand, setExpand] = useState({});
  const [verifiedValueLocal, setVerifiedValueLocal] = useState("");
  const [received, setReceived] = useState({});
  const [verified, setVerified] = useState({});
  const [expenseTypes, setExpenseTypes] = useState({});
  const [issues, setIssues] = useState(false);
  const { openPO } = useSelector(s => s);
  const [recKey] = useState(v4());
  const [pending, setPending] = useState(false);


  const generateRecForm = () => {
    setPending(true);
    database.ref(`/purchasing/pos/${poId}`).update({
      receivingFormUpdate: recKey,
      receivingReportURL: null
    })
  }

  useEffect(() => {
    if (openPO.receivingReportURL) {
      setPending(false)
    }
  }, [openPO.receivingReportURL]);

  const toggle = () => {
    _toggle();
    setReceived({});
  };
  const toggleAttach = () => setAttach((prev) => !prev);
  const toggleIssues = () => setIssues(prev => !prev);
  const checkVerified = () => {
    return mapValues(items, ({ comments }) => {
      return mapValues(comments, (comment) => Boolean(comment.verified));
    });
  };
  const verifiedInputs = () => {
    let inputs = {};
    map(items, ({ comments }, itemId) => {
      map(
        comments,
        (comment, commentId) =>
          (inputs = {
            ...inputs,
            [itemId + commentId]: comment.verifiedValue || "",
          })
      );
    });
    return inputs;
  };

  const fetchExpenseCategories = async () => {
    const categories = await database
      .ref("purchasing/expenseTypes")
      .once("value")
      .then((s) => s.val());
    return setExpenseTypes(categories);
  };
  useEffect(() => {
    fetchExpenseCategories();
  }, []);

  useEffect(() => {
    setVerified(checkVerified());
    setVerifiedValueLocal(verifiedInputs());
  }, [items]);

  const toggleVerify = async (itemId, commentId) => {
    return setVerified((prev) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [commentId]: !prev[itemId][commentId],
      },
    }));
    //dispatch(verifyRequirement(itemId, commentId));
  };

  const needsVerifiedCount = (item) =>
    size(pickBy(item.comments, (comment) => comment.verify === true));
  const isVerifiedCount = (item) =>
    size(pickBy(item.comments, (comment) => comment.verified === true));
  const receiveItems = () => {
    const cb = () => {
      toggle();
      dispatch(updatePOLastRec());
      // dispatch(_openPO(poId))
    }
    Promise.all(map(items, (item, itemId) => {
      const newCount = parseFloat(add(item.qtyReceived || 0, received[itemId] || 0));
      const allReceived = newCount >= item.qty;
      // console.log({allReceived, newCount, rec: received[itemId], ex: item.expenseType }, isVerifiedCount(item), needsVerifiedCount(item), allReceived)
      if (
        needsVerifiedCount(item) <= isVerifiedCount(item) &&
        item.expenseType
      ) {
        return dispatch(
          updatePOItem({ received: allReceived, qtyReceived: newCount, poId }, itemId)
        );
      }
    })).then(cb);
  };
  const recReport = openPO.receivingReportURL && openPO.receivingFormUpdate === recKey
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={toggle}
        // disableEnforceFocus
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Receive PO Items
          <Box sx={{position: 'absolute', top: t=>t.spacing(1), right: t=>t.spacing(1)}}>
            <LoadingIconButton onClick={recReport ? f=>f : generateRecForm} disabled={pending} component={recReport ? 'a' : 'button'} target="_blank" rel="noopener noreferrer" href={recReport ? openPO.receivingReportURL: ''}>
              <Tooltip title={recReport ? "Download Receiving Report" : "Create Receiving Report"}>
                <SaveAltIcon color={recReport ? 'primary' : 'inherit'}/>
              </Tooltip>
            </LoadingIconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {map(items, (item, itemId) => {
            if (!item) return null;
            const vCount = isVerifiedCount(item);
            const nvCount = needsVerifiedCount(item);
            const opacity = nvCount ? vCount / nvCount : 1;
            const receivedQty =
              parseFloat(item.qtyReceived || 0) +
              parseFloat(received[itemId] || 0);
            return (
              <Accordion
                key={itemId}
                TransitionProps={{ unmountOnExit: true }}
                expanded={Boolean(expand[itemId])}
                onChange={(event, expanded) => {
                  const { target } = event;
                  const clNameOmit = (clName) =>
                    includes(target.className, clName);
                  if (clNameOmit("MuiBackdrop") || clNameOmit("MuiMenuItem")) {
                    return setExpand((prev) => omit(prev, itemId));
                  }
                  setExpand((prev) => ({ ...prev, [itemId]: expanded }));
                }}
              >
                <AccordionSummary
                // sx={{backgroundColor: grey[200]}}
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid
                      item
                      xs={1}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {opacity >= 1 &&
                      item.expenseType &&
                      receivedQty >= item.qty ? (
                        <CheckCircleOutlineIcon
                          sx={{
                            color: green[500],
                          }}
                        />
                      ) : (
                        <>
                          <RadioButtonUncheckedIcon
                            sx={{
                              color: yellow[500],
                              transform: "translateX(12px)",
                              opacity: 1 - opacity,
                            }}
                          />
                          <RadioButtonUncheckedIcon
                            sx={{
                              zindex: 10,
                              color: green[500],
                              transform: "translateX(-12px)",
                              opacity,
                            }}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      {item.description}
                    </Grid>
                    <Grid item xs={3}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ textAlign: "center" }}
                      >
                        <Grid item xs={6}>
                          <div>Qty.</div>
                          <div>{`${receivedQty}/${item.qty}`}</div>
                        </Grid>
                        <Grid item xs={6}>
                          <div>Req.</div>
                          <div>{`${vCount}/${nvCount}`}</div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        select
                        label="Expense Type"
                        fullWidth
                        sx={{ zIndex: 1000 }}
                        value={item.expenseType || ""}
                        onChange={({ target }) => {
                          const cb = () => {
                          dispatch(_openPO(poId))
                          }
                          dispatch(
                            updatePOItem({ expenseType: target.value, poId }, itemId, cb)
                          );
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        {map(expenseTypes, (exp, expId) => {
                          return (
                            <MenuItem key={expId} value={exp}>
                              {exp}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="left"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <TextField
                            label="Current Qty Received"
                            fullWidth
                            value={received[itemId] || ""}
                            onChange={(e) => {
                              const { value } = e.target;
                              setReceived((prev) => ({
                                ...prev,
                                [itemId]: parseFloat(value),
                              }));
                            }}
                            error={Boolean(receivedQty > item.qty)}
                            helperText={
                              Boolean(receivedQty > item.qty) &&
                              "Received quantity exceeds order amount"
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {map(item.comments, (comment, commentId) => {
                      const {
                        attachmentRequired,
                        instruction,
                        verifyValue,
                        verifyValueLabel,
                      } = comment;
                      const { attachments } = items[itemId];
                      const commentAttachments = filter(
                        attachments,
                        (att) => att.commentId === commentId
                      );
                      return (
                        true && (
                          <Grid key={commentId} item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={1}>
                                <Checkbox
                                  checked={
                                    verified[itemId] &&
                                    verified[itemId][commentId]
                                  }
                                  onClick={() =>
                                    attachmentRequired
                                      ? (f) => f
                                      : toggleVerify(itemId, commentId).then(
                                          () =>
                                            dispatch(
                                              verifyRequirement(
                                                itemId,
                                                commentId
                                              )
                                            )
                                        )
                                  }
                                />
                              </Grid>
                              <Grid item xs={5}>
                                {instruction}
                              </Grid>
                              <Grid item xs={3}>
                                {verifyValue && (
                                  <TextField
                                    value={
                                      verifiedValueLocal[itemId + commentId]
                                    }
                                    onChange={(e) => {
                                      setVerifiedValueLocal((prev) => {
                                        return {
                                          ...prev,
                                          [itemId + commentId]: e.target.value,
                                        };
                                      });
                                    }}
                                    onBlur={(e) =>
                                      dispatch(
                                        updateComment(
                                          e.target.value,
                                          "verifiedValue",
                                          itemId,
                                          commentId
                                        )
                                      )
                                    }
                                    label={verifyValueLabel || "Verified Value"}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={3}>
                                <AvatarGroup>
                                  {size(commentAttachments) === 0 &&
                                  comment.attachmentRequired ? (
                                    <Badge
                                      badgeContent={<WarningIcon />}
                                      sx={{ color: orange[400] }}
                                    >
                                      <Avatar onClick={toggleAttach}>
                                        <FilePresentIcon />
                                      </Avatar>
                                    </Badge>
                                  ) : (
                                    map(
                                      commentAttachments,
                                      (commentAttachment) => {
                                        return (
                                          <Badge
                                            badgeContent={<HighlightOffIcon
                                              onClick={() => {
                                              confirm({
                                                title: `Are you sure you want to delete: ${commentAttachment.name} ?`,
                                                description: "",
                                                confirmationText: "Yes, delete",
                                                cancellationText:
                                                  "Cancel delete",
                                              }).then(() => {
                                               const cb = () => dispatch(_openPO(poId));
                                                return dispatch(unlinkFileToAttachment(commentAttachment.downloadURL, itemId, cb))
                                              })
                                              .catch(f => f);
                                            }}
                                            />}
                                            // sx={{color: orange[400]}}

                                            key={commentAttachment.downloadURL}
                                          >
                                            <Avatar
                                              component="a"
                                              href={
                                                commentAttachment.downloadURL
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              sx={{
                                                backgroundColor:
                                                  comment.attachmentRequired
                                                    ? green[300]
                                                    : null,
                                              }}
                                            >
                                              <FilePresentIcon />
                                            </Avatar>
                                          </Badge>
                                        );
                                      }
                                    )
                                  )}
                                </AvatarGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleAttach}>Attach Documents</Button>
          <Button onClick={toggleIssues} color="secondary" variant="outlined">
            Report Nonconformance
          </Button>
          <Button onClick={receiveItems} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <POIssues
        open={issues}
        onClose={toggleIssues}
        items={items}
        poId={poId}
      />
      <POAttachments
        open={attach}
        onClose={toggleAttach}
        items={items}
        poId={poId}
      />
    </Fragment>
  );
};

export default Receive;
