import React from 'react';
import {
	Grid,
	InputAdornment,
	TextField
} from '@mui/material';
import NumberFormat from 'react-number-format';


// const styles = {
// 	gridContainer: {
// 		marginTop: 1
// 	},
// 	label: {
// 		textAlign: 'right',
// 		lineHeight: '32px',
// 		paddingRight: 1
// 	},
// 	textField: {
// 		'& div' : {
// 			//color: `${theme.palette.text.primary} !important`,

// 		},
// 		'& input': {
// 			fontSize: '.8em !important',
// 			textAlign: 'center'
// 		}
// 	},
// };

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue * 100,
          },
        });
      }}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      displayType={'input'}
      allowLeadingZeros={false}
    />
  );
})

const Shipping = ({
	shipping,
	setFieldValue
}) => {
  const handleChange = e => {
  	return setFieldValue('shipping', e.target.value);
  }
  const styles = {
  	label: {
  		'& p': {color: theme => `${theme.palette.text.primary} !important`,
  		},
		tag: {
			textAlign: 'right'
		}
  	}
  }
	return (
		<Grid container sx={styles.gridContainer}>
			<Grid item xs={6} sx={{
					textAlign: 'right',
					lineHeight: '32px',
					paddingRight: theme => theme.spacing(1)
				}}>
				Shipping:
			</Grid>
			<Grid item xs={6}>
				<TextField
					value={shipping/100}
					fullWidth
					onChange={handleChange}
					variant="standard"
					sx={{
						'& div' : {
							color: theme => `${theme.palette.text.primary} !important`,

						},
						'& input': {
							fontSize: '.8em !important',
							textAlign: 'center'
					}}}
					InputProps={{
						inputComponent: NumberFormatCustom,
						startAdornment: <InputAdornment sx={styles.label} position="start">$</InputAdornment>,
						endAdornment: <InputAdornment position="end" sx={{'& p': {opacity: 0 }}}>%</InputAdornment>
					}}
				/>
			</Grid>
		</Grid>
	)
}

export default Shipping;