import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ComposedTextField = (props) => {
  const {
    name,
    label,
    parent,
    formikProps: {
      values,
      handleBlur,
      handleChange,
      errors,
      touched
    },
    required
  } = props;
  return (
    <TextField
      name={`${parent}.${name}`}
      label={label}
      value={values[parent][name]}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      required={required}
      error={errors[parent] && touched[parent] && Boolean(touched[parent][name] && errors[parent][name])}
      helperText={errors[parent] && touched[parent] && touched[parent][name] && errors[parent][name]}
    />
)}

const Shipping = React.forwardRef(({
	mailing,
	formikProps,
}, ref) => {
	return(
	<div  ref={ref} style={{width: '100%'}}>
      <Typography variant="h6" gutterBottom>
        Purchasing Contact
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ComposedTextField
            required
            parent="primaryContact"
            formikProps={formikProps}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ComposedTextField
            required
            parent="primaryContact"
            formikProps={formikProps}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ComposedTextField
            parent="primaryContact"
            formikProps={formikProps}
            id="email"
            name="email"
            label="Email Address"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="contact"
                value="yes"
                checked={formikProps.values.primaryContact.contact}
                onChange={() => formikProps.setFieldValue('primaryContact.contact',
                  !formikProps.values.primaryContact.contact
                )}
              />}
            label="Email purchase order on approval"
          />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Quality Contact
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ComposedTextField
            required
            parent="qualityContact"
            formikProps={formikProps}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ComposedTextField
            required
            parent="qualityContact"
            formikProps={formikProps}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ComposedTextField
            parent="qualityContact"
            formikProps={formikProps}
            id="email"
            name="email"
            label="Email Address"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="contactIssue"
                value="yes"
                checked={formikProps.values.qualityContact.contact}
                onChange={() => formikProps.setFieldValue('qualityContact.contact',
                  !formikProps.values.qualityContact.contact
                )}
              />}
            label="Email NCR on quality issue"
          />
        </Grid>
      </Grid>
    </div>
  );
});

export default Shipping;
