import { FETCH_REQUIREMENTS, FETCH_ALL_REQUIREMENTS } from 'actions/requirements';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_REQUIREMENTS:
            return { ...state, ...action.payload };
        case FETCH_ALL_REQUIREMENTS:
            return action.payload;
        default:
            return state;
    }
}