import { sortBy, uniq, map, includes, isEqual, omit, size } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Grid,
  IconButton,
  Paper,
  Button,
  MenuItem,
  Tooltip,
  Autocomplete,
  Box,
  Typography,
  Menu,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Download from "@mui/icons-material/GetApp";
import { Form, Formik } from "formik";
import Draggable from "react-draggable";
import { useSnackbar } from "notistack";
import { object, string } from "yup";

import { useConfirm } from "components/confirm";

import { fetchSuppliers } from "actions/suppliers";
import {
  createPO,
  getPONumber,
  updatePO,
  deletePO,
  closePO,
  openPO as openPOAction,
  validatePONumber,
  updatePOStatus,
  detatchListen
} from "actions/pos";
import { fetchItems } from "actions/items";
import { scrollPOs } from "actions/pos";

import ItemTable from "components/pos/items";
import PODocs from "components/pos/poDocs";
import Receive from "components/pos/receivePO";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"], .noDrag'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const GreyedOut = ({reopenPO}) => {
  return <Box sx={{
  position: 'absolute',
  height: 220,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1301,}}>
      {/* Watermark */}
      <Box sx={t => ({
        borderRadius: t.shape.borderRadius,
        border: `3px solid ${t.palette.secondary.main}`,
        padding: t.spacing(2),
        margin: t.spacing(3),
        transform: 'rotate(-10deg)'
      })}>
        <Typography sx={{fontWeight: 'bold'}} variant="h3" color="secondary">CLOSED</Typography>
      </Box>
      {/* Button to close the overlay */}
      <Button onClick={reopenPO} variant="contained" color="secondary">Reopen PO</Button>
    </Box>
}

const NewPO = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  // const ref = React.useRef()
  const [receive, setReceive] = useState(false);
  const [openMoreAnchor, setOpenMoreAnchor] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [canShow, setCanShow] = useState(0);
  const [childCount, setChildCount] = useState(0);
  const [display, setDisplay] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { suppliers, openPO } = useSelector((state) => state);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const sortedSuppliers = sortBy(
    uniq(map(suppliers, (s, id) => ({ ...s, id }))),
    "name"
  );
  const po = openPO;
  const { poId } = po;

  const handleOnClose = () => {
    if (poId) dispatch(closePO(poId));
    onClose();
  };
  const [vendorInput, setVendorInput] = useState(po ? po.vendor : "");
  const { firstName, lastName, uid, role } = useSelector(
    (state) => state.authUser
  );
  const supplierList = map(sortedSuppliers, (supplier) => supplier.id);
  const toggleReceive = () => setReceive((prev) => !prev);

  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = ("0" + (now.getMonth() + 1)).slice(-2);
    const day = ("0" + now.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const initialValues = {
    po: '',
    date: today(),
    vendor: "",
    vendorId: "",
    vendorPhysical: {},
    purchasingAgent: `${firstName} ${lastName}`,
    uid,
    shippingMethod: "",
    terms: "",
    due: "",
    taxType: "dollar",
    taxRate: 0,
    tax: 0,
    shipping: 0,
    workOrder: "",
    location: "",
    status: "OPEN",
    items: {},
    subtotal: 0,
    total: 0,
    notes: "",
    poDocuments: {},
  }

  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchSuppliers());
      dispatch(fetchItems());
      dispatch(getPONumber());
    };
    fetchData();
  }, []);

  const toggleOpenMore = e => {
    setOpenMoreAnchor(e ? e.currentTarget : null);
  }

  const handleScroll = (by, direction) => {
    dispatch(scrollPOs(by, direction))
  }

  const handleCloseAfterConfirm = () => {
    setVendorInput("");
    setDisplay(false);
    setCanShow(0);
    setChildCount(0);
    handleOnClose();
  };
  const incCanShow = React.useCallback(() => {
    if (childCount === 0) return;
    if (canShow > childCount) return;
    return setCanShow((prev) => prev + 1);
  }, [canShow, childCount]);
  useEffect(() => {
    setVendorInput(po && po.vendor ? po.vendor : "");
    setIsOverlayVisible(po && po.status === 'CLOSED');
    setChildCount(size(po.items));
    }, [po]);
  useEffect(() => {
    if (poId && !po.poItems) setDisplay(true);
    setEditOpen(Boolean(poId));
    if (canShow > 0 && canShow === childCount + 1) {
      setIsOverlayVisible(po ? po.status === 'CLOSED' : false)
      return setDisplay(true);
    } else {
      setIsOverlayVisible(po ? po.status === 'CLOSED' : false)
    }
    return;
  }, [poId]);
  const reopenPO = React.useCallback(() => {
    const cb = () => setIsOverlayVisible(!isOverlayVisible);
    dispatch(updatePOStatus(poId, 'OPEN', cb));
}, [poId]);
  if (!open && !poId) {
    return null;
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          poId
            ? po
            : initialValues
        }
        validationSchema={object().shape({
          po: string()
            // .required()
            .test('dup', 'Duplicate PO', async poVal => {
              const poNumberParsed = parseInt(poVal)
              if(!poNumberParsed || (poId && poNumberParsed === po.po)) return true
              const res = await dispatch(validatePONumber(poNumberParsed));
              return !res
            }),
          vendor: string().required(),
          location: string().required(),
        })}
        onSubmit={(values) => {
          if (isClosing && poId) dispatch(detatchListen(poId));
          const cb = (id) => {
            if (id) {
             isClosing
             ? setIsClosing(false)
             : dispatch(openPOAction(id));
            }
            enqueueSnackbar(po ? "PO Updated" : "PO Created", {
              variant: "success",
            });
          };
          return dispatch(poId ? updatePO(values, cb) : createPO(values, cb));
        }}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            setFieldValue,
            setValues,
            handleSubmit,
            touched,
            handleBlur,
            errors,
            submitForm
          } = formikProps;

          const isUnsaved = React.useMemo(() => {
          if (!poId ) {
            return !isEqual(values, initialValues);
          }
          const eq = !isEqual(
            omit(
              { ...po, poDocuments: (po && po.poDocuments) || {} },
              "pdfURL"
            ),
            omit(values, "pdfURL")
          );
          return eq;
          }, [values])
          const handleClose = () => {
            // if (!poId) return handleCloseAfterConfirm();
            return isUnsaved
              ? confirm({
                  title: "Unsaved Changes!",
                  description:
                    "You save unsaved changes, you still want to exit?",
                  confirmationText: "Don't Save",
                  cancellationText: "Cancel",
                })
                  .then(handleCloseAfterConfirm)
                  .catch((f) => f)
              : handleCloseAfterConfirm();
          };


          return (
            <>
              <Dialog
                open={open || editOpen}
                sx={{
                  "& div": {
                    opacity: open || display ? "1" : "0",
                    transition: "opacity .2s",
                  },
                }}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                disableEnforceFocus={receive}
              >
                <DialogTitle
                  style={{ cursor: "move" }}
                  id="draggable-dialog-title"
                >
                  Purchase Order
                  <IconButton
                    onClick={toggleOpenMore}
                    sx={{ml: t => t.spacing(1)}}
                    disabled={!Boolean(po && po.poId)}
                  ><ExpandMoreIcon /> </IconButton>
                  <Menu
                    anchorEl={openMoreAnchor}
                    open={Boolean(openMoreAnchor)}
                    onClose={() => toggleOpenMore()}
                  >
                    <MenuItem>
                      <IconButton onClick={() => handleScroll('po', 'up')}>
                        <ChevronLeftIcon />
                      </IconButton>
                      Scroll by PO
                      <IconButton onClick={() => handleScroll('po', 'down')}>
                        <ChevronRightIcon />
                      </IconButton>
                    </MenuItem>
                    <MenuItem>
                      <IconButton onClick={() => handleScroll('agent', 'up')}>
                        <ChevronLeftIcon />
                      </IconButton>
                      Scroll by PO Agent
                      <IconButton onClick={() => handleScroll('agent', 'down')}>
                        <ChevronRightIcon />
                      </IconButton>
                    </MenuItem>
                    <MenuItem>
                      <IconButton onClick={() => handleScroll('vendor', 'up')}>
                        <ChevronLeftIcon />
                      </IconButton>
                      Scroll by Vendor
                      <IconButton onClick={() => handleScroll('vendor', 'down')}>
                        <ChevronRightIcon />
                      </IconButton>
                    </MenuItem>
                  </Menu>
                  <PODocs
                    po={values.po}
                    poId={poId}
                    poDocuments={values.poDocuments}
                  />
      <Receive
        items={po && po.items}
        poId={po && po.poId}
        open={receive}
        toggle={toggleReceive}
        values={values}
      />
                  {uid === values.uid || includes(role, "ADMIN") ? (
                    <Tooltip title="Receive PO">
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: (theme) => theme.spacing(1),
                          right: (theme) => theme.spacing(13),
                        }}
                        className="noDrag"
                        color={
                          po && po.status === "CLOSED" ? "primary" : "secondary"
                        }
                        // disabled={isOverlayVisible}
                        onClick={toggleReceive}
                      >
                        <LibraryAddCheckIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {poId && (
                    <IconButton
                      disabled={isUnsaved || !po.pdfURL}
                      className="noDrag"
                      component="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={po.pdfURL}
                      sx={{
                        position: "absolute",
                        top: (theme) => theme.spacing(1),
                        right: (theme) => theme.spacing(7),
                      }}
                    >
                      <Download />
                    </IconButton>
                  )}
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: (theme) => theme.spacing(1),
                      right: (theme) => theme.spacing(1),
                    }}
                    className="noDrag"
                    onClick={handleClose}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          value={values.po}
                          name="po"
                          label="PO Number"
                          variant="outlined"
                          placeholder={poId ? "" : "SAVE PO TO GENERATE"}
                          onBlur={handleBlur}
                          error={Boolean(touched.po && errors.po)}
                          helperText={touched.po && errors.po}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={e => {
                          handleChange(e);
                            // if(!value) return;
                            // if(poId && value === po.po) return;
                            // dispatch(validatePONumber(value))
                            // .then(res => {
                            // console.log(res, setFieldError('po', res ? 'Duplicate PO Number' : ''))
                            //   return setFieldError('po', res ? 'Duplicate PO Number' : '');

                            // })
                          }}
                          fullWidth
                          sx={{ marginTop: (theme) => theme.spacing(1) }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          value={values.date}
                          name="date"
                          label="Date"
                          type="date"
                          variant="outlined"
                          onChange={handleChange}
                          fullWidth
                          sx={{ marginTop: (theme) => theme.spacing(1) }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          value={values.location}
                          name="location"
                          label="Location"
                          variant="outlined"
                          onChange={handleChange}
                          error={Boolean(touched.location && errors.location)}
                          helperText={touched.location && errors.location}
                          onBlur={handleBlur}
                          fullWidth
                          select
                          sx={{ marginTop: (theme) => theme.spacing(1) }}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="houma">Houma</MenuItem>
                          <MenuItem value="broussard">Broussard</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          value={values.vendor}
                          inputValue={vendorInput}
                          freeSolo
                          isOptionEqualToValue={(o, v) => o}
                          options={supplierList}
                          getOptionDisabled={o => suppliers?.[o]?.suspended}
                          getOptionLabel={(option) => {
                            if (!suppliers[option]) return "";
                            const { name, physical, suspended } = suppliers[option];
                            return `${name} (${physical && physical.city}, ${
                              physical && physical.state
                            })${suspended ? ' **SUSPENDED**' : ''}`;
                          }}
                          onInputChange={(event, newInputValue) => {
                            return (
                              newInputValue && setVendorInput(newInputValue)
                            );
                          }}
                          onChange={(event, eventValue) => {
                            const vendorName = suppliers[eventValue]
                              ? suppliers[eventValue].name
                              : "";
                            const vendorPhysical = suppliers[eventValue]
                              ? suppliers[eventValue].physical
                              : {};
                            setVendorInput(vendorName);
                            setFieldValue("vendorId", eventValue);
                            setFieldValue("vendor", vendorName);
                            setFieldValue("vendorPhysical", vendorPhysical);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vendor"
                              name="vendor"
                              variant="outlined"
                              error={Boolean(touched.vendor && errors.vendor)}
                              helperText={touched.vendor && errors.vendor}
                              onBlur={handleBlur}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.purchasingAgent}
                          name="purchasingAgent"
                          label="Purchasing Agent"
                          variant="outlined"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.workOrder}
                          name="workOrder"
                          label="Work Order"
                          variant="outlined"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          value={values.shippingMethod}
                          name="shippingMethod"
                          label="Shipping Method"
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          value={values.terms}
                          name="terms"
                          label="Payment Terms"
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          value={values.due}
                          name="due"
                          label="Due Date"
                          variant="outlined"
                          type="date"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Form>
                  <ItemTable
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    values={values}
                    poId={poId}
                    incCanShow={incCanShow}
                  />
  {isOverlayVisible && <GreyedOut reopenPO={reopenPO}/>}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {poId && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        confirm({
                          title: "Delete PO ?!",
                          description:
                            "Are you sure you want to delete this PO?",
                          confirmationText: "Yes, Delete",
                          cancellationText: "No, Don't Delete",
                        }).then(() => {
                          handleCloseAfterConfirm();
                          dispatch(deletePO(poId));
                        });
                      }}
                      color="secondary"
                    >
                      Delete PO
                    </Button>
                  )}
                  <Button onClick={handleSubmit} variant="outlined">
                    {po ? "Update" : "Create"}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsClosing(true)
                      submitForm().then(() => {
                        formikProps.resetForm();
                        handleCloseAfterConfirm();
                      });
                    }}
                    variant="contained"
                  >
                    {po ? "Update & Close" : "Create & Close"}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default React.memo(NewPO);
