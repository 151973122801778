import { size, map } from "lodash";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Badge,
  Tooltip,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/DeleteForever";

import { useConfirm } from "components/confirm";
import { attachFileToPO, removeFileFromPO } from "actions/pos";

// import { database as db } from "firebase_config";

const DropZoneContainer = styled(Box)(({ isDragActive, isDragReject }) => ({
  padding: "32px",
  border: `1px dashed ${
    isDragActive ? (isDragReject ? "red" : "green") : "#ccc"
  }`,
  borderRadius: "8px",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: isDragActive
    ? isDragReject
      ? "rgba(255,0,0,0.1)"
      : "rgba(0,255,0,0.1)"
    : "transparent",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 3,
    top: 5,
    padding: "0 4px",
  },
}));

const Drop = (props) => {
  const { onDrop } = props;
  const [over, setOver] = React.useState(false);
  const [invalid, setInvalid] = React.useState(true);
  const handleDragEnter = (event) => {
    event.preventDefault();
    const { items } = event.dataTransfer;
    if (!items[0].type) return;
    setInvalid(items[0].type !== "application/pdf");
    setOver(true);
  };
  const handleDragLeave = () => {
    setInvalid(true);
    setOver(false);
  };
  const handleDrop = (files) => {
    onDrop(files[0]);
    setInvalid(false);
    setOver(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "application/pdf",
    onDragOver: handleDragEnter,
    onDragLeave: handleDragLeave,
  });

  return (
    <DropZoneContainer
      isDragActive={over}
      isDragReject={invalid}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p>
        {over
          ? invalid
            ? "Only pdf files are allowed"
            : "Drop file here"
          : "Drag files here, or click to select"}
      </p>
    </DropZoneContainer>
  );
};

const Docs = ({
  open,
  onClose,
  po,
  poId,
  poDocuments
}) => {
  const handleClose = onClose;
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const onDrop = (file) => {
    dispatch(attachFileToPO(file, poId));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{`Attach Documents to PO ${po}`}</DialogTitle>
      <DialogContent>
        <Drop onDrop={onDrop} />
        <List>
          {map(poDocuments, (doc, id) => {
            return (
              <ListItem
                key={id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() =>
                      confirm({
                        description: `Delete file: ${doc.name}?`,
                      }).then(() => dispatch(removeFileFromPO(doc, poId)))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemButton
                  component="a"
                  href={doc.downloadURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText primary={doc.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClose} variant="contained" color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DocButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  return (
    <>
      <StyledBadge
        badgeContent={size(props.poDocuments)}
        color="primary"
        invisible={!props.poDocuments}
        className="noDrag"
        sx={{
          position: "absolute",
          top: (theme) => theme.spacing(1),
          right: (theme) => theme.spacing(19),
        }}
      >
        <IconButton onClick={props.poId && toggleOpen}
          sx={!props.poId ? {
            color: theme => theme.palette.text.disabled
          }: {}}
        >
          <Tooltip
            title={
              props.poId
              ? "Attach a document to PO"
              : "Save PO to attach documents"
            }
          >
            <AttachFileIcon />
          </Tooltip>
        </IconButton>
      </StyledBadge>
      <Docs open={open} onClose={toggleOpen} {...props} />
    </>
  );
};

export default React.memo(DocButton);
