import { map, size, omit, pickBy, values as _values, forEach, isEmpty } from "lodash";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  IconButton,
  Badge,
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  Alert
} from "@mui/material";
// import Alert from '@material-ui/lab/Alert';
import { WarningAmber, Done } from "@mui/icons-material";
import NumberFormat from "react-number-format";
import { Formik, Form } from "formik";
import { object, string, number, mixed } from "yup";

import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/DeleteForever";

import Comments from "components/pos/itemComments";
import CustomStyledInput from "components/CustomStyledInput";

// import { database } from 'firebase_config';

import { fetchItemByName } from "actions/items";
import { fetchCategoryRequirements } from "actions/requirements";

const styles = {
  submit: {
    display: "none",
  },
  description: {
    width: "100%",
    textAlign: "left",
    "& textarea": {
      fontSize: ".8em !important",
    },
    "& input": {
      fontSize: ".8em !important",
    },
  },
  center: {
    textAlign: "center",
    justifyContent: "center",
    "& input": {
      textAlign: "center",
    },
  },
  textField: {
    "& input": {
      fontSize: ".8em !important",
    },
  },
  right: {
    "& input": {
      textAlign: "right",
      fontSize: ".8em !important",
    },
  },
  grid: {
    textAlign: "center",
  },
  total: {
    "& span": {
      textAlign: "right",
    },
  },
  topGrid: {
    mt: 1,
  },
  deleteButton: {
    minWidth: "30px",
  },
};

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  //const ref = useRef();
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue * 100,
          },
        });
      }}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      prefix="$"
      displayType={onChange ? "input" : "text"}
    />
  );
});

const Item = ({ focused, item, updateItems, lastRow, onRender }) => {
  const [popover, setPopover] = useState("");
  const [prompt, setPrompt] = useState(false);
  const items = useSelector((state) => state.items);
  const validationSchema = React.useMemo(() => object().shape({
    qty: number(),
    cost: number(),
    description: mixed().when("comment", {
      is: false,
      then: string().required(),
    }),
  }), [item]);
  const togglePopover = (e) =>
    setPopover((prev) => (prev ? "" : e.currentTarget));
  const togglePrompt = () => setPrompt((prev) => !prev);
  React.useEffect(() => {
    onRender();
  }, [onRender]);
  return (
    <Formik
      enableReinitialize
      initialValues={item}
      onSubmit={(values) => {
        updateItems(values);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        values: {
          lineItem,
          itemId,
          code,
          description,
          qty,
          um,
          cost,
          total,
          comments,
          inputs,
          comment,
        },
        handleChange,
        setFieldValue,
        setValues,
        resetForm,
        setErrors
      }) => {
        const updateAfterValidate = (vals) =>
          validationSchema.validate(vals, {abortEarly: false})
          .then((v) => {
            updateItems(v)
            })
          .catch((e) => {
            let err = {};
            forEach(e.inner, eInner => err[eInner.path] = eInner.message)
           return setErrors({...errors, ...err });
          });
        const onDescriptionSelect = (event, value, reason) => {
        if(!value) return;
        const field = "description"
          fetchItemByName(field, value[field]).then(
              async (item) => {
                const itemObj = _values(item)[0];
                if (!itemObj)
                  return setValues({
                    ...values,
                    [field]: value,
                    // description: '',
                    // comments: {}
                  });
                const additionalItemRequirements = itemObj.requirements || {};
                const req = await fetchCategoryRequirements(
                  itemObj.category,
                  additionalItemRequirements
                );
                const comments = pickBy(req, (r) => r.includeOnPO);
                const inputsConstructed = () => {
                  const newInputs = itemObj.inputs;
                  for (const key in newInputs) {
                    newInputs[key].value = newInputs[key].defaultOption || "";
                  }
                  return newInputs;
                };
                const inputs = inputsConstructed();
                setValues({
                  ...values,
                  code: itemObj.name,
                  description: inputs
                    ? `${itemObj.description} [${map(
                        inputs,
                        (i) => i.value
                      )}]`.replace(/,\s*$/, "")
                    : itemObj.description,
                  comment: Boolean(itemObj && itemObj.comment),
                  comments,
                  inputs,
                  cost: itemObj.cost || 0
                });
              }
            )
        }
        const onCodeChange = (field, value) => {
          if (values[field] !== value) {
          setFieldValue(field, value);
          if (field === 'description' && !value) {
            const wasTyped = values.description.length === 1
            setValues({
              ...values,
              code: '',
              description: '',
              comment: wasTyped ? values.comment || false : false,
              comments: wasTyped ? size(values.comments) > 0 ? values.comments : {} : {},
              inputs: {}
              })
          }
          if (!value) return;
          if (value.length > 2) {
            fetchItemByName(field === "code" ? "name" : field, value).then(
              async (item) => {
                const itemObj = _values(item)[0];
                if (!itemObj)
                  return setValues({
                    ...values,
                    [field]: value,
                    // description: '',
                    // comments: {}
                  });
                const additionalItemRequirements = itemObj.requirements || {};
                const req = await fetchCategoryRequirements(
                  itemObj.category,
                  additionalItemRequirements
                );
                const comments = pickBy(req, (r) => r.includeOnPO);
                const inputsConstructed = () => {
                  const newInputs = itemObj.inputs;
                  for (const key in newInputs) {
                    newInputs[key].value = newInputs[key].defaultOption || "";
                  }
                  return newInputs;
                };
                const inputs = inputsConstructed();
                setValues({
                  ...values,
                  code: itemObj.name,
                  description: inputs
                    ? `${itemObj.description} [${map(
                        inputs,
                        (i) => i.value
                      )}]`.replace(/,\s*$/, "")
                    : itemObj.description,
                  comment: Boolean(itemObj && itemObj.comment),
                  comments,
                  inputs,
                  cost: itemObj.cost || 0
                });
              }
            );
          }
        }};
        // const [field, meta, helpers] = useField('qty');
        // console.log({field, meta, helpers})
        return (
          <Fragment>
            <Dialog open={prompt} onClose={togglePrompt}>
              <DialogContent>
                {map(inputs, (input, inputId) => {
                  return (
                    <Autocomplete
                      key={inputId}
                      options={input.options}
                      getOptionLabel={(o) => o}
                      renderInput={(params) => (
                        <TextField {...params} label={input.question} />
                      )}
                      sx={{
                        minWidth: 300,
                        marginBottom: (theme) => theme.spacing(1),
                      }}
                      freeSolo={!input.strictOptions}
                      defaultValue={input.defaultOption}
                      onChange={(e, v) => {
                        const newInputs = {
                          ...inputs,
                          [inputId]: { ...inputs[inputId], value: v },
                        };
                        const newDescription = `${description.replace(
                          /\[.*?\]/g,
                          ""
                        )} [${map(inputs, (i, iId) =>
                          iId === inputId ? v : i.value
                        )}]`.replace(/,\s*$/, "");
                        setValues({
                          ...values,
                          inputs: newInputs,
                          description: newDescription,
                        });
                      }}
                      value={input.value}
                    />
                  );
                })}
              </DialogContent>
              <DialogActions>
                <Button onClick={togglePrompt}>Close</Button>
              </DialogActions>
            </Dialog>
            <Form>
              <Grid
                container
                spacing={0}
                alignItems="flex-end"
                sx={{ ...styles.rootGrid, ...styles.grid, ...styles.topGrid }}
              >
                <Grid item xs={4} sm={2}>
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    sx={styles.grid}
                  >
                    <Grid item xs={2}>
                      <span>{lineItem}</span>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        options={_values(items)}
                        freeSolo
                        getOptionLabel={(o) => o.name}
                        name="code"
                        size="small"
                        renderOption={(props, option) => {
                          return (
                            <Box {...props} sx={{ fontSize: 9 }}>
                              {option.name}
                            </Box>
                          );
                        }}
                        onInputChange={(e, newValue) => {
                          onCodeChange("code", newValue);
                        }}
                        inputValue={code}
                        isOptionEqualToValue={(o, v) => {
                          return o.name === v;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            fullWidth
                            label={lineItem === 1 ? "Code" : null}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => updateAfterValidate(values)}
                            sx={styles.textField}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Autocomplete
                    options={_values(items)}
                    freeSolo
                    getOptionLabel={(o) => o.description || o}
                    name="description"
                    size="small"
                    onChange={onDescriptionSelect}
                    renderOption={(props, option) => {
                      return (
                        <Box {...props} sx={{ fontSize: 9 }}>
                          {option.description}
                        </Box>
                      );
                    }}
                    onInputChange={(e, newValue) => {
                      setFieldValue("description", newValue);
                    }}
                    inputValue={description}
                    isOptionEqualToValue={(o, v) => {
                      return o.description === v;
                    }}
                    renderInput={(params) => (
                      <Grid container>
                        <Grid item xs={11}>
                          <TextField
                            {...params}
                            variant="standard"
                            multiline
                            fullWidth
                            minRows={1}
                            maxRows={4}
                            autoComplete="off"
                            label={lineItem === 1 ? "Description" : null}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              updateAfterValidate(values);
                            }}
                            sx={{ ...styles.description, ...styles.textField }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            borderBottom: (t) =>
                              `1px solid ${t.palette.text.secondary}`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {size(inputs) > 0 ? (
                            <Box onClick={togglePrompt} sx={{ lineHeight: 1 }}>
                              {map(inputs, (i) => i.value).some(
                                (e) => Boolean(e) === false
                              ) ? (
                                <WarningAmber sx={{ color: "darkorange" }} />
                              ) : (
                                <Done sx={{ color: "green" }} />
                              )}
                            </Box>
                          ) : (
                            <Box sx={{ lineHeight: 1 }}>
                              <Done sx={{ opacity: 0 }} />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container spacing={0}>
                    <Grid item xs={8}>
                      <TextField
                        value={qty}
                        name={"qty"}
                        variant="standard"
                        disabled={comment}
                        type="number"
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("qty", value, true);
                          const newTotal = value * values.cost;
                          setFieldValue("total", newTotal);
                          // updateAfterValidate(values)
                        }}
                        label={lineItem === 1 ? "Qty" : null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onBlur={() => updateAfterValidate(values)}
                        sx={{ ...styles.textField, ...styles.center }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        value={um}
                        name={"um"}
                        onChange={handleChange}
                        disabled={comment}
                        variant="standard"
                        label={lineItem === 1 ? "UM" : null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ ...styles.textField, ...styles.center }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={1}>
                  <TextField
                    // label="react-number-format"

                    variant="standard"
                    value={cost / 100}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("cost", value);
                      const newTotal = values.qty * value;
                      setFieldValue("total", newTotal);
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    //onChange={e => handleChange(e,poItemIndex)}
                    name="cost"
                    disabled={comment}
                    label={lineItem === 1 ? "Cost" : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={() => updateAfterValidate(values)}
                    sx={{
                      ...styles.total,
                      ...styles.textField,
                      ...styles.right,
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={1}>
                  <CustomStyledInput
                    value={total / 100}
                    fullWidth
                    disabled={comment}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      // startAdornment:  <InputAdornment position="end">$</InputAdornment>
                    }}
                    label={lineItem === 1 ? "Total" : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      ...styles.total,
                      ...styles.textField,
                      ...styles.right,
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={1}>
                  <IconButton size="small" onClick={togglePopover}>
                    <Badge badgeContent={size(comments)} color="secondary">
                      <CommentIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => updateItems(false, itemId)}
                    sx={styles.deleteButton}
                  >
                    {!lastRow && <DeleteIcon />}
                  </IconButton>
                </Grid>
                {!isEmpty(errors) &&
                <Grid item xs={12}>
                  <Alert severity="error" color="error">{JSON.stringify(errors)}</Alert>
                </Grid>
                }
              </Grid>
              <Box component="button" sx={styles.submit} type="submit"></Box>
            </Form>
            <Comments
              popover={popover}
              togglePopover={togglePopover}
              comments={comments}
              updateComments={(comment, commentId) => {
                updateItems({
                  ...values,
                  comments: comment
                    ? {
                        ...comments,
                        [commentId]: comment,
                      }
                    : omit(comments, commentId),
                });
              }}
            />
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default Item;
