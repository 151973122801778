import { INVOICES, INVOICE } from 'actions/invoices';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case INVOICES:
            return action.payload;
        case INVOICE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}