import { PO_NUMBER } from 'actions/pos';

const INITIAL_STATE = 0

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case PO_NUMBER:
            return action.payload;
        default:
            return state;
    }
}