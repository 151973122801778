import { combineReducers } from 'redux';
import authUser from 'reducers/authUser';
import itemCategories from 'reducers/itemCategories';
import questions from 'reducers/questions';
import requirements from 'reducers/requirements';
import questionCategories from 'reducers/questionCategories';
import itemCategoriesQuestions from 'reducers/itemCategoriesQuestions';
import suppliers from 'reducers/suppliers';
import specs from 'reducers/specs';
import items from 'reducers/items';
import evaluation from 'reducers/evaluation';
import pos from 'reducers/pos';
import invoices from 'reducers/invoices';
import openInvoice from 'reducers/openInvoice';
import poNumber from 'reducers/poNumber';
import openPO from 'reducers/openPO';
import supplierIssues from 'reducers/supplierIssues';
import snackbar from 'reducers/snackbar';

export default combineReducers({
  authUser,
  itemCategories,
  questions,
  questionCategories,
  requirements,
  itemCategoriesQuestions,
  suppliers,
  specs,
  items,
  evaluation,
  pos,
  invoices,
  openInvoice,
  poNumber,
  openPO,
  supplierIssues,
  snackbar
})