import { size, map, isEqual } from 'lodash';
import React, { useState, useEffect, Fragment } from 'react';

import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	TextField,
	MenuItem,
	Typography,
	Paper,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Fab,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Box,
	FormControlLabel,
	Switch,
	Tooltip
} from '@mui/material';
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	Add,
	Create,
	DeleteForever,
	ExpandMore
} from '@mui/icons-material';

import { database } from 'firebase_config';
import { useConfirm } from 'components/confirm';
import NewRequirement from 'components/requirements/new';
import NewInput from 'components/items/newInput';

import { fetchItemCategories, createItem, deleteItem, deleteItemInput } from 'actions/items';
import { deleteItemRequirement } from 'actions/requirements';



const styles = {
	fab: {
		position: 'absolute',
		bottom: theme => theme.spacing(1),
		right: theme => theme.spacing(1),
	},
	images: {
		[theme => theme.breakpoints.up('xs')]: {
			width: '100%',
		},
		[theme => theme.breakpoints.up('sm')]: {
			width: 450,
			margin: '0 auto'
		},
		[theme => theme.breakpoints.up('md')]: {
			width: '100%',
		}
	},
	header: {
		margin: theme => theme.spacing(1),
	},
	paper: {
		maxHeight: 380,
		position: 'relative',
		paddingBottom: theme => theme.spacing(7)
	},
	preview: {
		width: '100%'
	},
	previewList: {
		width: '100%',
		position: 'relative'
	},
	previewListImage: {

	},
	right: {
		position: 'absolute',
		right: 0,
		top: 0,
		minWidth: 29,
		height: 50,
		padding: 0
	},
	left: {
		position: 'absolute',
		left: 0,
		minWidth: 29,
		height: 50,
		padding: 0
	},
	description: {
		'& div': {
			maxHeight: 96,
			'& textarea' : {
				overflowY: 'auto !important',
				maxHeight: 76,
				'& -webkit-scrollbar':  { width: '8px' }
			}
		}
	},
	list: {
		maxHeight: 380 - 45,
		overflow: 'hidden',
		overflowY: 'auto',
	},
	listItem: {
		'& div' : {
			'& span': {
				fontSize: '.85em !important',
			}
		}
	},
	label: {
		'& span': {
			fontSize: '.85em'
		}
	},
}

const images = [
		{src: "https://via.placeholder.com/150"},
		{src: "https://via.placeholder.com/150"},
		{src: "https://via.placeholder.com/150"},
		{src: "https://via.placeholder.com/150"},
		{src: "https://via.placeholder.com/150"},
		{src: "https://via.placeholder.com/150"}
	]

const NewItem = ({
	open,
	onClose,
	editId: _editId,
	setEditId,
	item: _item,
}) => {
	const dispatch = useDispatch();
	const confirm = useConfirm();
	const { enqueueSnackbar } = useSnackbar();
	const itemCategories = useSelector(s => s.itemCategories);
	const requirements = useSelector(s => s.requirements);
	const items = useSelector(s => s.items);
	const [editId, setLocalEditId] = useState(_editId);
	const [first, setFirst] = useState(0);
	const [key, setKey] = useState('');
	const [selectedImg, setSelectedImg] = useState(0)
	const [openReq, setOpenReq] = useState(false);
	const [openInput, setOpenInput] = useState(false);
	const [inputEditId, setInputEditId] = useState('');
	const [inputEdit, setInputEdit] = useState({});
	const [reqEditId, setReqEditId] = useState('');
	const [reqEdit, setReqEdit] = useState({});
	const goLeft = () => setFirst(first - 1);
	const goRight = () => setFirst(first + 1);
	const item = editId ? items[editId || key] : _item ? _item : null;
	const handleCloseAfterConfirm = (values) => {
		if (editId && !values.name) dispatch(deleteItem(key, () => setKey('')));
		if (setEditId) setEditId('');
		setKey('');
		onClose();
		setLocalEditId('')
	}
	useEffect(() => {
		setLocalEditId(_editId);
		dispatch(fetchItemCategories());
		}, [_editId])
	// useEffect(() => fetchItemRequirements(editId || key), [editId, key])
	// useEffect(() => {
	// 	fetchCategoryRequirements(catId);
	// 	}, [catId]);
	useEffect(() => setKey(database.ref('purchasing/items/items').push().key), [open]);

	const validateName = async value => {
		if(!value || value.length < 3) return;
		const itemNames = database.ref('purchasing/items/items')
		const dbName = itemNames.orderByChild('name').equalTo(value)
		const exists = await dbName.once('value').then(s => s.exists());
		return exists
	}
	return (
		<Formik
			enableReinitialize
			validateOnChange
			initialValues={item || {
				name: '',
				model: '',
				description: '',
				category: '',
				comment: false
			}}
			validationSchema={
				object().shape({
					name: string().required('Please enter an item name')
					.test(
						'exists',
						({value}) => `${value} already exists`,
						async v => {
						if (Boolean(editId && item)) return true;
						const res = await validateName(v);
						return !res
						}
					),
					description: string().required('Please enter an item description'),
					category: string().required('Please select an item category'),
				})
			}
			onSubmit={(values,actions) => {
				return dispatch(createItem(values, editId || key, () => {
					enqueueSnackbar(`Item ${editId ? 'updated' : 'created!'}`, { variant: 'success'});
					setLocalEditId(editId ? editId : key)
					actions.resetForm();
				}))}
			}
			>{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				setFieldValue,
				setFieldError,
				validateForm,
				setFieldTouched,
				setTouched,
				handleSubmit,
				submitForm
			}) => {
          const handleClose = () => {
            if (!editId) return handleCloseAfterConfirm(values);
			      const unsaved = !isEqual(items[editId], values);
            return unsaved
              ? confirm({
                  title: "Unsaved Changes!",
                  description:
                    "You save unsaved changes, you still want to exit?",
                  confirmationText: "Don't Save",
                  cancellationText: "Cancel",
                })
                  .then(() => handleCloseAfterConfirm(values))
                  .catch((f) => f)
              : handleCloseAfterConfirm(values);
          };
					if (errors.name && errors.name.indexOf('already exists')>-1 && !touched.name) {
						console.log('error')
						setFieldTouched('name', true)
					}
					const handleSave = () => submitForm();
					const handleSaveandClose = () => {
									submitForm()
									.then(() => {
										setTouched({
											name: true,
											description: true,
											category: true
										});
										handleCloseAfterConfirm(values);
									})
								}

			return (
				<Dialog
					open={open}
					onClose={handleClose}
					maxWidth="md"
					fullWidth
				>
					<DialogTitle onClick={() => console.log(item, editId)}>
						Add a new item
					</DialogTitle>
					<Form>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12} md={4}>
									<Box sx={styles.images}>
										<Box
											component="img"
											src={images[selectedImg].src}
											sx={styles.preview}
											alt=""
										/>
											<Box sx={styles.previewList}>
												<Button
													sx={styles.left}
													onClick={goLeft}
													disabled={first ===0}
												><KeyboardArrowLeft /></Button>
												<div style={{
													marginLeft: 29,
													marginRight: 29,
													overflowX: 'hidden',
											    display: 'inline-flex',
											    width: 'calc(100% - 60px)'
												}}>
													{images.map(({src}, index) => (
														<Box
															component="img"
															alt=""
															src={images[index].src}
															key={src+index}
															sx={selectedImg===index
																? { margin: '2px',
																		border: theme => (
																		`1px solid ${theme.palette.primary.main}`
																		),
																		height: '50px',
																		display: first > index && 'none'
																}
																: { margin: '2px',
																	height: '50px',
																	display: first > index && 'none' }
															}
															onClick={() => {
																setSelectedImg(index)
															}}
														/>
													))}
													<Box
														sx={{
															margin: '1px',
															height: '50px',
															width: '50px',
															alignItems: 'center',
															justifyContent: 'center',
															display: 'flex'
														}}
													>
														<IconButton color="primary">
															<Add />
														</IconButton>
													</Box>
												</div>
												<Button
													sx={styles.right}
													onClick={goRight}
													disabled={images.length - first === 3}
												><KeyboardArrowRight /></Button>
											</Box>
									</Box>
								</Grid>
								<Grid item xs={12} md={8}>
									<Grid container	spacing={2}>
										<Grid item xs={8}>
											<TextField
												fullWidth
												variant="outlined"
												label="Code"
												name="name"
												value={values.name || ''}
												onChange={handleChange}
												onBlur={handleBlur}
												error={Boolean(touched.name && errors.name)}
												helperText={touched.name && errors.name}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												fullWidth
												variant="outlined"
												label="Model #"
												name="model"
												value={values.model || ''}
												onChange={handleChange}
												onBlur={handleBlur}
												error={touched.model && !!errors.model}
												helperText={touched.model && errors.model}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												variant="outlined"
												label="Description"
												name="description"
												value={values.description || ''}
												onChange={handleChange}
												onBlur={handleBlur}
												error={Boolean(touched.description && errors.description)}
												helperText={touched.description && errors.description}
												multiline
												sx={styles.description}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												variant="outlined"
												label="Category"
												name="category"
												onChange={e => {
												const { value } = e.target;
													handleChange(e);
													const isComment =  value
														&& Boolean(itemCategories[value])
														&& Boolean(itemCategories[value].comment)
													setFieldValue('comment', isComment)
												}}
												onBlur={handleBlur}
												value={values.category || ''}
												error={Boolean(touched.category && errors.category)}
												helperText={touched.category && errors.category}
												select
												// sx={styles.category}
											>
												<MenuItem value=''></MenuItem>
												{map(itemCategories, (category, categoryId) => {
													const { title } = category;
													return <MenuItem key={categoryId} value={categoryId}>{title}</MenuItem>
												})
												}
											</TextField>
										</Grid>
										<Grid item xs={12}>
			                <FormControlLabel
			                    control={<Switch
			                        checked={values.comment || false}
			                        onChange={() => setFieldValue('comment', !values.comment)}
			                    />}
			                    label={<Typography variant="button">Comment Only</Typography>}
			                    labelPlacement="start"
			                />
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} md={6}>
									<Box sx={styles.header}>
										<Typography variant="h6">Requirements</Typography>
									</Box>
									<Paper elevation={1} sx={styles.paper}>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMore />}
											>
												Category Requirements
											</AccordionSummary>
											<AccordionDetails>
												<List sx={styles.list}>
													{map(requirements, (requirement, reqId) => {
													const itemCatId = values.category;
													if (
														itemCategories[itemCatId] &&
														itemCategories[itemCatId].requirements &&
														itemCategories[itemCatId].requirements[reqId]
														) {
													const { instruction } = requirement;
														return (
														<Fragment key={reqId}>
															<ListItem sx={styles.listItem}>
																<ListItemText
																	primary={instruction}
																/>
															</ListItem>
															<Divider />
														</Fragment>
													)}
													})}
												</List>
											</AccordionDetails>
										</Accordion>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMore />}
											>
												Item Specific Requirements
											</AccordionSummary>
											<AccordionDetails style={{ display: 'inline' }}>
												<List sx={styles.list}>
													{item ? map(item.requirements, (requirement, reqId) => {
													if (requirements && requirement) {
													if (!requirements[reqId]) return console.log(reqId, requirements[reqId])
													const { instruction } = requirements[reqId];
														return (
														<Fragment key={reqId}>
															<ListItem sx={styles.listItem}>
																<ListItemText
																	primary={instruction}
																	style={{ maxWidth: '70%' }}
																/>
																<ListItemSecondaryAction>
																	<IconButton
																		onClick={() => {
																			setOpenReq(true);
																			setReqEditId(reqId)
																			setReqEdit(requirements[reqId])
																		}}
																	>
																		<Create />
																	</IconButton>
																	<IconButton
																		onClick={() => {
																			dispatch(deleteItemRequirement(editId || key, reqId, () => {
																				enqueueSnackbar('Item requirement deleted', { variant: 'warning'})
																			}))
																		}}
																	>
																		<DeleteForever />
																	</IconButton>
																</ListItemSecondaryAction>
															</ListItem>
															<Divider />
														</Fragment>
													)}
													}) : null}
												</List>
											</AccordionDetails>
										</Accordion>
											<Fab
												sx={styles.fab}
												color="primary"
												size="small"
												onClick={() => setOpenReq(true)}
											>
												<Add />
											</Fab>
									</Paper>
								</Grid>
								<Grid item xs={12} md={6}>
									<div style={{ margin: 8 }}>
										<Typography variant="h6">Purchasing Inputs</Typography>
									</div>
									<Paper elevation={1} sx={styles.paper}>
										<List>
										{item ? map(item.inputs, (input, inputId) => {
											return (
												<ListItem key={inputId}>
													<ListItemText primary={input.question} />
													<ListItemSecondaryAction>
														<IconButton
															onClick={() => {
																setOpenInput(true);
																setInputEditId(inputId)
																setInputEdit(input)
															}}
														>
															<Create />
														</IconButton>
														<IconButton
															onClick={() => dispatch(deleteItemInput(editId || key, inputId, () => {
																enqueueSnackbar('Input deleted', { variant: 'warning'})
															}))}
														>
															<DeleteForever />
														</IconButton>
													</ListItemSecondaryAction>
												</ListItem>
											)
										}) : null}
										</List>
										{Boolean(editId)
										? <Fab
										sx={styles.fab}
											color="primary"
											size="small"
											onClick={() => setOpenInput(true)}

										>
											<Add />
										</Fab>
										: <Tooltip
											title="Save Item to add input"
										>
										<Box sx={styles.fab}><Fab
											disabled
											color="primary"
											size="small"
											// onClick={() => Boolean(editId) && setOpenInput(true)}

										>
											<Add />
										</Fab></Box>
											</Tooltip>
										}
									</Paper>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							{(Boolean(editId) || !size(item) > 0)
							&& <Button onClick={handleSave} variant="outlined">Save</Button>}
							<Button
								onClick={handleSaveandClose}
								variant="contained"
								color="primary"
							>
								{editId
									? 'Save & Close'
									: size(item) > 0
										? 'Clone'
										: 'Create'
								}
							</Button>
						</DialogActions>
					</Form>
					<NewRequirement
						open={openReq}
						onClose={() => {
							setOpenReq(false);
							setEditId(editId || key)
							setReqEditId('')
							setReqEdit({})
						}}
						edit={reqEdit}
						editId={reqEditId}
						itemId={editId || key}
						item
					/>
					<NewInput
						open={openInput}
						onClose={() => {
							setOpenInput(false);
							setEditId(editId || key)
							setInputEditId('');
							setInputEdit({});
						}}
						itemId={editId || key}
						inputEditId={inputEditId}
						inputEdit={inputEdit}
					/>
				</Dialog>
			)}}
		</Formik>
	)
};


export default NewItem;
