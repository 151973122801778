import { database, storage } from 'firebase_config'

export const FETCH_SPECS = 'FETCH_SPECS';

const specs = database.ref('purchasing/specs');
const specStorage = storage.ref('purchasing/specs')
// ==================================
// ======Specification Actions=======
// ==================================

export function fetchSpecs() {
    return dispatch => specs.on('value', snapshot => {
        dispatch({
            type: FETCH_SPECS,
            payload: snapshot ? snapshot.val() : null
        })
    })
}

export function createSpec({title, file}, cb) {
    return dispatch => {
        const specKey = specs.push().key;
        dispatch(addSpecAttachment(specKey, file[0], (url) => {
            specs.child(specKey).set({title, url}, cb)
        }))
    }
}

export function editSpec(id, {title, file}, cb) {
    return dispatch => {
        dispatch(addSpecAttachment(id, file[0], (url) => {
            specs.child(id).set({title, url}, cb)
        }))
    }
}

export function deleteSpec(specId, cb) {
    // A cloud function removes the storage items under specId
    return () => specs.child(specId).remove().then(cb)
}

export function addSpecAttachment(specId, file, cb) {
	return () => {
	    const upload = specStorage.child(specId).child(file.name).put(file)
	    upload.on('state_changed', snapshot => {
	        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
	        console.log('Upload is ' + progress + '% done');
	    }, error => console.error(error), () => {
    	    upload.snapshot.ref.getDownloadURL().then(url => {
    	        cb(url)
    	    })
    	})
	}
}