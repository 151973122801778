import { map } from 'lodash';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { fetchSupplierIssues, unFetchSupplierIssues, updateSupplierIssue } from 'actions/suppliers';

const ExampleComponent = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const supplierIssues = useSelector(state => state.supplierIssues);

  const handleListItemClick = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedItem({});
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(fetchSupplierIssues());
    return () => dispatch(unFetchSupplierIssues());
  }, []);
  return (
    <div>
      {map(supplierIssues, (issueData, issueDataId) => (
        <Accordion key={issueDataId}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{issueData.vendorName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {map(issueData.issueItems, (issue, issueId) => (
                <ListItem button onClick={() => handleListItemClick(issue)} key={issueId}>
                  <ListItemText primary={issue.issue} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedItem ? selectedItem.name : ''}</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            ...selectedItem,
            issue: selectedItem.issue || '',
            date: selectedItem.date || '',
            dueDate: selectedItem.dueDate || '',
            initialActions: selectedItem.initialActions || '',
            rootCause: selectedItem.rootCause || '',
            correctiveActions: selectedItem.correctiveActions || '',
            similarIssues: selectedItem.similarIssues || '',
            status: selectedItem.status || '',
          }}
          onSubmit={(values) => {
            dispatch(updateSupplierIssue(values, handleClose));
          }}
        >
          {({values, handleChange}) => (
            <Form>
              <DialogContent>
                <Grid container>
                <Grid item xs={12}>
                  <TextField
                    label="Issue"
                    fullWidth
                    margin="normal"
                    name="issue"
                    value={values.issue}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Date"
                    fullWidth
                    margin="normal"
                    name="date"
                    value={values.date}
                    type="date"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Initial Actions"
                    fullWidth
                    margin="normal"
                    name="initialActions"
                    value={values.initialActions}
                    onChange={handleChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Root Cause"
                    fullWidth
                    margin="normal"
                    name="rootCause"
                    value={values.rootCause}
                    onChange={handleChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Corrective Actions"
                    fullWidth
                    margin="normal"
                    name="correctiveActions"
                    value={values.correctiveActions}
                    onChange={handleChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Similar Issues"
                    fullWidth
                    margin="normal"
                    name="similarIssues"
                    value={values.similarIssues}
                    onChange={handleChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Status"
                    fullWidth
                    margin="normal"
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                  />
                </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ExampleComponent;
