import _ from 'lodash';
import { database } from 'firebase_config'

export const FETCH_REQUIREMENTS = 'FETCH_REQUIREMENTS';
export const FETCH_ALL_REQUIREMENTS = 'FETCH_ALL_REQUIREMENTS';
export const FETCH_REQUIREMENTS_FOR_PO = 'FETCH_REQUIREMENTS_FOR_PO';

const requirements = database.ref('purchasing/requirements');
const itemsCategories = database.ref('purchasing/items/categories');
const items = database.ref('purchasing/items/items');
const purchasing = database.ref('purchasing');

// =====================================
// =====Purchase Order Requirements=====
// =====================================
export function fetchRequirements() {
    return dispatch => requirements.on('value', snapshot => {
        dispatch({
            type: FETCH_ALL_REQUIREMENTS,
            payload: snapshot ? snapshot.val() : null
        })
    })
}


export function fetchCategoryRequirements(itemCategoryId, additionalItemRequirements) {
    const itemReqs = itemsCategories.child(`${itemCategoryId}/requirements`);
    const fillReqs = async snapshot => {
        if (snapshot) {
        let data = {};
        const itemReqs = await snapshot.val();
        const itemsCombined = additionalItemRequirements
        ? { ...additionalItemRequirements, ...itemReqs }
        : itemReqs
        // A note here about using promises in this way, when mapping over the set if we
        // return a promise, we can use Promise.all to await all functions to resolve
        // instead of resolving as 'true-y' for the callback itself.  This will be necessary
        // fetching a list of conditional data as we have here without dispatching for each record
        // and pushing multiple re-renders.  we just wait to dispatch after the data is composed
        await Promise.all(_.map(itemsCombined, async (snap, key) => {
            await requirements.child(key).once('value', res => {
                const req = res.val();
                data[key] = req
            })
        }));
        return data;
    }}
    return additionalItemRequirements
    ? itemReqs.once('value').then(data => fillReqs(data))
    : dispatch => itemReqs
    .on('value', snapshot => {
        fillReqs(snapshot).then(data => {
        dispatch({
            type: FETCH_REQUIREMENTS,
            payload: data
        });
    })
    })
}

export function updatePODocs(poId, items, setFieldValue) {
    return () => database
          .ref(`purchasing/pos/${poId}`)
          .once("value")
          .then(async (snapshot) => {
            const po = snapshot.val();
            // if (!po) return console.log('NO PO');
            const poDocuments = po ? po.poDocuments : {};
            const nonNullItems = _.omit(items, item => !item.downloadURL)
            const itemSpecs = _.map(nonNullItems, item => {
                return _.map(item.comments, comment => comment.spec)
            })
            const itemSpecIds = _.filter(_.uniq(_.flatten(itemSpecs)), e => e);
            const specs = {};
            await Promise.all(
                _.map(itemSpecIds, async (specId) => {
                  await database
                      .ref(`purchasing/specs/${specId}`)
                      .once("value")
                      .then((specSnap) => {
                        const data = specSnap.val();
                        const docData = {
                          downloadURL: data.url,
                          name: data.title,
                          id: specId,
                          specId
                        };
                        return (specs[specId] = docData);
                      });
                  return;
                }),
            );
            const poDocs = {};
            const downloadURLs = {};
            const allDocs = {...poDocuments, ...specs};
            for (const key in allDocs) {
              if (Object.prototype.hasOwnProperty.call(allDocs, key)) {
                const item = allDocs[key];
                if (!downloadURLs[item.downloadURL]) {
                  downloadURLs[item.downloadURL] = true;
                  if (!Boolean(item.specId && !_.includes(itemSpecIds, item.specId))) {
                      poDocs[key] = item;
                  }
                }
              }
            }
            return setFieldValue('poDocuments', poDocs);
          });
}

export function createRequirement(values, cb, id, item) {
    const key = requirements.push().key
    return dispatch => requirements.child(key).set(values, () => {
        if (id) {
            item ?
            dispatch(linkItemToRequirement(key, id, cb)) :
            dispatch(linkItemCategoryToRequirement(key, id, cb));
        }
    })
}

export function editRequirement(id, values, cb) {
    return () => requirements.child(id).update(values).then(cb)
}

export function linkItemCategoryToRequirement(reqId, id, cb) {
    let updates = {};
        updates[`/items/categories/${id}/requirements/${reqId}`] = true;
    return () => database.ref('purchasing').update(updates)
    .then(cb)
    .catch(err => console.log('ERROR: ', err))
}

export function linkRequirements(reqIds, id, cb) {
    let updates = {
        [`/items/categories/${id}/requirements`]:  reqIds
    };
    return () => database.ref('purchasing').update(updates)
    .then(cb)
    .catch(err => console.log('ERROR: ', err))
}

export function linkItemToRequirement(reqId, id, cb) {
    let updates = {
        [`/items/items/${id}/requirements/${reqId}`]: true
    };
    return () => database.ref('purchasing').update(updates)
    .then(cb)
    .catch(err => console.log('ERROR: ', err))
}

export function deleteItemRequirement(itemId, reqId, cb) {
    const updates = {
            // [`requirements/${reqId}`]: null,
            [`items/items/${itemId}/requirements/${reqId}`]: null,
    }
    purchasing.update(updates).then(cb)
    return () => purchasing.update(updates)
    .then(cb);
}

export function fullyDeleteRequirement(reqId) {
    return () => {
        requirements.child(reqId).remove()
    }
}

export function deleteRequirement(catId, reqId, cb) {
    const updates = {
            // [`requirements/${reqId}`]: null,
            [`items/categories/${catId}/requirements/${reqId}`]: null,
    }
    return () =>  database.ref('purchasing').update(updates)
    // .then(after => console.log('after', after))
}

export function fetchItemRequirements(itemId) {
    return dispatch => items.child(`${itemId}/requirements`)
    .on('value', async snapshot => {
        if (snapshot) {
        let data = {};
        const itemReqs = await snapshot.val()
        // A note here about using promises in this way, when mapping over the set if we
        // return a promise, we can use Promise.all to await all functions to resolve
        // instead of resolving as 'true-y' for the callback itself.  This will be necessary
        // fetching a list of conditional data as we have here without dispatching for each record
        // and pushing multiple re-renders.  we just wait to dispatch after the data is composed
        await Promise.all(_.map(itemReqs, async (snap, key) => {
            await requirements.child(key).once('value', res => {
                const req = res.val();
                data[key] = req
            })
        }));
        if (dispatch) dispatch({
            type: FETCH_REQUIREMENTS,
            payload: data
        });
    }})
}