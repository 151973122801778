import { SHOW_SNACKBAR } from 'actions';

const INITIAL_STATE = {
    message: '',
    options: {

    }
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SHOW_SNACKBAR:
            return action.payload;
        default:
            return state;
    }
}