import { values, map, omitBy, last, mapValues } from 'lodash';
import { database } from 'firebase_config'

export const FETCH_ITEM_CATEGORIES = 'FETCH_ITEM_CATEGORIES';
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_ITEM = 'FETCH_ITEM';

const categories = database.ref('purchasing/items/categories');
const items = database.ref('purchasing/items/items');
const poItems = database.ref('purchasing/poItems');
const itemCategoryQuestions = database.ref('purchasing/questions/itemCategoryQuestions');

// =================================
// ======Item Category Actions======
// =================================

export function fetchItemCategories() {
    return dispatch => categories.on('value', snapshot => {
        dispatch({
            type: FETCH_ITEM_CATEGORIES,
            payload: snapshot ? snapshot.val() : {}
        })
    })
}

export function markCritical(catId, status) {
    return () => categories.child(catId).update({ critical: status});
}

export function deleteItemCategory(catId, cb) {
    const updates = {
        [`/items/categories/${catId}`]: null,
        [`purchasing/questions/itemCategoryQuestions/${catId}`]: null,
    }
    return () => database.ref('purchasing').update(updates);
}
export function createItemCategory(values, cloneId) {
    return () => categories.push(values).then(({ key }) => {
        if (!!cloneId) {
            categories.child(cloneId).child('requirements').once('value', snapshot => {
                const requirements = snapshot.val();
                itemCategoryQuestions.child(cloneId).once('value', snap => {
                    const linkedQuestions = snap.val();
                    const updates = {
                        [`items/categories/${key}/requirements`]: requirements,
                        [`questions/itemCategoryQuestions/${key}`]: linkedQuestions,
                    }
                    database.ref('purchasing').update(updates)
                })
            })
        }
    })
}

export function createItem(values, key, cb) {
    return () => items.child(key).update(values).then(cb)
}

export function fetchItems() {
    return dispatch => items.on('value', snapshot => {
        dispatch({
            type: FETCH_ITEMS,
            payload: snapshot ? snapshot.val() : null
        })
    })
}

// export async function fetchItemByName(name) {

//     const findItemByName = items.orderByChild('name').equalTo(name);
//     return dispatch => findItemByName.on('value')
//     .then(snapshot => snapshot.val())
// }

export async function fetchItemByName(field, value) {
    const findItemByName = items.orderByChild(field).equalTo(value);
    const find = async () => {
        const item = await findItemByName.once('value').then(async s => {
            const item = s.val();
            const name = item && values(item)[0].name;
            const lastPOItems = await poItems.orderByChild('code').equalTo(name).limitToLast(10)
            .once('value').then(s => s.val());
            const nonZeros = omitBy(lastPOItems, poItem => !poItem.cost);
            const costs = map(nonZeros, obj => obj.cost)
            const lastCost = last(costs);
            return mapValues(item, o => ({ ...o, cost: lastCost}));
            })
        return item
    }
    const result = await find();
    return result
}

export function deleteItem(key, cb) {
    return () => items.child(key).remove().then(cb)
}

export function addItemInput(values, itemId, cb) {
    return () => items.child(`${itemId}/inputs`).push(values).then(cb);
}

export function editItemInput(values, itemId, inputId, cb) {
    return () => items.child(`${itemId}/inputs/${inputId}`).set(values).then(cb);
}

export function deleteItemInput(itemId, inputId, cb) {
    return () => items.child(`${itemId}/inputs/${inputId}`).remove().then(cb);
}

