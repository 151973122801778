import { map, pickBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { object, string, number } from 'yup';
import Dropzone from 'react-dropzone'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Button,
	TextField,
	FormControlLabel,
	FormHelperText,
	Checkbox,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Radio,
	RadioGroup,
	Slider,
	Typography
} from '@mui/material';
import {
	ExpandMore,
	PriorityHigh,
	DoneOutline,
	ErrorOutline
} from '@mui/icons-material';
import { orange, green, red } from '@mui/material/colors';
import { fetchEvaluation, answerQuestion, attachEvidence } from 'actions/suppliers';
import { fetchQuestions, fetchQuestionCategories } from 'actions/questions';


const styles = {
	warning: {
		color: orange[600]
	},
	done: {
		color: green[600]
	},
	errorOutline: {
		color: red[600]
	},
	summary: {
		'& div' : {
			minHeight: 84,
			paddingTop: 5,
			paddingBottom: 8
		}
	},
	panel: {
		display: 'block'
	},
	radios: {
		flexDirection: 'row'
	},
	errorText: {
		marginBottom: theme => theme.spacing(2)
	},
	iconButton: {
		padding: 0,
		marginLeft: theme => theme.spacing(1),
		marginRight: theme => theme.spacing(1),
	}
}

const ComposedTextfield = ({
	qid,
	supplierId,
	question: {
		question,
		attachment,
		evidence,
	},
	reportQuestion,
	reportResponse: {
		answer: resAnswer,
		attachments: resUrl,
		evidence: resEvidence,
		summary: resSummary,
		score: resScore
	}
}) => {
	const dispatch = useDispatch();
	return (
	<Formik
		enableReinitialize
		initialValues={{
			answer: resAnswer || '',
			summary: resSummary || '',
			attachments: resUrl || {},
			score: resScore || 0
		}}
		validationSchema={
			object().shape({
				answer: reportQuestion && reportQuestion.question ? string().required() :  string(),
				summary: reportQuestion && reportQuestion.evidence ? string().required() :  string(),
				// attachments: reportQuestion && reportQuestion.attachment ? array().min(1) :  string(),
				score:  number().when('answer', {
					is: 'yes',
					then: number().moreThan(0)
				})
		}
		)}
		onSubmit={(values)=> {
		 const points = (values.score/100) * (reportQuestion.weight || 0)
			dispatch(answerQuestion(supplierId, qid, { ...values, points }, () => {
				console.log('Done')
			}))
		}}
		>{
			({
				setFieldValue,
				handleBlur,
				values,
				errors,
				handleChange,
				touched
			}) => {
				return (
				<Form>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<IconButton disableRipple sx={styles.iconButton}>
								{reportQuestion.question ?
									resAnswer ?
										resScore > 80 ? <DoneOutline sx={styles.done} /> : <PriorityHigh sx={styles.warning}/>
										:
										<ErrorOutline sx={styles.errorOutline}/>
									:
									null
								}
							</IconButton>
							{question}
						</AccordionSummary>
						<AccordionDetails sx={styles.panel}>
							<RadioGroup
								value={values.answer}
								onChange={handleChange}
								name="answer"
								sx={styles.radios}
							>
								<FormControlLabel
									control={<Radio color="primary" />}
									value="yes"
									label="Yes"
								/>
								<FormControlLabel
									control={<Radio color="primary" />}
									value="no"
									label="No"
								/>
							</RadioGroup>
							<TextField
								label="Answer summary"
								name="summary"
								sx={styles.summary}
								value={values.summary}
								onChange={handleChange}
								onBlur={handleBlur}
								variant="outlined"
								fullWidth
								multiline
								error={Boolean(touched.summary && errors.summary)}
								helperText={touched.summary && errors.summary}
							/>
							<ul>
								{map(values.attachments, attachment => (
									<li key={attachment.url}>
										<a href={attachment.url} target="_blank" rel="noopener noreferrer">{attachment.name}</a>
									</li>
								))}
							</ul>
							<Dropzone
								onDrop={acceptedFiles => dispatch(attachEvidence(supplierId, qid, acceptedFiles, (url) => {
									console.log(url.name)
								}))}
							>
							  {({getRootProps, getInputProps}) => (
							    <section>
							      <div {...getRootProps()}>
							        <input {...getInputProps()} />
							        <p>Drag 'n' drop some files here, or click to select files</p>
								      <FormHelperText
								      	error={Boolean(touched.url && errors.url)}
								      	sx={styles.errorText}
								      >
								      	{touched.url && errors.url}
								      </FormHelperText>
							      </div>
							    </section>
							  )}
							</Dropzone>
							<Typography gutterBottom>
				        Score
				      </Typography>
							<Slider
				        value={values.score}
				        name="score"
				        onChange={(e,v) => setFieldValue('score', values.answer === 'yes' ? v : 0)}
				        step={5}
				        min={0}
				        max={100}
				        valueLabelDisplay="auto"
				      />
				      <FormHelperText
				      	error={Boolean(touched.score && errors.score)}
				      	sx={styles.errorText}
				      >
				      	{touched.score && errors.score}
				      </FormHelperText>
				      <Button
				      	type="submit"
				      	variant="contained"
				      	color="primary"
				      >
				      	Submit
				      </Button>
						</AccordionDetails>
					</Accordion>
				</Form>
				);
			}
		}
		</Formik>
	)}

const Evaluation = ({
	open,
	onClose,
	supplierId,
	canEval
}) => {
	const dispatch = useDispatch();
	const questions = useSelector(state => state.questions);
	const questionCategories = useSelector(state => state.questionCategories);
	const evaluation = useSelector(state => state.evaluation);

	useEffect(() => {
			dispatch(fetchEvaluation(supplierId));
      dispatch(fetchQuestions());
      dispatch(fetchQuestionCategories());
		}, []);
	useEffect(() => canEval(!!evalReport), [evaluation])
	const [all, setAll] = useState(false);
	const { evalReport, evalResponse } = evaluation;
	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
			<DialogTitle>Supplier Evaluation</DialogTitle>
				<DialogContent>
					<FormControlLabel
						control={<Checkbox
								checked={all}
								onChange={() => setAll(prev => (!prev))}
							/>}
						label="Show all questions"
					/>
					{map(questionCategories, (questionCategory, catId) =>{
						return (
							<Accordion key={catId}>
								<AccordionSummary expandIcon={<ExpandMore />}>{questionCategory}</AccordionSummary>
								{map(pickBy(questions, { categoryId: catId}), (question, qid) => (
									<ComposedTextfield
										key={qid}
										qid={qid}
										supplierId={supplierId}
										reportQuestion={evalReport ? evalReport[qid] || {} : {}}
										reportResponse={evalResponse ? evalResponse[qid] || {} : {}}
										question={question}
									/>
								))}
							</Accordion>
						)
					})}

				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Cancel</Button>
					<Button
						type="submit"
						variant="contained"
						color="primary"
					>
						Submit
					</Button>
				</DialogActions>
		</Dialog>
	)}



export default Evaluation;