import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';

const snakeInit = [
    [10, 10],
    [15, 10],
    [20, 10],
    [25, 10],
    [30, 10],
    [35, 10],
    [35, 15],
    [35, 20],
]

const HEIGHT = 400;
const WIDTH = 400;

const Canvas = () => {
    const [ snake, setSnake ] = useState(_.values(snakeInit));
    const [ direction, setDirection ] = useState('right');
    const [ food, setFood ] = useState(random());
    const [ crash, setCrash ] = useState(false);
    const [ score, setScore ] = useState(0);
    const [ speed, setSpeed ] = useState(50)
    function random() {
      return [
          Math.floor((Math.random() * WIDTH) / 5) * 5,
          Math.floor((Math.random() * HEIGHT) / 5) * 5,
          ]
    }
    const restart = () =>{
        setSnake(_.values(snakeInit));
        setDirection('right');
        setFood(random());
        setScore(0);
        setCrash(false);
    }
    useEffect(()=>document.addEventListener('keydown', e => {
        switch(e.keyCode) {
            case 37:
                setDirection(dir => dir === 'right' ? 'right' : 'left');
                break;
            case 38:
                setDirection(dir => dir === 'down' ? 'down' : 'up')
                break;
            case 39:
                setDirection(dir => dir === 'left' ? 'left' : 'right')
                break;
            case 40:
                setDirection(dir => dir === 'up' ? 'up' : 'down')
                break;
            case 82:
                restart();
                break;
            default:
                break;
        }
    }), []);
    function useInterval(callback, delay) {
      const savedCallback = useRef();

      useEffect(() => {
        savedCallback.current = callback;
      }, [callback]);

      useEffect(() => {
        function tick() {
          if(crash) {
            return;
          }
          savedCallback.current();
        }
        if (delay !== null) {
          let id = setInterval(tick, delay);
          return () => clearInterval(id);
        }
      }, [delay, crash]);
    }
    const didEat = newSnake => {
        const [ x,y ] = newSnake[newSnake.length - 1];
        if (x === food[0] && y === food[1]) {
            setFood(random());
            setSpeed(speed - (speed * .02));
            return true
        } else { return false }
    }
    const didCrash = point => {
        return _.map(snake, seg => {
            if(seg[0] === point[0] && seg[1] === point[1]) {
                setCrash(true);
            }
        })
    }
    const move = (x,y) => {
        const newSnake = [...snake];
        const nowX = newSnake[newSnake.length-1][0];
        const nowY = newSnake[newSnake.length-1][1];
        const moveX = () =>{
            if (nowX > WIDTH - 5) {
                return 0;
            } else if (nowX < 0){
                return WIDTH - 5;
            } else {
                return nowX + x;
            }
        };
        const moveY = () =>{
            if (nowY > HEIGHT - 5) {
                return 0;
            } else if (nowY < 0){
                return HEIGHT - 5;
            } else {
                return nowY + y;
            }
        };
        const point = [moveX(), moveY()];
        didCrash(point)
        newSnake.push(point);
        const ate = didEat(newSnake);
        if (!ate) {
            newSnake.shift();
        }
        if (ate) {
            setScore(score + 1)
        }
        setSnake(newSnake);
    };
    useInterval(() => {
        switch(direction) {
            case 'right' :
                move(5,0);
                break;
            case 'left' :
                move(-5,0);
                break;
            case 'up' :
                move(0,-5);
                break;
            case 'down' :
                move(0,5);
                break;
            default:
                break;
        }
        draw();
    }, speed);
    const draw = crash ? ()=>{} : () => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'green';
        ctx.clearRect(0,0,WIDTH,HEIGHT);
        snake.forEach(point => ctx.fillRect(point[0], point[1], 5, 5));
        ctx.fillStyle= 'red';
        ctx.fillRect(food[0], food[1], 5, 5);
    };
    return (
        <div
            style={{
                height: 600,
                width: '100%',
                marginTop: 15
            }}
        >
            <div style={{width: WIDTH, margin: 'auto' }}>
            {crash ? <div>You Crashed <button onClick={restart}>Restart</button></div> : null}
            <canvas id="canvas" height={HEIGHT} width={WIDTH} style={{ border: '1px solid gray'}}></canvas>
            <div>
                <div style={{margin: '0 auto', width: 48}}>
                    <IconButton
                        onClick={()=>setDirection(dir => dir === 'down' ? 'down' : 'up')}
                        onTouchStart={()=>setDirection(dir => dir === 'down' ? 'down' : 'up')}
                    >
                        <KeyboardArrowUp />
                    </IconButton>
                </div>
                <div style={{margin: '0 auto', width: 136, display: 'flex'}}>
                    <IconButton
                        onClick={()=>setDirection(dir => dir === 'right' ? 'right' : 'left')}
                        onTouchStart={()=>setDirection(dir => dir === 'right' ? 'right' : 'left')}
                    >
                        <KeyboardArrowLeft />
                    </IconButton>
                    <div style={{width: 40, textAlign: 'center', margin: 'auto'}} >{score}</div>
                    <IconButton
                        onClick={()=>setDirection(dir => dir === 'left' ? 'left' : 'right')}
                        onTouchStart={()=>setDirection(dir => dir === 'left' ? 'left' : 'right')}
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                </div>
                <div style={{margin: '0 auto', width: 48}}>
                    <IconButton
                        onClick={()=>setDirection(dir => dir === 'up' ? 'up' : 'down')}
                        onTouchStart={()=>setDirection(dir => dir === 'up' ? 'up' : 'down')}
                    >
                        <KeyboardArrowDown />
                    </IconButton>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Canvas;