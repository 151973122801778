import { map, size } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Button,
  BottomNavigation,
  BottomNavigationAction,
  Slide,
  Checkbox,
  FormControlLabel,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Box
} from '@mui/material';
import green from '@mui/material/colors/green'
import amber from '@mui/material/colors/amber'
import Close from '@mui/icons-material/Close';
import RotateRight from '@mui/icons-material/RotateRight';
import AllInclusive from '@mui/icons-material/AllInclusive';
import RateReview from '@mui/icons-material/RateReview';
import DonutLarge from '@mui/icons-material/DonutLarge';
import LocalAtm from '@mui/icons-material/LocalAtm';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Create from '@mui/icons-material/Create';
import Check from '@mui/icons-material/Check';

import {
	deleteSupplier,
	createNewEval,
	markCritical,
	fetchShortEval,
	fetchSupplier,
	toggleVendorSuspension
} from 'actions/suppliers';

import LinkCategories from 'components/suppliers/linkCategories';
import Evaluation from 'components/suppliers/evaluate';
import EditSupplier from 'components/suppliers/new';
import CriticalEval from 'components/suppliers/criticalEval';

const styles = {
	closeButton: {
		position: 'absolute',
		top: theme => theme.spacing(1),
		right: theme => theme.spacing(1)
	},
	cardTitle: {
		textTransform: 'uppercase',
		paddingBottom: 0
	},
  contact: {
    '& span' : {
      fontSize: '.8em',
    },
    '& svg': {
      height: '.8em',
      width: '.8em',
    },
  },
  categoryTable: {
  	marginTop: 0
  }
};

const CircularProgressWithLabel = (props) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


const Supplier = ({
	open,
	onClose,
	supplierId
}) => {
  const dispatch = useDispatch();
  const supplier = useSelector(state => state.suppliers && state.suppliers[supplierId]);
	const [ rating, setRating ] = useState('total');
	const [link, setLink] = useState(false);
	const [evaluate, setEvaluate] = useState(false);
	const [canEval , setCanEval] = useState(false);
	const [edit, setEdit] = useState(false);
	const [supplierEval, setSupplierEval] = useState(null);
	const [criticalEval, setCriticalEval] = useState(false);
	const formatDate = (dateString) => {
    if (!dateString) return null;
    const year = dateString.slice(0, 4);
    const month = dateString.slice(5, 7);
    const day = dateString.slice(8, 10);
    return `${month}/${day}/${year}`;
  };
	// useEffect(() => fetchSupplier(supplierId), [supplierId]);
	const toggleSuspension = () => {
	  dispatch(toggleVendorSuspension(supplierId))
	}
	React.useEffect(() => {
	  if (supplierId && supplier) supplier.shortEval ?
	  dispatch(fetchShortEval(supplier.shortEval)).then(evaluation => {
	    setSupplierEval(evaluation || null);
	  })
	  : setSupplierEval(null);
	  if (supplierId && size(supplier.initialPOs) < 3) {
	    dispatch(fetchSupplier(supplierId))
	  }
	}, [criticalEval, supplierId])
	if (!(supplier && supplier.primaryContact)) return null;
	const approved = size(supplier.initialPOs) > 2
	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth="lg"
		>
			<DialogTitle>
				<Typography variant="h4" component="div">
				{`${supplier.name}${supplier.critical ? ' (Critical Supplier)' : ''}`}
				</Typography>
				<IconButton
					onClick={onClose}
					sx={styles.closeButton}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
			 <CriticalEval
			  open={criticalEval}
			  onClose={() => {
			    setCriticalEval(false);
			    setSupplierEval(null)
			  }}
			  vendorValues={{ ...supplier, supplierId }}
			  editEval={supplierEval}
			 />
									<Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Card sx={{position: 'relative'}}>
                          <CardHeader
                            sx={styles.cardTitle}
                            titleTypographyProps={{
                              color: supplier.suspended ? 'secondary' : 'default'
                            }}
                            title={`Status: ${supplier.suspended
                            ? "SUSPENDED"
                            : approved
                              ? "APPROVED"
                              : "PENDING APPROVAL"
                            }`}
                          />

                            <Slide in={rating === 'total'} direction={'left'} sx={{position: 'absolute', top: theme => theme.spacing(6), left: 0}}>
                              <CardContent>
                                <Typography variant="button">Scope of Approval</Typography>
                                <List>
                                <ListItem>
                                    <ListItemIcon
                                      sx={{color: approved ? green[600] : amber[600]}}
                                    >
                                      {approved
                                      ? <Check />
                                      : <RotateRight />}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={"Non-Critical Service Related Product"}
                                      secondary={`Status: ${approved ? "APPROVED" : "PENDING APPROVAL"}`}
                                      />
                                  </ListItem>
                                  {supplier.critical &&
                                  <ListItem>
                                    <ListItemIcon
                                    >
                                      <IconButton
                                        onClick={() => setCriticalEval(true)}
                                        sx={{margin: theme => theme.spacing(-1), color: supplierEval ? green[600] : amber[600]}}
                                      >
                                        {supplierEval
                                      ? <Check />
                                      : <RotateRight />}
                                      </IconButton>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={'Critical Supplier Evaluation'}
                                      secondary={supplierEval && `Scope: ${supplierEval.scope} ${supplierEval.evalDate ? `(${formatDate(supplierEval.evalDate)})` : '(3/30/23)'}`}
                                    />
                                  </ListItem>}
                                </List>
                              </CardContent>
                            </Slide>
                            <Slide in={rating === 'eval'} direction={'left'} sx={{minHeight: 240}}>
                              <CardContent>
                                <List>
                                {map(supplier.initialPOs, (init, index) => {
                                  return <ListItem key={index}>
                                    <ListItemIcon
                                      sx={{color: green[600]}}
                                    >
                                      <Check />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={`Purchase Order: ${init.po}`}
                                      secondary={`PO Total: $ ${init.total}`}
                                      />
                                  </ListItem>
                                })}
                                </List>
                              </CardContent>
                            </Slide>
                            <Slide in={rating === 'performance'} direction={'left'} sx={{position: 'absolute', top: theme => theme.spacing(6), left: 0}}>
                              <CardContent>
                                <Typography variant="button">PO Totals/Issues</Typography>
                                <List>
                                {map(supplier.yearCount, (total, year) => {
                                  const count = parseInt(total)
                                  const grade = (count/count-0)*100
                                  return <ListItem key={year}>
                                    <ListItemIcon
                                      sx={{color: green[600]}}
                                    >
                                      <CircularProgressWithLabel value={grade} />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={`${year}`}
                                      secondary={`${total.toLocaleString()}/0`}
                                      />
                                  </ListItem>
                                })}
                                </List>
                              </CardContent>
                            </Slide>
                            <Slide in={rating === 'price'} direction={'left'} sx={{position: 'absolute', top: theme => theme.spacing(6), left: 0}}>
                              <CardContent>
                                <Typography variant="button">Year Totals</Typography>
                                <List>
                                {map(supplier.yearTotals, (total, year) => {
                                  return <ListItem key={year}>
                                    <ListItemText
                                      primary={`${year}`}
                                      secondary={`$ ${total.toLocaleString()}`}
                                      />
                                  </ListItem>
                                })}
                                </List>
                              </CardContent>
                            </Slide>
                          <BottomNavigation value={rating} onChange={(e,v) => setRating(v)}>
                            <BottomNavigationAction label="Total" value="total" icon={<AllInclusive />}/>
                            <BottomNavigationAction label="Evaluation" value="eval" icon={<RateReview />}/>
                            <BottomNavigationAction label="Performance" value="performance" icon={<DonutLarge />}/>
                            <BottomNavigationAction label="Price" value="price" icon={<LocalAtm />}/>
                          </BottomNavigation>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Card>
                          <CardHeader sx={styles.cardTitle} title="Contacts" />
                          <CardContent>
                            <Typography variant="h6">
                            	{`${supplier.primaryContact.firstName} ${supplier.primaryContact.lastName}`}
                          	</Typography>
                            <div>{supplier.primaryContact.email}</div>
                            <div>
                              <FormControlLabel
                                label="Contact on email submittal"
                                margin="dense"
                                sx={styles.contact}
                                control={
                                  <Checkbox checked={supplier.primaryContact.contact} />
                                }
                              />
                            </div>
                            <Typography variant="h6">
                            	{`${supplier.qualityContact.firstName} ${supplier.qualityContact.lastName}`}
                          	</Typography>
                            <div>{supplier.qualityContact.email}</div>
                            <div>
                              <FormControlLabel
                                label="Contact on quality issue"
                                margin="dense"
                                sx={styles.contact}
                                control={
                                  <Checkbox checked={supplier.qualityContact.contact} />
                                }
                              />
                            </div>
                          </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Card>
                          <CardHeader sx={styles.cardTitle} title="Physical Address" />
                          <CardContent>
                            <div>{supplier.name}</div>
                            <div>{supplier.physical.address1}</div>
                            <div>{supplier.physical.address2}</div>
                            <div>{supplier.physical.city},{' '}{supplier.physical.state}</div>
                          </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Card>
                          <CardHeader sx={styles.cardTitle} title="Mailing Address" />
                          <CardContent>
                            <div>{supplier.name}</div>
                            <div>{supplier.billing.address1}</div>
                            <div>{supplier.billing.address2}</div>
                            <div>{supplier.billing.city},{' '}{supplier.billing.state}</div>
                          </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Card>
                          <CardHeader
                          	sx={styles.cardTitle}
                          	title="Item Categories"
                          	action={
                          		<IconButton
                          		  onClick={() => setLink(true)}
                          		>
                          			<Create />
                          		</IconButton>
                          	}
                        	/>
                          <CardContent>
                          	<Table sx={styles.categoryTable} size="small">
                          		<TableHead>
                          			<TableRow>
                          				<TableCell>Title</TableCell>
                          				<TableCell>Status</TableCell>
                          			</TableRow>
                          		</TableHead>
                          		<TableBody>
		                          	{map(supplier.linkedCategories, ({ status, title }, catId) => {
		                          		return <TableRow key={catId}>
		                          			<TableCell>{title}</TableCell>
		                          			<TableCell>{status || 'Pending'}</TableCell>
		                          		</TableRow>
		                          	})}
                          		</TableBody>
                          	</Table>
                          </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Card>
                          <CardHeader sx={styles.cardTitle} title="Actions" />
                          <CardContent>
                            <Button
                              onClick={() => dispatch(markCritical(supplierId))}
                              variant="contained"
                            >
                              Mark as Critical
                            </Button>
                            <Button
                              onClick={() =>{dispatch(createNewEval(supplierId))}}
                              variant="contained"
                            >
                              Create New Evaluation
                            </Button>
                            <Button
                              onClick={() =>{setEvaluate(true)}}
                              variant="contained"
                              disabled={canEval}
                            >
                              Complete Evaluation
                            </Button>
                            <Button
                              onClick={() =>{setEdit(true)}}
                              variant="contained"
                            >
                              Edit Supplier Info
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={toggleSuspension}
                            >
                              {!supplier.suspended
                              ? "Suspend Vendor Approval"
                              : "Reinstate Vendor"}
                            </Button>
                            <IconButton
                              sx={styles.deleteButton}
                              onClick={() => dispatch(deleteSupplier(supplierId, () => console.log('deleted')))}
                            >
                              <DeleteForever
                              />
                            </IconButton>
                          </CardContent>
                        </Card>
                    </Grid>
                  </Grid>
                  <EditSupplier
                    open={edit}
                    onClose={() => setEdit(false)}
                    supplier={supplier}
                    supplierId={supplierId}
                  />
			</DialogContent>
			<LinkCategories
			  open={link}
			  onClose={() => setLink(false)}
			  linked={supplier.linkedCategories || {}}
			  supplierId={supplierId}
		  />
		  <Evaluation
		    open={evaluate}
		    onClose={() => setEvaluate(false)}
		    supplierId={supplierId}
		    canEval={can => setCanEval(can)}
		  />
		</Dialog>
	);
};


export default Supplier;
