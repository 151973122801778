import _ from 'lodash';
import { database } from 'firebase_config';

export const FETCH_QUESTIONS = 'FETCH_QUESTIONS'
export const FETCH_QUESTION_CATEGORIES = 'FETCH_QUESTION_CATEGORIES'
export const FETCH_ITEM_CATEGORY_QUESTIONS = 'FETCH_ITEM_CATEGORY_QUESTIONS'

const questions = database.ref('purchasing/questions/questions');
const questionCategories = database.ref('purchasing/questions/categories');
const itemCategoryQuestions = database.ref('purchasing/questions/itemCategoryQuestions')

// =====================================
// =======Supplier Questions============
// =====================================

export function createQuestion(values, cb) {
    return () => questions.push(values).then(cb);
}

export function editQuestion(id, question, cb) {
    return () => questions.child(id).update({question}).then(cb)
}

export function fetchQuestions() {
    return dispatch => questions.on('value', snapshot => {
        dispatch({
            type: FETCH_QUESTIONS,
            payload: snapshot ? snapshot.val() : null
        })
    })
}

export function deleteQuestion(id, categoryId, cb) {
    return dispatch => questions.child(id).remove()
    .then(() => questions.orderByChild('categoryId').equalTo(categoryId).once('value', snap => {
        dispatch(organizeIndex(snap.val()))
        cb()
    }))
}

export function organizeIndex(questionList) {
    const linked = _.sortBy(_.map(questionList, (v,k) => ({ ...v, id: k })), 'index')
    return () => linked.forEach((q, index) => {
            questions.child(q.id).update({index: index + 1});
        })
}
// =====================================
// ===Supplier Question Categories======
// =====================================

export function createQuestionCategories(values, cb) {
    return () => questionCategories.push(values).then(cb);
}

export function editQuestionCategoriesn(id, value, cb) {
    return () => questionCategories.child(id).update(value).then(cb)
}

export function fetchQuestionCategories() {
    return dispatch => questionCategories.on('value', snapshot => {
        dispatch({
            type: FETCH_QUESTION_CATEGORIES,
            payload: snapshot.val()
        })
    })
}


// =====================================
// ===Link Question to Item Categories==
// =====================================

export function fetchItemCategoryQuestions(itemCategoryId) {
    return dispatch => itemCategoryQuestions.child(itemCategoryId).on('value', snapshot => {
        dispatch({
            type: FETCH_ITEM_CATEGORY_QUESTIONS,
            payload: snapshot ? snapshot.val() : null
        })
    })
}

export function linkQuestionToItemCategory(questionId, itemCategoryId, cb) {
    const status = itemCategoryQuestions.child(`${itemCategoryId}/${questionId}/linked`)
    return () => status.once('value', snap => {
        status.set(!snap.val())
    }).then(cb)
}

export function toggleQuestionControls(questionId, itemCategoryId, type) {
    const control = itemCategoryQuestions.child(`${itemCategoryId}/${questionId}/${type}`)
    return () => control.once('value', snap => control.set(!snap.val()))
}

export function setQuestionWeight(questionId, itemCategoryId, weight) {
    const control = itemCategoryQuestions.child(`${itemCategoryId}/${questionId}/weight`)
    return () => control.once('value', snap => control.set(weight))
}
