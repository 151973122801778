import { map } from 'lodash';
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import amber from "@mui/material/colors/amber";
import { useFormik } from "formik";
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from "react-redux";
import { createStyles, makeStyles } from '@mui/styles';

import { saveVendorEval } from 'actions/suppliers'

const useStyles = makeStyles(() =>
  createStyles({
    dropzoneContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: '120px',
      border: '2px dashed #9e9e9e',
      borderRadius: '4px',
      padding: '16px',
      cursor: 'pointer',
      transition: 'border-color 0.3s ease-in-out',
    },
    dropzoneAccepted: {
      borderColor: 'green',
    },
    dropzoneRejected: {
      borderColor: 'red',
    },
  })
);

const CriticalEvaluationDialog = ({ open, onClose, vendorValues, editEval }) => {
	const classes = useStyles();
  const user = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const initialValues = editEval ? {
    ...editEval,
    attachments: editEval.attachments || [],
    files: editEval.files || [],
    options: editEval.options || {}
    } : {
    vendor: vendorValues.name || "",
    supplierId: vendorValues.supplierId,
    scope: "",
    options: {},
    evalDate: '',
    initialPOs: ["", "", ""].map((e, i) =>
      vendorValues.initialPOs && vendorValues.initialPOs[i]
        ? vendorValues.initialPOs[i].po
        : ""
    ),
    approvedBy: user ? `${user.firstName} ${user.lastName}` : "",
    attachments: [],
    files: [],
    qmsCertifiedOrCompliant: "",
    qmsStandards: "",
    supplyChainControl: false,
    qualityManualAvailable: false,
    qualityGoalsAvailable: false,
    customerPurchaseOrdersReviewed: false,
    periodicReviewsManagement: false,
    personnelTrained: false,
    secureSystemAvailable: false,
    obsoleteDocumentsRemoved: false,
    writtenPurchaseOrdersUsed: false,
    purchaseOrdersVerified: false,
    inventoryMaintained: false,
    measuringInstrumentsCalibrated: false,
    calibrationItemsTraceable: false,
    calibrationActivitiesClimateControlled: false,
    inspectionProceduresApproved: false,
    resourcesAvailable: false,
    housekeepingAcceptable: false,
    workAreaOrganized: false,
  };
  const handleSubmit = (values) => {
    const cb = () => onClose()
    dispatch(saveVendorEval(values, cb));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });

  const { values, handleChange, handleBlur, setFieldValue } = formik;
  // Function to handle files accepted by the dropzone
  const handleDrop = (acceptedFiles) => {
    // You can handle the accepted files here as per your requirements
    if(!acceptedFiles[0]) return;
    const newFiles = [...values.files, acceptedFiles[0]]
    setFieldValue('files', newFiles)
  };

  // React Dropzone hook
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: {
    	'application/pdf': ['.pdf'],
    	'image/*': ['.png', '.jpg', '.jpeg'],
    },
    onDrop: handleDrop,
	  maxFiles: 1,
  });

const dropzoneContainerClass = [
    classes.dropzoneContainer,
    isDragAccept ? classes.dropzoneAccepted : '',
    isDragReject ? classes.dropzoneRejected : '',
  ].join(' ');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Critical Evaluation</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ "& .MuiFormControl-root": { width: "100%" } }}>
            <TextField
              label="Vendor"
              type="text"
              id="vendor"
              name="vendor"
              value={values.vendor}
              onChange={handleChange}
              onBlur={handleBlur}
              margin="normal"
              disabled
            />
            <TextField
              label="Scope"
              type="text"
              id="scope"
              name="scope"
              value={values.scope}
              onChange={handleChange}
              onBlur={handleBlur}
              margin="normal"
            />
            <TextField
              label="Date of Evalutation"
              type="date"
              id="evalDate"
              name="evalDate"
              value={values.evalDate || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              margin="normal"
            />
            <Grid container sx={{m: 2}}>
              <Grid item xs={12} sx={{ml: -2, color: theme => theme.palette.primary.main}}>
                <label>Qualification Method </label>
                <Typography variant="caption" > (Select all that apply)</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={!!values.options['siteVisit']} onChange={()=>setFieldValue('options', {...values.options, siteVisit: !values.options.siteVisit})}
                  />}
                  label="Vendor Site Visit"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={!!values.options['qmsCertified']} onChange={()=>setFieldValue('options', {...values.options, qmsCertified: !values.options.qmsCertified})}
                  />}
                  label="QMS Certified"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={!!values.options['qmsCompliant']} onChange={()=>setFieldValue('options', {...values.options, qmsCompliant: !values.options.qmsCompliant})}
                  />}
                  label="QMS Compliant and Verified"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={!!values.options['initialPOEval']} onChange={()=>setFieldValue('options', {...values.options, initialPOEval: !values.options.initialPOEval})}
                  />}
                  label="Initial Purchase Evaluation"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{color: theme => theme.palette.primary.main}}>
                <label>Initial POs:</label>
              </Grid>
              {values.initialPOs.map((initialPO, index) => (
                <Grid item xs={4} key={index}>
                  <TextField
                    label={`Initial PO ${index + 1}`}
                    type="text"
                    name={`initialPOs[${index}]`}
                    value={initialPO}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    disabled
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset"sx={{mt: 2, mb: 2}}>
                  <FormLabel component="legend" sx={{color: theme => theme.palette.primary.main}}>
                    QMS Certified or Compliant:
                  </FormLabel>
                  <RadioGroup
                    row
                    name="qmsCertifiedOrCompliant"
                    value={values.qmsCertifiedOrCompliant}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Certified"
                      control={<Radio />}
                      label="Certified"
                    />
                    <FormControlLabel
                      value="Compliant"
                      control={<Radio />}
                      label="Compliant"
                    />
                    <FormControlLabel
                      value="N/A"
                      control={<Radio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl>
          <Grid item xs={12}>
            {values.attachments[0] &&
            <List>
            	<ListItem>Saved Files:</ListItem>
            	{map(values.attachments, (attachment, index) => {
            		return <ListItem key={index+attachment.name} button component="a" href={attachment.url} target="_blank" rel="noopener noreferrer">
            			{attachment.name}
            		</ListItem>
            	})}
            </List>}
            {values.files[0] &&
            <List>
            	<ListItem>Unsaved Files:</ListItem>
            	{map(values.files, (attachment, index) => {
            		return <ListItem key={index+attachment.name}>
            			{attachment.name}
            		</ListItem>
            	})}
            </List>}
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <div {...getRootProps({ className: dropzoneContainerClass })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>
                  Drag and drop PDF or image files here, or click to select files.
                </p>
              )}
            </div>
          </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="List all QMS Standard(s)"
                    type="text"
                    id="qmsStandards"
                    name="qmsStandards"
                    value={values.qmsStandards}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="center"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  Quality Management
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Reviewed the type and extent of control applied by the supplier, internally and to their supply chain?"
                  name="supplyChainControl"
                  checked={values.supplyChainControl}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", color: amber[400] }}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption" align="center">
                  --If certified, remaining questions may be omitted by
                  verifying certification status--
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Quality Manual or equivalent available?"
                  name="qualityManualAvailable"
                  checked={values.qualityManualAvailable}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Quality goals and continuous improvement are available and communicated to all areas of the organization?"
                  name="qualityGoalsAvailable"
                  checked={values.qualityGoalsAvailable}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Customer Purchase Orders are reviewed for accuracy and compliance prior to acceptance to ensure the capability to meet all stated requirements?"
                  name="customerPurchaseOrdersReviewed"
                  checked={values.customerPurchaseOrdersReviewed}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Management periodically reviews customer complaints with Quality, Manufacturing, Engineering, Purchasing, and Materials?"
                  name="periodicReviewsManagement"
                  checked={values.periodicReviewsManagement}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Personnel are trained in the policies and procedures of their operations and departments?"
                  name="personnelTrained"
                  checked={values.personnelTrained}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Is a secure system in place to ensure that current documents (drawings and specifications) and generated records are available and controlled?"
                  name="secureSystemAvailable"
                  checked={values.secureSystemAvailable}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Is a system in place to ensure that obsolete documents are removed from active files?"
                  name="obsoleteDocumentsRemoved"
                  checked={values.obsoleteDocumentsRemoved}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Are written purchase orders used to control purchasing from approved suppliers?"
                  name="writtenPurchaseOrdersUsed"
                  checked={values.writtenPurchaseOrdersUsed}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Are purchase orders verified upon delivery?"
                  name="purchaseOrdersVerified"
                  checked={values.purchaseOrdersVerified}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Is inventory maintained and protected from degradation?"
                  name="inventoryMaintained"
                  checked={values.inventoryMaintained}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Measuring instruments in use are calibrated and have expiration dates defined?"
                  name="measuringInstrumentsCalibrated"
                  checked={values.measuringInstrumentsCalibrated}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Items used for calibration are traceable to NIST? (Calibration Suppliers Only)"
                  name="calibrationItemsTraceable"
                  checked={values.calibrationItemsTraceable}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Are calibration activities completed in a climate-controlled environment? (Calibration Suppliers Only)"
                  name="calibrationActivitiesClimateControlled"
                  checked={values.calibrationActivitiesClimateControlled}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Are inspection procedures approved by an ASNT Certified Level III? (NDE Suppliers Only)"
                  name="inspectionProceduresApproved"
                  checked={values.inspectionProceduresApproved}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="center"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  Organizational Capacity
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <label>Additional Questions:</label>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Does the supplier have resources available to meet Gulf-Pro’s needs and expectations (i.e., personnel, equipment, knowledge)?"
                  name="resourcesAvailable"
                  checked={values.resourcesAvailable}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Is work area housekeeping acceptable?"
                  name="housekeepingAcceptable"
                  checked={values.housekeepingAcceptable}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Is work area organized and free of safety concerns?"
                  name="workAreaOrganized"
                  checked={values.workAreaOrganized}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <TextField
              label="Approved By"
              type="text"
              id="approvedBy"
              name="approvedBy"
              value={values.approvedBy}
              onChange={handleChange}
              onBlur={handleBlur}
              margin="normal"
            />
          </Box>
          <Button type="submit">Submit</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CriticalEvaluationDialog;
