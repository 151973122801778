import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import Draggable from "react-draggable";
import {
    AppBar,
    Toolbar,
    Typography,
    InputBase,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    ListItemIcon,
    InputAdornment,
    IconButton,
    Button,
    OutlinedInput,
    Switch,
    Collapse,
    FormControlLabel,
    Slide,
    Fab,
    Slider,
    Checkbox,
    Box
} from '@mui/material';
import {
    Add,
    ExpandMore,
    DeleteForever,
    Chat,
    Create,
    SaveAlt
} from '@mui/icons-material';

import * as actions from 'actions/questions';
import Question from 'components/questions/question';

const styles = {
    root: {
        flexGrow: 1,
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
    },
    title: {
        flexGrow: 1,
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        padding: theme => theme.spacing(1),
        // transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //       width: 300,
        //     },
        // [theme.breakpoints.up('md')]: {
        //       width: 450,
        //     },
        // [theme.breakpoints.up('lg')]: {
        //       width: 600,
        //     },
    },
    editQuestion: {
        width: '90%'
    },
    button: { height: 35 },
    details: {
        display: 'block',
        marginTop: 0
    },
    expansionPanel: {
        '& h6' : {
            fontSize: '1.25em',
            transition: 'all 0.25s ease-in-out',
        }
    },
    form: { width: '100%' },
    confirm: { fontSize: '1em', '& span' : { fontWeight: 'bold' }},
    drag: {
        '&:focus': {
            backgroundColor: 'rgba( 0, 155, 229, .4)',
            transition: 'background-color 1s',
        },
        '&:hover': { cursor: 'grabbing' }
    },
    draggable: {
        '&:hover' : {
            cursor: 'grab'
        }
    }
}

const DeleteConfirmation = React.forwardRef(({
    questionId,
    deleteQuestion,
    cancel,
    categoryId
}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    return (
        <ListItem ref={ref}>
            <ListItemText
                primary='Are you sure you want to delete this question?'
                sx={styles.confirm}
            />
            <ListItemSecondaryAction>
            <Button variant="contained" onClick={cancel} style={{marginRight: 8}}>No, Cancel</Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => deleteQuestion(questionId, categoryId, () => {
                        enqueueSnackbar('Question deleted', { variant: 'warning' })
                    })}
            >
                Yes, Delete
            </Button>
            </ListItemSecondaryAction>
        </ListItem>
    )
})

const CustomSlider = ({
    current,
    update
}) => {
    const [val, setVal] = useState(current);
    return <Slider
        style={{ color: '#f50057' }}
        min={0}
        max={100}
        step={5}
        value={val || 0}
        valueLabelDisplay="auto"
        onChange={(e,v) => setVal(v)}
        onChangeCommitted={update}
    />
}

const QuestionCategory = connect(mapStateToProps, actions)(({
    questionsPerCat,
    createQuestion,
    categoryId,
    editQuestion,
    category,
    deleteQuestion,
    itemCategoryLink,
    organizeIndex,
    linkQuestionToItemCategory,
    itemCategoriesQuestions,
    toggleQuestionControls,
    setQuestionWeight
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ question, setQuestion ] = useState('')
    const [ edit, setEdit ] = useState('')
    const [ editVal, setEditVal ] = useState('')
    const [ deleteConf, setDeleteConf ] = useState('')
    const [ highlight, setHighlight ] = useState('')
    const [ add, setAdd ] = useState(false)
    return (
        <Fragment>
            <Question
                open={add}
                onClose={()=> {
                    setAdd(false);
                    setQuestion('');
                    setEdit('');
                    setEditVal('');
                }}
                question={question}
                questions={questionsPerCat}
                index={_.size(_.filter(questionsPerCat, { categoryId })) + 1}
                createQuestion={createQuestion}
                categoryId={categoryId}
            />
                <Box
                    component="form"
                    onSubmit={e => {
                        e.preventDefault();
                        if(question) {
                            setAdd(true)
                        }
                    }}
                    sx={styles.form}
                >
                <OutlinedInput
                  placeholder={`Enter new supplier question for ${category}…`}
                //   classes={{
                //     root: classes.inputRoot,
                //     input: classes.inputInput
                //   }}
                  inputProps={{ 'aria-label': 'Search' }}
                  value={question}
                  onChange={e => setQuestion(e.target.value)}
                  fullWidth
                  endAdornment={
                      <InputAdornment position="end">
                        <IconButton type='submit'>
                            <Add />
                        </IconButton>
                      </InputAdornment>
                  }
                />
            </Box>
            <div>
            <List dense id='limit'>
            {_.map(_.sortBy(_.map(questionsPerCat, (v,k) => ({ ...v, questionId: k })), 'index'),
            ({question, itemCategoryLinks, index, questionId }) => {
                const submit = () => editQuestion(questionId, editVal, () => {
                    enqueueSnackbar('Question Updated', { variant: 'success' });
                    setEditVal('');
                    setEdit('');
                })
                const linked =
                    itemCategoriesQuestions &&
                    itemCategoriesQuestions[questionId] &&
                    itemCategoriesQuestions[questionId].linked
                const evidence =
                    itemCategoriesQuestions &&
                    itemCategoriesQuestions[questionId] &&
                    itemCategoriesQuestions[questionId].evidence
                const attachment =
                    itemCategoriesQuestions &&
                    itemCategoriesQuestions[questionId] &&
                    itemCategoriesQuestions[questionId].attachment
                const weight =
                    itemCategoriesQuestions &&
                    itemCategoriesQuestions[questionId] &&
                    itemCategoriesQuestions[questionId].weight
                return (
                    deleteConf === questionId ?
                    <Slide
                        in={deleteConf === questionId}
                        direction="left"
                        key={questionId}
                    >
                        <DeleteConfirmation
                            questionId={questionId}
                            deleteQuestion={deleteQuestion}
                            cancel={() => setDeleteConf('')}
                            categoryId={categoryId}
                        />
                    </Slide>
                    :
                    <Draggable
                        cancel=".no-drag"
                        key={questionId}
                        position={{ x: 0, y: 0 }}
                        onStop={(e, d) => {
                            const location = d.y/30;
                            if (location > 1 || location < -1) {
                                organizeIndex({
                                    ...questionsPerCat,
                                    [questionId]: {
                                        ...questionsPerCat[questionId],
                                        index: index + location
                                    }
                                })
                            }
                            setHighlight('')
                        }}
                        onStart={() => setHighlight(questionId)}
                        axis="y"
                        bounds="parent"
                    >
                    <Slide in={deleteConf !== questionId} direction="right">
                    <div>
                    <ListItem
                        sx={highlight === questionId ? styles.drag : styles.draggable}
                        button
                        dense
                        onClick={itemCategoryLink ?
                        () => {
                            // linkQuestionToItemCategory(questionId, itemCategoryLink, () => {})
                        }
                        :() => {
                            setEdit(edit ? '' : questionId)
                            setEditVal(edit ? '' : question)
                        }}
                    >

                        <ListItemIcon>
                            {edit === questionId ?
                                <Create />
                            :
                                itemCategoryLink ?
                                    <Switch
                                        edge="start"
                                        checked={linked || false}
                                        onChange={()=>{
                                            linkQuestionToItemCategory(questionId, itemCategoryLink, () => {})
                                        }}
                                        className="no-drag"
                                    />
                                :
                                    <Chat />
                            }

                        </ListItemIcon>
                        {edit === questionId ?
                        <Box
                            component="form"
                            onSubmit={e => {
                                e.preventDefault();
                                submit();
                            }}
                            sx={styles.editQuestion}
                        >
                        <InputBase
                            // classes={{
                            //     root: classes.inputInput,
                            //     input: classes.inputRoot
                            // }}
                            value={editVal}
                            onChange={e => setEditVal(e.target.value)}
                            autoFocus
                        />
                        </Box>
                        :
                        <ListItemText
                            primary={question}
                        />
                        }
                        {itemCategoryLink ? null :
                        <ListItemSecondaryAction>
                            <IconButton
                                onClick={edit === questionId ?
                                submit
                                :
                                () => setDeleteConf(questionId)
                                }
                            >
                                {edit === questionId ? <SaveAlt /> : <DeleteForever />}
                            </IconButton>
                        </ListItemSecondaryAction>}
                    </ListItem>
                    <Collapse
                        in={linked}
                    >
                    <ListItem
                        sx={highlight === questionId ? styles.drag : styles.draggable}
                        dense
                    >
                        <ListItemSecondaryAction>
                            <FormControlLabel
                                value="Record Evidence"
                                control={<Checkbox
                                    checked={evidence || false}
                                    onChange={() => {
                                        toggleQuestionControls(questionId, itemCategoryLink, 'evidence')
                                    }}
                                />}
                                label="Record Evidence"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="Include Attachment"
                                control={<Checkbox
                                    checked={attachment || false}
                                    onChange={() => {
                                        toggleQuestionControls(questionId, itemCategoryLink, 'attachment')
                                    }}
                                />}
                                label="Include Attachment"
                                labelPlacement="start"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className="no-drag">
                        <ListItemText
                            primary="Weight"
                            secondary={
                                <CustomSlider
                                    current={weight || 0}
                                    update={(e,v) => {
                                        setQuestionWeight(questionId, itemCategoryLink, v)
                                    }}
                                />
                            }
                        />

                    </ListItem>
                    </Collapse>
                    </div>
                    </Slide>
                    </Draggable>
                )
            })}
            </List></div>
        </Fragment>
    )
})

const Questions = ({
    questions,
    questionCategories,
    fetchQuestions,
    fetchQuestionCategories,
    createQuestionCategories,
    itemCategoryLink,
    itemCategoriesQuestions
}) => {
    useEffect(() => {
        fetchQuestions();
        fetchQuestionCategories();
    }, [])
    const [ category, setCategory ] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const outerTheme = useTheme();
    const innerTheme = {
        components: {
            MuiAccordionSummary: {
                styleOverrides: {
                    root: styles.expansionPanel
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: styles.inputRoot,
                    input: styles.inputInput
                }
            }
        }
    }
    return (
        <ThemeProvider theme={theme => (
         createTheme({
             ...outerTheme,
             ...innerTheme
         })
        )}>
        <AppBar position="static" elevation={0} color="default">
            <Toolbar>
                <Box
                    component="form"
                    onSubmit={e => {
                        e.preventDefault();
                        createQuestionCategories(category, () => {
                            enqueueSnackbar("Category Added", { variant: 'success' });
                            setCategory('')
                        });
                    }}
                    sx={styles.form}
                >
                <InputBase
                  placeholder="Enter new supplier question category…"
                  inputProps={{ 'aria-label': 'Search' }}
                  value={category}
                  onChange={e => setCategory(e.target.value)}
                  fullWidth
                  endAdornment={
                      <InputAdornment position="end">
                        <Fab type='submit' color="primary" size="small">
                            <Add />
                        </Fab>
                      </InputAdornment>
                  }
                />
            </Box>
            </Toolbar>
        </AppBar>
        {_.map(questionCategories, (category, categoryId) => {
            return (
                <Accordion key={categoryId}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <Typography variant="subtitle2">{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={styles.details}>
                        <QuestionCategory
                            questionsPerCat={_.pickBy(questions, {categoryId})}
                            categoryId={categoryId}
                            category={category}
                            itemCategoryLink={itemCategoryLink}
                            itemCategoriesQuestions={itemCategoriesQuestions}
                        />
                    </AccordionDetails>
                </Accordion>
            )
        })}
        </ThemeProvider>
    )
}

function mapStateToProps({ questions, questionCategories }) {
    return { questions, questionCategories }
}

export default connect(mapStateToProps, actions)(Questions);
