import { map, every, includes, pickBy, mapValues, sortBy } from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Paper,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Slide,
} from "@mui/material";
import orange from "@mui/material/colors/orange";
import Add from "@mui/icons-material/Add";
import Create from "@mui/icons-material/Create";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Warning from "@mui/icons-material/Warning";
import Difference from "@mui/icons-material/Difference";

import Drawer from "components/drawer";
import Search from "components/search";
import NewItem from "components/items/new";

import { fetchItems, deleteItem } from "actions/items";
import { fetchRequirements } from "actions/requirements";

const styles = {
  root: {
    flexGrow: 1,
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "70%",
    minWidth: 310,
  },
  title: {
    flexGrow: 1,
  },
  iconButton: {
    padding: (theme) => theme.spacing(1),
  },
  input: {
    marginLeft: 1,
    flex: 1,
  },
  button: {
    position: "absolute",
    right: (theme) => theme.spacing(1),
  },
};

const Confirm = React.forwardRef(({ cancel, confirm }, ref) => (
  <ListItem ref={ref} style={{ minHeight: 72 }}>
    <Warning style={{ color: orange[600] }} />
    <ListItemText
      primary={<Typography variant="h6">Are you sure?</Typography>}
    />
    <ListItemSecondaryAction>
      <Button onClick={cancel}>Nevermind</Button>
      <Button onClick={confirm} color="secondary" variant="contained">
        Yes, Delete
      </Button>
    </ListItemSecondaryAction>
  </ListItem>
));
const Items = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const [terms, setTerms] = React.useState([]);
  const items = useSelector((s) => s.items);
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [item, setItem] = useState({});
  const itemWithIds = React.useMemo(() => mapValues(items, (i, itemId) => ({...i, itemId})), [items]);
  const filteredItems = React.useMemo(() => sortBy(pickBy(itemWithIds, (item, itemId) => {
    const searchString = `${item.name} ${item.description}`.toLowerCase();
    const searchBy = [...terms, search];
    const include = every(searchBy, (by) =>
      includes(searchString, by.toLowerCase())
    );
    return include;
  }), 'name'), [itemWithIds, terms, search]);

  useEffect(() => {
    dispatch(fetchItems());
    dispatch(fetchRequirements());
    }, []);

  const handleOpenNew = () => {
    setItem({});
    setEditId("");
    setOpen(true)
  }
  return (
    <Drawer header="Purchasing" roles={["ALL"]} active="Items">
      <Paper sx={styles.root}>
        <AppBar position="static">
          <Tabs
            value={tabIndex}
            onChange={(e, i) => setTabIndex(i)}
            variant="scrollable"
          >
            <Tab label="Items" />
          </Tabs>
        </AppBar>
        <AppBar color="default">
          <Toolbar>
            <Typography variant="h6" component="h6" sx={styles.title}>
              Items
            </Typography>
          </Toolbar>
        </AppBar>

        <AppBar position="static" elevation={0} color="default">
          <Toolbar
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                paddingRight: theme.spacing(6),
              },
            })}
          >
            <Search
              search={search}
              setSearch={setSearch}
              terms={terms}
              setTerms={setTerms}
            />
            <Box display={{ xs: "block", sm: "none" }} sx={styles.button}>
              <Fab size="small" color="primary" onClick={handleOpenNew}>
                <Add />
              </Fab>
            </Box>
            <Box display={{ xs: "none", sm: "block" }} sx={styles.button}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenNew}
              >
                <Add />
                Add Item
              </Button>
            </Box>
          </Toolbar>
          <NewItem
            open={open}
            onClose={() => {
              setOpen(false);
              setEditId("");
              setItem({});
            }}
            editId={editId}
            item={item}
            setEditId={setEditId}
          />
        </AppBar>
        <List>
          {map(filteredItems, (item) => {
            const { name, description, itemId } = item;
            const del = deleteId === itemId;
            return del ? (
              <div key={itemId}>
                <Slide in={del} direction="left">
                  <Confirm
                    cancel={() => setDeleteId("")}
                    confirm={() => dispatch(deleteItem(itemId))}
                  />
                </Slide>
              </div>
            ) : (
              <Slide key={itemId} in={!del} direction="right">
                <ListItem>
                  <ListItemText primary={name} secondary={description} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => {
                        setItem(item);
                        setEditId(itemId);
                        setOpen(true);
                      }}
                    >
                      <Create />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setItem(item);
                        setOpen(true);
                      }}
                    >
                      <Difference />
                    </IconButton>
                    <IconButton onClick={() => setDeleteId(itemId)}>
                      <DeleteForever />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Slide>
            );
          })}
        </List>
      </Paper>
    </Drawer>
  );
};

export default Items;
