import React from 'react';
import { useDispatch } from 'react-redux';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Stepper,
	Step,
	StepLabel,
	Slide,
	Box
} from '@mui/material';
import { Formik, Form } from 'formik';
import { object, string, reach } from 'yup';

import Address from 'components/suppliers/address';
import Contacts from 'components/suppliers/contacts';
import Company from 'components/suppliers/company';

import { editSupplier, createSupplier } from 'actions/suppliers';

const styles = {
	root: {
		overflow: 'hidden',
	}
}

const getSteps = () => ([
	'Company Name',
	'Physical address',
	'Mailing address',
	'Company contacts'
]);

const schema = object().shape({
	physical: object().shape({
		address1: string().required(),
		city: string().required(),
		state: string().required(),
		zip: string().required()
	}),
	billing:  object().shape({
		address1: string().required(),
		city: string().required(),
		state: string().required(),
		zip: string().required()
	}),
	primaryContact:  object().shape({
		firstName: string(),
		lastName: string(),
		email: string().email(),
	}),
	qualityContact:  object().shape({
		firstName: string(),
		lastName: string(),
		email: string().email(),
	}),
})

const NewSupplier = ({
	open,
	onClose,
	supplier,
	supplierId
}) => {
	const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [direction, setDirection] = React.useState('left');
  const steps = getSteps();
  return <Formik
	initialValues={supplier || {
		name: '',
		division: '',
		physical: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			zip: ''
		},
		sameAddress: false,
		billing: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			zip: ''
		},
		primaryContact: {
			firstName: '',
			lastName: '',
			email: '',
			contact: true
		},
		qualityContact: {
			firstName: '',
			lastName: '',
			email: '',
			contact: true
		},
	}}
	validationSchema={
		object().shape({
		name: string().required(),
		physical: reach(schema, 'physical'),
		billing: reach(schema, 'billing'),
		primaryContact: reach(schema, 'primaryContact'),
		qualityContact: reach(schema, 'qualityContact'),
	})}
	onSubmit={(values, actions) => {
		const cb = () => {
				actions.resetForm();
				onClose();
		};
		return supplier
			? dispatch(editSupplier(values, supplierId, cb))
			: dispatch(createSupplier(values, cb));
	}}
	>{formikProps => {
		return (
		<Form>
			<Dialog
				open={open}
				onClose={onClose}
				fullWidth
				scroll="body"
				maxWidth={'sm'}
			>
				<DialogTitle>
					Add Vendor
				</DialogTitle>
				<DialogContent sx={styles.root}>
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
		          const stepProps = {};
		          const labelProps = {};
							return (
		            <Step key={label} {...stepProps}>
		            	<StepLabel {...labelProps}>
		            		<Box display={{xs: 'none', sm: 'block'}}>
		            			{label}
	        				</Box>
	        			</StepLabel>
		            </Step>
	          	);
						})}
					</Stepper>
					<div >
						{activeStep === 0 ?
							<Slide in direction={direction} timeout={400}
								><Company formikProps={formikProps}/>
							</Slide>
						: null}
						{activeStep === 1 ?
							<Slide in direction={direction} timeout={400}
								><Address formikProps={formikProps} parent="physical"/>
							</Slide>
						: null}
						{activeStep === 2 ?
							<Slide in direction={direction} timeout={400}>
								<Address billing formikProps={formikProps} parent="billing"/>
							</Slide>
						: null}
						{activeStep === 3 ?
							<Slide in direction={direction} timeout={400}>
								<Contacts formikProps={formikProps}/>
							</Slide>
						: null}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Close</Button>
					{activeStep > 0 ?
						<Button onClick={() => {
							setDirection('right');
							setActiveStep(activeStep -1);
						}}>Back</Button>
					: null }
					{activeStep === steps.length - 1 ?
						<Button
							variant="contained"
							color="primary"
							type="submit"
							onClick={formikProps.handleSubmit}
						>
							Submit
						</Button>
					:
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setActiveStep(activeStep + 1)
								setDirection('left')
							}}
						>
							Next
						</Button>
					}

				</DialogActions>
			</Dialog>
		</Form>
		)
  }}</Formik>
}

export default NewSupplier;
