import React from 'react';
import {
	Grid,
	TextField,
	IconButton
} from '@mui/material';
import { Formik, Form } from 'formik';
import { database } from 'firebase_config';

import PublishIcon from '@mui/icons-material/Publish'

const comments = database.ref('purchasing/itemComments');
const commentKeyGen = async () => {
	const { key } = await comments.push();
	return key;
}

const styles = {
	iconButtonGrid: {
		maxWidth: '48px'
	},
	textareaGrid: {
		maxWidth: 'calc(100% - 80px)',
		flexBasis: 'calc(100% - 80px)',
		minWidth: 288
	}
}

const EditComment = ({
	comment,
	commentId,
	updateComments,
	closeEdit
}) => {
	return (
		<Formik
			initialValues={comment || {
				title: '',
				instruction: ''
			}}
			onSubmit={(values, actions) => {
				const submitNew = () => {
					commentKeyGen().then(newId => {
						updateComments(values, newId);
						actions.resetForm()
					})
				}
				const submitEdit = () => {
					updateComments(values, commentId);
					closeEdit();
				}
				return comment
				? submitEdit()
				: submitNew()
			}}
		>
		{({
			values: {
				title,
				instruction
			},
			handleChange
		}) => {
			return (
				<Form>
					<Grid container spacing={2} alignItems="center">
						{/*<Grid item xs={12}>
							<TextField
								label='Title'
								InputLabelProps={{
									shrink: true
								}}
							/>
						</Grid>*/}
						<Grid item xs={10} sx={styles.textareaGrid}>
							<TextField
								value={instruction}
								onChange={handleChange}
								name="instruction"
								multiline
								rows={2}
								fullWidth
								variant="outlined"
								label="Additional Instruction"
								InputLabelProps={{
									shrink: true
								}}
							/>
						</Grid>
						<Grid item xs={2} sx={styles.iconButtonGrid}>
							<IconButton type="submit">
								<PublishIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Form>
			)
		}}
		</Formik>
		)
}

export default EditComment;