import { omit, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Checkbox,
	Button,
} from '@mui/material';
import Close from '@mui/icons-material/Close';

import { fetchItemCategories } from 'actions/items';
import { linkSupplierCategories } from 'actions/suppliers';

const styles = {
	close: {
		position: 'absolute',
		top: theme => theme.spacing(1),
		right: theme => theme.spacing(1)
	}
}

const LinkCategories =({
	open,
	onClose,
	linked,
	supplierId
}) => {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => dispatch(fetchItemCategories()), []);
	const [linkList, setLinkList] = useState(linked);
	const itemCategories = useSelector(state => state.itemCategories)
	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>
				<IconButton onClick={onClose} sx={styles.close}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<List>
					{
						map(itemCategories, (itemCategory, catId) => {
							return (
								<ListItem key={catId}>
									<Checkbox
										checked={Boolean(linkList[catId]) || false}
										onClick={() => setLinkList(prev => {
											if (prev[catId]) {
												return omit(prev, catId)
											}
											return { ...prev, [catId]: { ...prev[catId], linked: true, title: itemCategory.title }}

										})}
									/>
									<ListItemText
										primary={itemCategory.title}
									/>
								</ListItem>
							)
						})
					}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					onClick={() => {
						dispatch(linkSupplierCategories(supplierId, linkList, () => {
							enqueueSnackbar('Categories updated', { variant: 'success'});
							onClose();
						}));
					}}
					variant="contained"
					color="primary"
				>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default LinkCategories;
