import { FETCH_SUPPLIER_ISSUES } from 'actions/suppliers';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_SUPPLIER_ISSUES:
            return action.payload;
        default:
            return state;
    }
}